import * as React from "react";

function IcBurgerDot(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M8.333 8.333a.667.667 0 100-1.333.667.667 0 000 1.333zM8.333 3.667a.667.667 0 100-1.334.667.667 0 000 1.334zM8.333 13a.667.667 0 100-1.333.667.667 0 000 1.333z"
        stroke="currentColor"
        strokeWidth={1.33333}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default IcBurgerDot;
