import React from "react";

import { CalendarIcon } from "@heroicons/react/24/outline";
import { Card, CardBody, CardHeader } from "@material-tailwind/react";
import { format } from "date-fns";
import { id } from "date-fns/locale";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { HiArrowLeft } from "react-icons/hi";
import { Link } from "react-router-dom";

import { Button } from "../../../../../../../atoms/V2/Button";
import { Calendar } from "../../../../../../../atoms/V2/DatePicker";
import { DropdownSingleWithSearch } from "../../../../../../../atoms/V2/DropdownSingleWithSearch";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../../../../../atoms/V2/Form";
import { Input } from "../../../../../../../atoms/V2/Input";
import { InputNumber } from "../../../../../../../atoms/V2/InputNumber";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../../../../../atoms/V2/Popover";
import { ROUTES_PATH } from "../../../../../../../shared/routes";
import { cn } from "../../../../../../../shared/utils";

const FormSection = (props) => {
  const { form, handleOnSubmit, queryHouses, setSelectedData, mutate } = props;

  return (
    <Card className="col-span-8 w-full border p-0 shadow-none">
      <CardHeader
        floated={false}
        shadow={false}
        className="m-0 flex items-center gap-xs px-3xl pt-xl"
      >
        <Link to={ROUTES_PATH.manajemenSapronak}>
          <HiArrowLeft className="size-6 text-text-primary" />
        </Link>
        <h2 className="text-lg font-bold text-text-primary">
          Ubah Setting DOC
        </h2>
      </CardHeader>
      <CardBody className="space-y-3xl px-3xl">
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(handleOnSubmit)}
            className="space-y-8"
          >
            <div className="grid grid-cols-2 gap-6">
              <FormField
                control={form.control}
                name="house"
                render={({ field }) => {
                  const transformedOptions = queryHouses.data?.contents?.map(
                    (house) => ({
                      id: house.house?.id,
                      name: house.house?.labels,
                      labels: house.house?.labels,
                      code: house.analyticAccount,
                      maxCapacity: house.docInPopulation,
                      guaranteeType: house.house?.guaranteeType?.labels,
                      guaranteeAmount: house.house?.guaranteeAmount,
                      diffFcrHistoryOne: house.house?.diffFcrHistoryOne,
                      diffFcrHistoryTwo: house.house?.diffFcrHistoryTwo,
                      diffFcrHistoryThree: house.house?.diffFcrHistoryThree,
                      ipHistoryOne: house.house?.ipHistoryOne,
                      ipHistoryTwo: house.house?.ipHistoryTwo,
                      ipHistoryThree: house.house?.ipHistoryThree,
                      periodId: house.id,
                    }),
                  );

                  const selectedOption = transformedOptions?.find(
                    (item) => item?.id === field?.value,
                  );

                  return (
                    <FormItem>
                      <FormLabel isRequire>Kandang</FormLabel>
                      <FormControl>
                        <DropdownSingleWithSearch
                          id={field.name}
                          options={transformedOptions}
                          disabled={true}
                          isError={form.formState.errors.house}
                          placeholder="Pilih Kandang"
                          placeholderSearch="Cari nama kandang"
                          selectedOption={selectedOption}
                          onSelect={(value) => {
                            field.onChange(value.id || null);
                            form.setValue(
                              "docInPopulation",
                              value?.maxCapacity || "",
                            );
                            form.setValue("idPeriode", value?.code || "");

                            setSelectedData({
                              dif1: value?.diffFcrHistoryOne?.toString() || "-",
                              dif2: value?.diffFcrHistoryTwo?.toString() || "-",
                              dif3:
                                value?.diffFcrHistoryThree?.toString() || "-",
                              ip1: value?.ipHistoryOne?.toString() || "-",
                              ip2: value?.ipHistoryTwo?.toString() || "-",
                              ip3: value?.ipHistoryThree?.toString() || "-",
                              jaminan: value?.guaranteeType || "-",
                              nilai_jaminan: value?.guaranteeAmount || "-",
                              idPeriode: value?.periodId || "-",
                            });
                          }}
                          content={(option) => (
                            <div className="flex max-w-80 flex-col py-[10px]">
                              <p className="text-base text-text-secondary">
                                {option?.name}
                              </p>
                              <p className="text-xs text-text-quarterary">
                                {option?.code || "-"}
                              </p>
                            </div>
                          )}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />

              <FormField
                control={form.control}
                name="idPeriode"
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>ID Periode</FormLabel>
                      <FormControl>
                        <Input
                          {...field}
                          id={field.name}
                          disabled
                          placeholder="ID Periode"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />

              <div className="col-span-2">
                <FormField
                  control={form.control}
                  name="docInDate"
                  render={({ field }) => {
                    return (
                      <FormItem>
                        <FormLabel isRequire>Tanggal</FormLabel>
                        <FormControl>
                          <Popover>
                            <PopoverTrigger asChild>
                              <Button
                                id={field.name}
                                variant={
                                  form.formState.errors.docInDate
                                    ? "btnErrorOutlined"
                                    : "btnOutlinedGrey"
                                }
                                className={cn(
                                  "w-full justify-between pr-3 text-left font-normal focus:border-primary-400 focus:ring focus:ring-primary-200 focus-visible:ring-primary-200",
                                  !field.value && "text-gray-400",
                                )}
                              >
                                {field.value ? (
                                  format(field.value, "PPP", {
                                    locale: id,
                                  })
                                ) : (
                                  <span>Pilih Tanggal</span>
                                )}
                                <CalendarIcon className="h-4 w-4" />
                              </Button>
                            </PopoverTrigger>
                            <PopoverContent
                              className="w-auto bg-white p-0"
                              align="end"
                            >
                              <Calendar
                                mode="single"
                                selected={field.value}
                                onSelect={field.onChange}
                                autoFocus
                              />
                            </PopoverContent>
                          </Popover>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    );
                  }}
                />
              </div>

              <div className="col-span-2">
                <FormField
                  control={form.control}
                  name="docInPopulation"
                  render={({ field }) => {
                    return (
                      <FormItem>
                        <FormLabel isRequire>Populasi</FormLabel>
                        <FormControl>
                          <InputNumber
                            id={field.name}
                            value={field.value || ""}
                            onValueChange={(values) => {
                              field.onChange(
                                values.value ? Number(values.value) : "",
                              );
                            }}
                            isError={form.formState.errors.docInPopulation}
                            placeholder="Masukkan populasi"
                            rightComponent={
                              <span className="text-sm text-text-placeholder">
                                Ekor
                              </span>
                            }
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    );
                  }}
                />
              </div>
            </div>
            <div className="flex w-full justify-end">
              <Button
                type="submit"
                disabled={mutate.isPending}
                size="small"
                className="w-fit"
              >
                Simpan
                {mutate.isPending && (
                  <AiOutlineLoading3Quarters className="size-4 animate-spin text-button-primaryFg" />
                )}
              </Button>
            </div>
          </form>
        </Form>
      </CardBody>
    </Card>
  );
};

export default FormSection;
