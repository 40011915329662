import React from "react";

import {
  Card,
  CardBody,
  CardHeader,
  Typography,
} from "@material-tailwind/react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { HiArrowLeft } from "react-icons/hi";
import { Link } from "react-router-dom";

import { Button } from "../../../../atoms/V2/Button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "../../../../atoms/V2/Dialog";
import { ROUTES_PATH } from "../../../../shared/routes";
import FormTambah from "./components/form";
import { useMasterPeriodeTambah } from "./hook";

const MasterPeriodeTambahFeature = () => {
  const { mutateAdd, handleMutate, isDialogOpen, setIsDialogOpen } =
    useMasterPeriodeTambah();

  return (
    <div className="p-4">
      <Dialog
        open={isDialogOpen}
        onOpenChange={(isOpen) => {
          setIsDialogOpen(isOpen);
        }}
      >
        <DialogContent className="sm:max-w-[560px]">
          <DialogHeader>
            <DialogTitle>Konfirmasi buat master periode</DialogTitle>
            <DialogDescription>
              Pastikan semua informasi periode baru sudah benar sebelum
              menyimpan.
            </DialogDescription>
          </DialogHeader>
          <div className="flex items-center justify-end gap-xl">
            <Button
              variant="btnOutlinedGrey"
              className="w-fit"
              onClick={() => setIsDialogOpen(false)}
              disabled={mutateAdd.isPending}
            >
              Batal
            </Button>
            <Button
              disabled={mutateAdd.isPending}
              className="w-fit"
              onClick={handleMutate}
            >
              Buat Periode
              {mutateAdd.isPending && (
                <AiOutlineLoading3Quarters className="size-4 animate-spin text-button-primaryFg" />
              )}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      <Card className="border shadow-none">
        <CardHeader
          floated={false}
          shadow={false}
          className="mx-3xl rounded-none"
        >
          <div className="flex items-center gap-1">
            <Link to={ROUTES_PATH.masterPeriode}>
              <HiArrowLeft className="size-6 text-text-primary" />
            </Link>
            <Typography className="text-xl font-bold text-text-primary">
              Tambah periode
            </Typography>
          </div>
        </CardHeader>
        <CardBody className="space-y-3xl">
          <FormTambah />
        </CardBody>
      </Card>
    </div>
  );
};

export default MasterPeriodeTambahFeature;
