export const ROUTES_PATH = {
  masterPeriode: "/periode-budidaya/master-periode",
  masterPeriodeDetail: "/periode-budidaya/master-periode/:id",
  masterPeriodeTambahPeriode: "/periode-budidaya/master-periode/tambah-periode",
  masterPeriodeEditPeriode: "/periode-budidaya/master-periode/edit-periode/:id",
  masterPeriodeRegion: "/periode-budidaya/region-master-periode",
  masterPeriodeRegionDetail: "/periode-budidaya/region-master-periode/:id",
  manajemenSapronak: "/periode-budidaya/manajemen-sapronak",
  manajemenSapronakSettingDOC:
    "/periode-budidaya/manajemen-sapronak/setting-doc",
  managemenSapronakSettingDOCEdit:
    "/periode-budidaya/manajemen-sapronak/setting-doc/edit/:id",
  manajemenSapronakSettingPakan:
    "/periode-budidaya/manajemen-sapronak/setting-pakan",
  manajemenSapronakSettingPakanEdit:
    "/periode-budidaya/manajemen-sapronak/setting-pakan/edit/:id",
  laporanSapronak: "/periode-budidaya/laporan-sapronak",
};

export const ROUTES = {
  masterPeriodeDetail: (id) => `/periode-budidaya/master-periode/${id}`,
  masterPeriodeEditPeriode: (id) =>
    `/periode-budidaya/master-periode/edit-periode/${id}`,
  masterPeriodeRegionDetail: (id) =>
    `/periode-budidaya/region-master-periode/${id}`,
  manajemenSapronak: (tab) => `/periode-budidaya/manajemen-sapronak?tab=${tab}`,
  manajemenSapronakSettingDOCEdit: (id) =>
    `/periode-budidaya/manajemen-sapronak/setting-doc/edit/${id}`,
  manajemenSapronakSettingPakanEdit: (id) =>
    `/periode-budidaya/manajemen-sapronak/setting-pakan/edit/${id}`,
  laporanSapronak: (tab) => `/periode-budidaya/laporan-sapronak?tab=${tab}`,
};
