import React from "react";

import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";

import {
  IcChevronSelectorVertical,
  IcDownload,
  IcPlus,
} from "../../../../../../../atoms/icons";
import { Button } from "../../../../../../../atoms/V2/Button";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "../../../../../../../atoms/V2/Dropdown";
import NotFound from "../../../../../../../atoms/V2/NotFound";
import {
  ScrollArea,
  ScrollBar,
} from "../../../../../../../atoms/V2/ScrollArea";
import { toast } from "../../../../../../../atoms/V2/useToast";
import { cn } from "../../../../../../../shared/utils";
import { isSummarizedRow } from "../config";
import { useRecordingHarian } from "../hook";

export function DataTable({ columns, data }) {
  const { handleOpenAddDrawer, queryNextDay, mutateDownload } =
    useRecordingHarian();

  const [columnVisibility, setColumnVisibility] = React.useState({});

  const handleColumnVisibilityChange = (columnId, value) => {
    const column = table.getColumn(columnId);

    const childColumns = column.columns
      ? getAllNestedColumns(column.columns)
      : [];

    const newVisibility = { ...columnVisibility };

    newVisibility[columnId] = value;
    childColumns.forEach((childCol) => {
      newVisibility[childCol.id] = value;
    });

    setColumnVisibility(newVisibility);
  };

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    state: {
      columnVisibility,
    },
  });

  const getAllNestedColumns = (columns) => {
    let flatColumns = [];
    columns.forEach((column) => {
      flatColumns.push(column);
      if (column.columns) {
        flatColumns = [...flatColumns, ...getAllNestedColumns(column.columns)];
      }
    });
    return flatColumns;
  };

  const filteredColumns = getAllNestedColumns(table.getAllColumns()).filter(
    (column) => column.getCanHide(),
  );

  const isLastNestedColumn = (header) => {
    if (!header.column.parent) return false;
    const siblings = header.column.parent.columns;
    return siblings[siblings.length - 1].id === header.column.id;
  };

  return (
    <section className="mb-4">
      <div className="flex items-center justify-between px-xl py-xl">
        <div className="flex items-center gap-2">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button
                variant="btnOutlinedGrey"
                className="ml-auto w-fit text-sm font-medium"
                disabled={data?.length === 0}
              >
                View
                <IcChevronSelectorVertical className="size-5 text-button-secondaryFg" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              align="start"
              className="border-gray-300 p-0 shadow-none"
            >
              <ScrollArea className="h-[200px]">
                <ScrollBar />
                {filteredColumns.map((column) => {
                  const depth = column.depth;
                  return (
                    <DropdownMenuCheckboxItem
                      key={column.id}
                      className={cn(
                        "cursor-pointer rounded-none px-lg py-sm capitalize text-text-secondary",
                        depth > 0 && `pl-${(depth + 1) * 4}`,
                      )}
                      checked={column.getIsVisible()}
                      onCheckedChange={(value) =>
                        handleColumnVisibilityChange(column.id, !!value)
                      }
                    >
                      {column.id === "usedFeedsLabel" ? "Jenis Pakan" : ""}

                      {column.columnDef.header !== ""
                        ? column.columnDef.header
                        : column.id}
                    </DropdownMenuCheckboxItem>
                  );
                })}
              </ScrollArea>
              {Object.keys(columnVisibility).length > 0 && (
                <div className="border-t">
                  <Button
                    variant="btnLinkBlack"
                    className="font-medium text-text-primary"
                    onClick={() => setColumnVisibility({})}
                  >
                    Atur Ulang
                  </Button>
                </div>
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
        <div className="flex items-center gap-4">
          <Button
            variant="btnOutlinedGrey"
            className="w-fit text-[#484B4F]"
            disabled={data?.length === 0 || mutateDownload.isPending}
            onClick={() => {
              toast({
                title: "Sedang mengunduh data",
                variant: "loading",
              });
              mutateDownload.mutate();
            }}
          >
            <IcDownload className="size-5" />
            Unduh
          </Button>

          <Button
            className="w-fit"
            onClick={() => {
              if (queryNextDay.data?.day === -1) {
                toast({
                  title: "Data harian sudah lengkap",
                  variant: "error",
                });
              } else {
                handleOpenAddDrawer();
              }
            }}
          >
            <IcPlus className="size-5" />
            Tambah data harian
          </Button>
        </div>
      </div>

      {data?.length === 0 ? (
        <div className="flex h-[60dvh] w-full flex-col items-center justify-center">
          <NotFound />
        </div>
      ) : (
        <div className={cn("overflow-y-auto border scrollbar-hide")}>
          <table className="w-full min-w-max table-auto border-separate border-spacing-0 text-left">
            <thead className="sticky top-0 z-10">
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    const columnRelativeDepth =
                      header.depth - header.column.depth;
                    if (columnRelativeDepth > 1) {
                      return null;
                    }

                    let rowSpan = 1;
                    if (header.isPlaceholder) {
                      const leafs = header.getLeafHeaders();
                      rowSpan = leafs[leafs.length - 1].depth - header.depth;
                    }

                    const isColSpanColumn = header.column.columns?.length > 0;
                    const isNestedColumn = header.depth > 1;
                    const isLast = isLastNestedColumn(header);

                    return (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        rowSpan={rowSpan}
                        className={cn(
                          "border-b border-border-secondary bg-neutral-50 px-lg py-1 text-sm font-bold text-text-primary",
                          isColSpanColumn && "border-x text-center",
                          isNestedColumn && "border-l",
                          isLast && "border-r",
                        )}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr
                  key={row.id}
                  className="group bg-white hover:bg-bg-secondary"
                >
                  {row.getVisibleCells().map((cell) => {
                    const isNumber =
                      cell.getValue() != null &&
                      typeof cell.getValue() === "number";

                    const isBlackText = isSummarizedRow.includes(
                      cell.row.original?.recordDate,
                    );

                    const isLastRow =
                      row.id === table.getRowModel().rows.slice(-1)[0].id;

                    const isVisit = cell.row.original?.hasVisit === true;

                    if (isBlackText) {
                      return (
                        <td
                          key={cell.id}
                          className={cn(
                            "relative border-b border-border-secondary bg-bg-secondary px-lg py-[10px] text-sm !font-bold !text-text-primary group-hover:bg-bg-secondary",
                            isNumber && "text-right font-medium",
                            isLastRow && "border-none",
                          )}
                        >
                          {isNumber
                            ? cell.getValue()?.toLocaleString("id-ID")
                            : flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext(),
                              )}
                        </td>
                      );
                    }

                    return (
                      <td
                        key={cell.id}
                        className={cn(
                          "relative border-b border-border-secondary px-lg py-[10px] text-sm text-text-tertiary group-hover:bg-bg-secondary",
                          isNumber && "text-right font-medium",
                          isLastRow && "border-none",
                          isVisit && "bg-[#F0FDF4]",
                        )}
                      >
                        {isNumber
                          ? cell.getValue()?.toLocaleString("id-ID")
                          : flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </section>
  );
}
