import { useRef } from "react";

import { useQuery } from "@tanstack/react-query";
import Cookies from "js-cookie";

import api from "../../api/configApi";

const useFetchFile = (url, { shouldDownload = false, fileName = "" } = {}) => {
  const token = Cookies.get("accessToken");
  const shouldDownloadRef = useRef(false); // Track download intent via ref

  const query = useQuery({
    queryKey: ["fetch-file", url], // Query key remains stable
    queryFn: async () => {
      if (!url) throw new Error("URL is required");

      const response = await api.get(url, {
        headers: { Authorization: `Bearer ${token}` },
        responseType: "blob",
      });

      if (!response?.data)
        throw new Error("Invalid response: Blob data missing");

      const blobUrl = URL.createObjectURL(response.data);

      // Trigger download if ref is true and shouldDownload is enabled
      if (shouldDownload && shouldDownloadRef.current) {
        const link = document.createElement("a");
        link.href = blobUrl;
        link.download = fileName || url.split("/").pop() || "downloaded-file";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        shouldDownloadRef.current = false; // Reset after download
      }

      return blobUrl; // Always return the URL for potential rendering
    },
    enabled: !!url,
  });

  // Manually trigger download by setting ref and refetching
  const triggerDownload = () => {
    shouldDownloadRef.current = true;
    query.refetch();
  };

  return { ...query, triggerDownload };
};

export default useFetchFile;
