import React from "react";

import {
  CardBody,
  CardHeader,
  Tab,
  TabPanel,
  Tabs,
  TabsBody,
  TabsHeader,
} from "@material-tailwind/react";
import { Card } from "@material-tailwind/react";
import { Navigate, useSearchParams } from "react-router-dom";

import { cn } from "../../../../shared/utils";
import { SapronakDOC, SapronakPakan } from "./section";

const VALID_TABS = ["doc", "ovk", "pakan"];

const TAB_DATA = [
  { label: "DOC", value: "doc" },
  { label: "Pakan", value: "pakan" },
];

const ManajemenSapronak = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentTab = searchParams.get("tab") || "doc";

  if (!VALID_TABS.includes(currentTab)) {
    return <Navigate to="/manajemen-sapronak?tab=doc" replace />;
  }

  const handleTabChange = (value) => {
    setSearchParams({ tab: value });
  };

  return (
    <div className="p-4">
      <Card className="m-0 h-auto w-full border border-border-secondary p-0 shadow-none">
        <Tabs value={currentTab}>
          <CardHeader
            floated={false}
            shadow={false}
            className="m-0 w-full rounded-b-none border-b px-xl py-sm pb-0"
          >
            <TabsHeader
              className="m-0 gap-lg rounded-none bg-transparent p-0"
              indicatorProps={{
                className:
                  "bg-transparent border-b-[3px] border-primary-300 shadow-none rounded-none gap-x-6 p-0 m-0",
              }}
              role="tablist"
            >
              {TAB_DATA.map(({ label, value }) => (
                <Tab
                  key={value}
                  value={value}
                  onClick={() => handleTabChange(value)}
                  disabled={value === "ovk"}
                  className={cn(
                    currentTab === value
                      ? "text-text-secondary"
                      : "text-text-tertiary",
                    "m-0 h-9 w-fit bg-white p-0 px-2 text-sm font-semibold",
                  )}
                  role="tab"
                  aria-selected={currentTab === value}
                  aria-controls={`tabpanel-${value}`}
                >
                  {label}
                </Tab>
              ))}
            </TabsHeader>
          </CardHeader>
          <CardBody className="min-h-[80dvh] w-full px-0 py-sm">
            <TabsBody>
              <TabPanel
                value="doc"
                className="p-0"
                role="tabpanel"
                id="tabpanel-doc"
              >
                <SapronakDOC />
              </TabPanel>
              <TabPanel
                value="pakan"
                className="p-0"
                role="tabpanel"
                id="tabpanel-pakan"
              >
                <SapronakPakan />
              </TabPanel>
            </TabsBody>
          </CardBody>
        </Tabs>
      </Card>
    </div>
  );
};

export default ManajemenSapronak;
