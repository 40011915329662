import React from "react";

import { Card, CardBody, CardHeader } from "@material-tailwind/react";

import { formatIDR } from "../../../../../../../shared/utils";

const DetailSection = ({ selectedData }) => {
  return (
    <Card className="col-span-4 flex-shrink-0 border p-0 shadow-none">
      <CardHeader
        floated={false}
        shadow={false}
        className="m-0 space-y-1 px-3xl pt-3xl"
      >
        <h2 className="text-lg font-semibold text-text-primary">
          Performa sebelumnya
        </h2>
        <p className="text-xs text-text-tertiary">
          Diff FCR dan IP berdasarkan data 3 periode terakhir
        </p>
      </CardHeader>
      <CardBody className="space-y-xl px-3xl pt-xl">
        <div className="space-y-2">
          <h3 className="font-medium text-text-secondary">Performa</h3>
          <div className="rounded-md border border-border-secondary">
            <table className="w-full table-auto border-collapse overflow-hidden rounded-md border border-border-secondary text-sm text-text-primary">
              <thead>
                <tr>
                  <th className="border border-border-secondary bg-bg-secondary px-lg py-1 text-left"></th>
                  <th className="border border-border-secondary bg-bg-secondary px-lg py-1 text-left font-bold">
                    P-1
                  </th>
                  <th className="border border-border-secondary bg-bg-secondary px-lg py-1 text-left font-bold">
                    P-2
                  </th>
                  <th className="border border-border-secondary bg-bg-secondary px-lg py-1 text-left font-bold">
                    P-3
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border border-border-secondary p-lg font-medium">
                    Diff FCR
                  </td>
                  <td className="border border-border-secondary p-lg">
                    {selectedData.dif1 || "-"}
                  </td>
                  <td className="border border-border-secondary p-lg">
                    {selectedData.dif2 || "-"}
                  </td>
                  <td className="border border-border-secondary p-lg">
                    {selectedData.dif3 || "-"}
                  </td>
                </tr>
                <tr>
                  <td className="border border-border-secondary p-lg font-medium">
                    IP
                  </td>
                  <td className="border border-border-secondary p-lg">
                    {selectedData.ip1 || "-"}
                  </td>
                  <td className="border border-border-secondary p-lg">
                    {selectedData.ip2 || "-"}
                  </td>
                  <td className="border border-border-secondary p-lg">
                    {selectedData.ip3 || "-"}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="space-y-lg">
          <h3 className="font-medium text-text-secondary">Jaminan</h3>
          <div className="grid grid-cols-2">
            <div className="space-y-lg">
              <p className="text-sm text-text-tertiary">Jenis Jaminan</p>
              <p className="text-sm text-text-tertiary">Nilai Jaminan</p>
            </div>
            <div className="space-y-lg">
              <p className="text-sm text-text-primary">
                {selectedData.jaminan || "-"}
              </p>
              <p className="text-sm text-text-primary">
                {selectedData.nilai_jaminan
                  ? formatIDR(selectedData?.nilai_jaminan)
                  : "-"}
              </p>
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default DetailSection;
