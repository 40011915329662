import { useQuery } from "@tanstack/react-query";

import api from "../../api/configApi";

const useGetFeedsStock = ({ periodId }) => {
  const query = useQuery({
    queryKey: ["get-list-feed-sapronak", periodId],
    queryFn: async () => {
      const response = await api.get("/v1/feeds/stock", {
        params: {
          periodId,
        },
      });
      return response.data;
    },
    enabled: !!periodId,
  });

  return query;
};

export default useGetFeedsStock;
