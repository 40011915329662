import * as React from "react";

function IcBuilding(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M10 14v-3.6c0-.373 0-.56-.073-.703a.667.667 0 00-.291-.291c-.143-.073-.33-.073-.703-.073H7.067c-.374 0-.56 0-.703.073a.667.667 0 00-.291.291C6 9.84 6 10.027 6 10.4V14M2 4.667a2 2 0 104 0 2 2 0 104 0 2 2 0 104 0M4.133 14h7.734c.746 0 1.12 0 1.405-.145.25-.128.455-.332.583-.583.145-.285.145-.659.145-1.405V4.133c0-.746 0-1.12-.145-1.405a1.334 1.334 0 00-.583-.583C12.987 2 12.613 2 11.867 2H4.133c-.746 0-1.12 0-1.405.145-.25.128-.455.332-.583.583C2 3.013 2 3.387 2 4.133v7.734c0 .746 0 1.12.145 1.405.128.25.332.455.583.583.285.145.659.145 1.405.145z"
        stroke="currentColor"
        strokeWidth={1.33333}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default IcBuilding;
