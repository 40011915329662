import { createContext, useContext, useEffect, useRef, useState } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { format } from "date-fns";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useDebounce } from "use-debounce";

import TableCellItem from "../../../../../../../atoms/Table/TableCellItem";
import TableHeaderItem from "../../../../../../../atoms/Table/TableHeaderItem";
import { Checkbox } from "../../../../../../../atoms/V2/Checkbox";
import { useDropzone } from "../../../../../../../atoms/V2/Dropzone";
import IconType from "../../../../../../../atoms/V2/IconType";
import { FILE_CONTENT_TYPE } from "../../../../../../../shared/variables";
import { useMutateFeedsList } from "../../../../../../../useCases/Feed";
import {
  useDownloadReportExcel,
  useEditFeedRealization,
  useGetDetailSettingFeed,
  useGetSettingFeed,
  useMutateFeedRealization,
} from "../../../../../../../useCases/Settings/Feed";
import { useFetchFile } from "../../../../../../../useCases/Utils";
import BadgeWeek from "../../../components/badge-week";
import { BadgeStatus } from "../components";
import { DOWNLOAD_TYPE, formSchema } from "../config";

const SettingPakan = createContext(undefined);

export const SettingPakanStore = ({ children }) => {
  const isDataTotalItemSet = useRef(false);

  const [currentTab, setCurrentTab] = useState("setting");
  const [search, setSearch] = useState("");
  const [value] = useDebounce(search, 500);
  const [currentPage, setCurrentPage] = useState(0);
  const [sortBy, setSortBy] = useState("updatedAt");
  const [descending, setDescending] = useState(true);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [dataTotalItem, setDataTotalItem] = useState(0);
  const [hasRealized, setHasRealized] = useState(false);

  const [docInDate, setDocInDate] = useState({
    from: null,
    to: null,
  });
  const [initialCapacity, setInitialCapacity] = useState(null);
  const [finalCapacity, setFinalCapacity] = useState(null);
  const [isFilter, setIsFilter] = useState(false);
  const [filteredItems, setFilteredItems] = useState({
    year: null,
    month: null,
  });

  const [selectedAll, setSelectedAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  const [selectedRealisasi, setSelectedRealisasi] = useState({
    id: null,
    idFeed: null,
    idPeriod: null,
    houseName: "",
    houseId: "",
    fileSJ: null,
    fileTTB: null,
  });

  const [selectedSetting, setSelectedSetting] = useState({
    id: null,
    idFeed: null,
    idPeriod: null,
    houseName: "",
    houseId: "",
  });

  const [isSheetDetailSettingOpen, setIsSheetDetailSettingOpen] =
    useState(false);

  const [selectedFeedType, setSelectedFeedType] = useState([]);
  const [isUploadedFileSJ, setIsUploadedFileSJ] = useState(false);
  const [isUploadedFileTTB, setIsUploadedFileTTB] = useState(false);
  const [isSheetRealisasiOpen, setIsSheetRealisasiOpen] = useState(false);
  const [isSheetDetailRealisasiOpen, setIsSheetDetailRealisasiOpen] =
    useState(false);
  const [isFileExist, setIsFileExist] = useState({
    sj: true,
    ttb: true,
  });
  const [isEditRealisasi, setIsEditRealisasi] = useState(false);

  const [isDialogDownloadOpen, setIsDialogDownloadOpen] = useState(false);
  const [downloadType, setDownloadType] = useState(DOWNLOAD_TYPE.allData);
  const [selectedWeeks, setSelectedWeeks] = useState([]);
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");

  const { selectedUnits } = useSelector((state) => state.filterUnit);

  const query = useGetSettingFeed({
    itemPerPage,
    currentPage,
    sortBy,
    descending,
    search: value,
    hasRealized,
    unit: selectedUnits.join(","),
    year: filteredItems.year,
    month: filteredItems.month,
    week: selectedWeeks.join(","),
    realizationType: hasRealized ? selectedFeedType?.join(",") : "",
    settingType: hasRealized ? "" : selectedFeedType?.join(","),
  });
  const data = query.data?.contents || [];
  const queryDetail = useGetDetailSettingFeed(
    hasRealized ? selectedRealisasi?.id : null,
  );

  const queryDetailSetting = useGetDetailSettingFeed(
    selectedSetting?.id ? selectedSetting?.id : null,
  );

  const mutateFeedData = useMutateFeedsList();
  const mutateRealization = useMutateFeedRealization(selectedRealisasi?.id);
  const mutateDownloadExcel = useDownloadReportExcel();
  const mutateEditRealization = useEditFeedRealization(selectedRealisasi?.id);

  const isPendingRealization =
    mutateRealization.isPending || mutateEditRealization.isPending;

  const queryFileJS = useFetchFile(selectedRealisasi?.fileSJ, {
    shouldDownload:
      FILE_CONTENT_TYPE.PDF === queryDetail.data?.media?.sj?.contentType
        ? true
        : isSheetDetailRealisasiOpen
          ? true
          : false,
    fileName: queryDetail.data?.media?.sj?.fileName,
  });
  const queryFileTTB = useFetchFile(selectedRealisasi?.fileTTB, {
    shouldDownload:
      FILE_CONTENT_TYPE.PDF === queryDetail.data?.media?.ttb?.contentType
        ? true
        : isSheetDetailRealisasiOpen
          ? true
          : false,
    fileName: queryDetail.data?.media?.ttb?.fileName,
  });

  const form = useForm({
    resolver: zodResolver(formSchema),
  });

  const dropzoneSJ = useDropzone({
    onDropFile: async (file) => {
      await new Promise((resolve) => setTimeout(resolve, 1000));

      return {
        status: "success",
        result: URL.createObjectURL(file),
      };
    },
    validation: {
      accept: {
        "image/png": [".png"],
        "image/jpeg": [".jpg", ".jpeg"],
      },
      maxSize: 10 * 1024 * 1024,
      maxFiles: 1,
    },
  });

  const dropzoneTTB = useDropzone({
    onDropFile: async (file) => {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      return {
        status: "success",
        result: URL.createObjectURL(file),
      };
    },
    validation: {
      accept: {
        "image/png": [".png"],
        "image/jpeg": [".jpg", ".jpeg"],
        "application/pdf": [".pdf"],
      },
      maxSize: 10 * 1024 * 1024,
      maxFiles: 1,
    },
  });

  useEffect(() => {
    if (query.data && !isDataTotalItemSet.current) {
      setDataTotalItem(query.data?.totalItems);
      isDataTotalItemSet.current = true;
    } else if (
      query.data &&
      isDataTotalItemSet.current &&
      !isFilter &&
      !value
    ) {
      setDataTotalItem(query.data?.totalItems);
    }
  }, [query.data, isFilter, value]);

  useEffect(() => {
    if (isEditRealisasi && queryDetail.data) {
      form.setValue("feedRealization", queryDetail.data?.feedRealization?.id);
      form.setValue(
        "dateRealization",
        new Date(
          queryDetail.data?.dateRealization
            ? queryDetail.data?.dateRealization
            : new Date(),
        ),
      );
      form.setValue("qtyRealization", queryDetail.data?.qtyRealization);
      form.setValue("sjNumber", queryDetail.data?.sjNumber);
      form.setValue("ttbNumber", queryDetail.data?.ttbNumber);
    }
  }, [form, isEditRealisasi, queryDetail.data]);

  useEffect(() => {
    if (isSheetRealisasiOpen) {
      setIsFileExist({
        sj: true,
        ttb: true,
      });
    }
  }, [isSheetRealisasiOpen]);

  useEffect(() => {
    if (selectedWeeks.length > 0) {
      setIsFilter(true);
    } else {
      setIsFilter(false);
    }
  }, [selectedWeeks]);


  const handleResetFilter = () => {
    setYear("");
    setMonth("");
    setSelectedWeeks([]);
    setIsFilter(false);
    setFilteredItems({
      year: "",
      month: "",
    });
  };

  const handleApplyFilter = () => {
    setFilteredItems({
      year: year,
      month: month,
    });
    setIsFilter(true);
    setCurrentPage(0);
  };

  const countFilteredItems = (items) => {
    const { year, month, ...rest } = items;
    let count = 0;

    if (year) {
      count += 1;
    }

    if (month) {
      count += 1;
    }

    count += Object.values(rest).filter((value) => value).length;

    return count;
  };

  const handleSort = (column) => {
    if (sortBy === column) {
      setDescending(!descending);
    } else {
      setSortBy(column);
      setDescending(false);
    }
    setCurrentPage(0);
  };

  const handleOnSubmit = () => {
    const values = form.getValues();

    const payload = {
      rearingPeriod: { id: selectedRealisasi?.idPeriod },
      feedRealization: { id: values.feedRealization },
      dateRealization: format(values.dateRealization, "yyyy-MM-dd"),
      qtyRealization: values.qtyRealization,
      sjNumber: values.sjNumber,
      ttbNumber: values.ttbNumber,
    };

    if (isEditRealisasi) {
      const formData = new FormData();
      formData.append("data", JSON.stringify(payload));

      if (dropzoneSJ.fileStatuses.length > 0) {
        formData.append("sj", dropzoneSJ.fileStatuses[0].file);
      }

      if (dropzoneTTB.fileStatuses.length > 0) {
        formData.append("ttb", dropzoneTTB.fileStatuses[0].file);
      }

      if (!isFileExist.sj && !isFileExist.ttb) {
        setIsUploadedFileSJ(true);
        setIsUploadedFileTTB(true);
        return;
      }

      mutateEditRealization.mutate(formData, {
        onSuccess: () => {
          setIsSheetRealisasiOpen(false);
          setCurrentPage(0);
          setSelectedRealisasi({
            id: null,
            idFeed: null,
            idPeriod: null,
            houseName: "",
            houseId: "",
            fileSJ: null,
            fileTTB: null,
          });
          setIsUploadedFileSJ(false);
          setIsUploadedFileTTB(false);
          dropzoneSJ.reset();
          dropzoneTTB.reset();
          form.reset();
        },
      });
    } else {
      if (
        dropzoneSJ.fileStatuses.length < 1 ||
        dropzoneTTB.fileStatuses.length < 1
      ) {
        if (dropzoneSJ.fileStatuses.length < 1) setIsUploadedFileSJ(true);
        if (dropzoneTTB.fileStatuses.length < 1) setIsUploadedFileTTB(true);
        return;
      }

      const formData = new FormData();
      formData.append("data", JSON.stringify(payload));
      formData.append("sj", dropzoneSJ.fileStatuses[0].file);
      formData.append("ttb", dropzoneTTB.fileStatuses[0].file);

      mutateRealization.mutate(formData, {
        onSuccess: () => {
          setIsSheetRealisasiOpen(false);
          setCurrentPage(0);
          setSelectedRealisasi({
            id: null,
            idFeed: null,
            idPeriod: null,
            houseName: "",
            houseId: "",
            fileSJ: null,
            fileTTB: null,
          });
          setIsUploadedFileSJ(false);
          setIsUploadedFileTTB(false);
          dropzoneSJ.reset();
          dropzoneTTB.reset();
          form.reset();
        },
      });
    }
  };

  const handleSameWithSetting = () => {
    if (selectedRealisasi?.id) {
      const finds = data.find((item) => item.id === selectedRealisasi?.id);
      form.setValue(
        "dateRealization",
        finds?.dateSetting ? new Date(finds?.dateSetting) : new Date(),
      );
      form.setValue("qtyRealization", finds?.qtySetting);
      form.setValue("feedRealization", finds?.feedSetting?.id);
    }
  };

  const handleCheckAllChange = (checked) => {
    setSelectedAll(checked);
    setSelectedRows(checked ? data.map((item) => item.id) : []);
  };

  const handleCheckChange = (id) => {
    setSelectedRows((prev) => {
      if (prev.includes(id)) {
        const newSelection = prev.filter((item) => item !== id);
        setSelectedAll(false);
        return newSelection;
      } else {
        const newSelection = [...prev, id];
        setSelectedAll(newSelection.length === data.length);
        return newSelection;
      }
    });
  };

  const handleDownloadExcel = () => {
    if (downloadType === DOWNLOAD_TYPE.filterData) {
      mutateDownloadExcel.mutate(
        {
          search: value,
          hasRealized,
          year: year,
          month: month,
          week: selectedWeeks.join(","),
        },
        {
          onSuccess: () => {
            setIsDialogDownloadOpen(false);
          },
        },
      );
    } else if (downloadType === DOWNLOAD_TYPE.selectedData) {
      mutateDownloadExcel.mutate(
        {
          id: selectedRows.map((id) => id).join(","),
          hasRealized,
        },
        {
          onSuccess: () => {
            setIsDialogDownloadOpen(false);
          },
        },
      );
    } else {
      mutateDownloadExcel.mutate(
        {
          id: null,
          hasRealized,
        },
        {
          onSuccess: () => {
            setIsDialogDownloadOpen(false);
          },
        },
      );
    }
  };

  const handleChangeTab = (tab) => {
    setCurrentTab(tab.value);
    setSearch("");
    setCurrentPage(0);
    setSortBy("updatedAt");
    setDescending(true);
    setFilteredItems({
      year: null,
      month: null,
    });
    setYear("");
    setMonth("");
    setSelectedWeeks([]);
    setHasRealized(tab.value === "realisasi" ? true : false);
    setIsFilter(false);
    setIsEditRealisasi(false);
    setSelectedRows([]);
    setSelectedAll(false);
    setDownloadType(DOWNLOAD_TYPE.allData);
    setSelectedRealisasi({
      id: null,
      idFeed: null,
      idPeriod: null,
      houseName: "",
      houseId: "",
      fileSJ: null,
      fileTTB: null,
    });

    form.reset();
  };

  const columns = [
    {
      accessorKey: "rearingPeriod.house.name",
      header: ({ column }) => {
        const value = column.id;
        const isIndeterminate =
          selectedRows.length > 0 && selectedRows.length < data.length;

        return (
          <div className="flex w-full items-center gap-2">
            <Checkbox
              checked={
                selectedAll ? true : isIndeterminate ? "indeterminate" : false
              }
              onCheckedChange={handleCheckAllChange}
              aria-label="Select all"
            />
            <TableHeaderItem
              title="Nama kandang"
              value={value}
              handleSort={handleSort}
              sortBy={sortBy}
              isDescending={descending}
            />
          </div>
        );
      },
      cell: ({ row }) => {
        const id = row.original.id;
        const idPeriod = row.original?.rearingPeriod?.id;
        const label = row.original?.rearingPeriod?.house?.labels;
        const subLabel = row.original?.rearingPeriod?.analyticAccount;

        return (
          <div className="flex items-center gap-2">
            <Checkbox
              checked={selectedRows.includes(id)}
              onCheckedChange={() => handleCheckChange(id)}
              aria-label="Select row"
            />

            <button
              onClick={() => {
                setIsSheetDetailSettingOpen(true);
                setSelectedSetting({
                  id,
                  idFeed: row.original?.feedRealization?.id,
                  idPeriod,
                  houseName: label,
                  houseId: subLabel,
                });
              }}
              className="text-start hover:underline"
            >
              <TableCellItem
                variant="withBottomText"
                value={label}
                valueBottomSubText={subLabel}
              />
            </button>
          </div>
        );
      },
    },
    {
      accessorKey: "rearingPeriod.house.unit.name",
      header: ({ column }) => {
        const value = column.id;
        return (
          <TableHeaderItem
            title="Nama Unit"
            value={value}
            handleSort={handleSort}
            sortBy={sortBy}
            isDescending={descending}
            customClass="h-[26.7px] items-center"
          />
        );
      },
      cell: ({ row }) => {
        const value = row.original?.rearingPeriod?.house?.unit?.labels;

        return (
          <TableCellItem
            value={value}
            isNumber={false}
            customClass="text-text-secondary py-lg"
          />
        );
      },
    },
    {
      accessorKey: "dateSetting",
      header: ({ column }) => {
        const value = column.id;
        return (
          <TableHeaderItem
            title="Tanggal Rencana"
            value={value}
            handleSort={handleSort}
            sortBy={sortBy}
            isDescending={descending}
            customClass="h-[26.7px] items-center"
          />
        );
      },
      cell: ({ row }) => {
        const value = row.original?.dateSetting || new Date();
        const formatDate = format(new Date(value), "yyyy/MM/dd");

        return (
          <TableCellItem
            value={formatDate}
            isNumber={false}
            customClass="text-text-secondary py-lg"
          />
        );
      },
    },
    {
      accessorKey: "week",
      header: ({ column }) => {
        const value = column.id;
        return (
          <TableHeaderItem title="Minggu" value={value} disableSorting={true} />
        );
      },
      cell: ({ row }) => {
        const value = row.original?.weekSetting;
        return <BadgeWeek type={value} />;
      },
    },
    {
      accessorKey: "feedSetting",
      header: ({ column }) => {
        const value = column.id;
        return (
          <TableHeaderItem title="Pakan" value={value} disableSorting={true} />
        );
      },
      cell: ({ row }) => {
        const value = row.original?.feedSetting?.labels;
        const subValue = row.original?.feedSetting?.supplier;
        return (
          <TableCellItem
            value={value}
            valueBottomSubText={subValue}
            variant="withBottomText"
            customClass="text-text-secondary"
          />
        );
      },
    },
    {
      accessorKey: "feedType",
      header: ({ column }) => {
        const value = column.id;
        return (
          <TableHeaderItem
            title="Jenis pakan"
            value={value}
            disableSorting={true}
          />
        );
      },
      cell: ({ row }) => {
        const value = row.original?.feedSetting?.type?.id;
        return <BadgeStatus type={value} />;
      },
    },
    {
      accessorKey: "qtySetting",
      header: ({ column }) => {
        const value = column.id;
        return (
          <TableHeaderItem
            title="Kuantitas (Sak)"
            value={value}
            handleSort={handleSort}
            sortBy={sortBy}
            isDescending={descending}
            customClass="h-[26.7px]  items-center"
          />
        );
      },
      cell: ({ row }) => {
        const value = (row.original?.qtySetting ?? 0) / 50;

        return (
          <TableCellItem
            value={value}
            customClass="text-text-secondary  py-lg"
          />
        );
      },
    },
  ];

  const columnRealisasi = [
    {
      accessorKey: "rearingPeriod.house.name",
      header: ({ column }) => {
        const value = column.id;
        const isIndeterminate =
          selectedRows.length > 0 && selectedRows.length < data.length;

        return (
          <div className="flex w-full items-center gap-2">
            <Checkbox
              checked={
                selectedAll ? true : isIndeterminate ? "indeterminate" : false
              }
              onCheckedChange={handleCheckAllChange}
              aria-label="Select all"
            />
            <TableHeaderItem
              title="Nama kandang"
              value={value}
              handleSort={handleSort}
              sortBy={sortBy}
              isDescending={descending}
            />
          </div>
        );
      },
      cell: ({ row }) => {
        const id = row.original.id;
        const idPeriod = row.original?.rearingPeriod?.id;
        const label = row.original?.rearingPeriod?.house?.labels;
        const subLabel = row.original?.rearingPeriod?.analyticAccount;

        return (
          <div className="flex items-center gap-2">
            <Checkbox
              checked={selectedRows.includes(id)}
              onCheckedChange={() => handleCheckChange(id)}
              aria-label="Select row"
            />

            <button
              onClick={() => {
                setIsSheetDetailRealisasiOpen(true);
                setSelectedRealisasi({
                  id,
                  idFeed: row.original?.feedRealization?.id,
                  idPeriod,
                  houseName: label,
                  houseId: subLabel,
                  fileSJ: row.original?.media?.sj?.url,
                  fileTTB: row.original?.media?.ttb?.url,
                });
              }}
              className="text-start hover:underline"
            >
              <TableCellItem
                variant="withBottomText"
                value={label}
                valueBottomSubText={subLabel}
              />
            </button>
          </div>
        );
      },
    },
    {
      accessorKey: "rearingPeriod.house.unit.name",
      header: ({ column }) => {
        const value = column.id;
        return (
          <TableHeaderItem
            title="Nama Unit"
            value={value}
            handleSort={handleSort}
            sortBy={sortBy}
            isDescending={descending}
            customClass="h-[26.7px] items-center"
          />
        );
      },
      cell: ({ row }) => {
        const value = row.original?.rearingPeriod?.house?.unit?.labels;

        return (
          <TableCellItem
            value={value}
            isNumber={false}
            customClass="text-text-secondary py-lg"
          />
        );
      },
    },
    {
      accessorKey: "dateSetting",
      header: ({ column }) => {
        const value = column.id;
        return (
          <TableHeaderItem
            title="Tanggal"
            value={value}
            handleSort={handleSort}
            sortBy={sortBy}
            isDescending={descending}
            customClass="h-[26.7px] items-center"
          />
        );
      },
      cell: ({ row }) => {
        const value = row.original?.dateRealization || new Date();
        const formatDate = format(new Date(value), "yyyy/MM/dd");

        return (
          <TableCellItem
            value={formatDate}
            isNumber={false}
            customClass="text-text-secondary py-lg"
          />
        );
      },
    },
    {
      accessorKey: "feedRealization",
      header: ({ column }) => {
        const value = column.id;
        return (
          <TableHeaderItem title="Pakan" value={value} disableSorting={true} />
        );
      },
      cell: ({ row }) => {
        const value = row.original?.feedRealization?.labels;
        const subValue = row.original?.feedRealization?.supplier;
        return (
          <TableCellItem
            value={value}
            valueBottomSubText={subValue}
            variant="withBottomText"
            customClass="text-text-secondary"
          />
        );
      },
    },
    {
      accessorKey: "feedRealization.type",
      header: ({ column }) => {
        const value = column.id;
        return (
          <TableHeaderItem
            title="Jenis pakan"
            value={value}
            disableSorting={true}
          />
        );
      },
      cell: ({ row }) => {
        const value = row.original?.feedRealization?.type?.id;
        return <BadgeStatus type={value} />;
      },
    },
    {
      accessorKey: "qtyRealization",
      header: ({ column }) => {
        const value = column.id;
        return (
          <TableHeaderItem
            title="Kuantitas (Sak)"
            value={value}
            handleSort={handleSort}
            sortBy={sortBy}
            isDescending={descending}
            customClass="h-[26.7px]  items-center"
          />
        );
      },
      cell: ({ row }) => {
        const value = (row.original?.qtyRealization ?? 0) / 50;

        return (
          <TableCellItem
            value={value}
            customClass="text-text-secondary  py-lg"
          />
        );
      },
    },
    {
      accessorKey: "week",
      header: ({ column }) => {
        const value = column.id;
        return (
          <TableHeaderItem title="Minggu" value={value} disableSorting={true} />
        );
      },
      cell: ({ row }) => {
        const value = row.original?.weekSetting;
        return <BadgeWeek type={value} />;
      },
    },
    {
      accessorKey: "sjNumber",
      header: ({ column }) => {
        const value = column.id;
        return (
          <TableHeaderItem
            title="Surat Jalan"
            value={value}
            handleSort={handleSort}
            sortBy={sortBy}
            isDescending={descending}
          />
        );
      },
      cell: ({ row }) => {
        const value = row.original?.sjNumber;
        const type = row.original?.media?.sj?.contentType;
        return (
          <div className="flex items-center gap-md">
            <IconType type={type} />
            <TableCellItem value={value} customClass="text-text-secondary" />
          </div>
        );
      },
    },
    {
      accessorKey: "ttbNumber",
      header: ({ column }) => {
        const value = column.id;
        return (
          <TableHeaderItem
            title="Tanda Terima Barang"
            value={value}
            handleSort={handleSort}
            sortBy={sortBy}
            isDescending={descending}
          />
        );
      },
      cell: ({ row }) => {
        const value = row.original?.ttbNumber;
        const type = row.original?.media?.ttb?.contentType;
        return (
          <div className="flex items-center gap-md">
            <IconType type={type} />
            <TableCellItem value={value} customClass="text-text-secondary" />
          </div>
        );
      },
    },
  ];

  return (
    <SettingPakan.Provider
      value={{
        currentTab,
        search,
        setSearch,
        value,
        currentPage,
        setCurrentPage,
        itemPerPage,
        setItemPerPage,
        dataTotalItem,
        year,
        setYear,
        month,
        setMonth,
        selectedWeeks,
        setSelectedWeeks,
        docInDate,
        setDocInDate,
        initialCapacity,
        setInitialCapacity,
        finalCapacity,
        setFinalCapacity,
        isFilter,
        filteredItems,
        selectedRows,
        selectedSetting,
        setSelectedSetting,
        isSheetDetailSettingOpen,
        setIsSheetDetailSettingOpen,
        selectedRealisasi,
        setSelectedRealisasi,
        selectedFeedType,
        setSelectedFeedType,
        isUploadedFileSJ,
        isUploadedFileTTB,
        isSheetRealisasiOpen,
        setIsSheetRealisasiOpen,
        isSheetDetailRealisasiOpen,
        setIsSheetDetailRealisasiOpen,
        isFileExist,
        setIsFileExist,
        isEditRealisasi,
        setIsEditRealisasi,
        isDialogDownloadOpen,
        setIsDialogDownloadOpen,
        downloadType,
        setDownloadType,
        query,
        data,
        queryDetail,
        queryDetailSetting,
        mutateFeedData,
        mutateDownloadExcel,
        isPendingRealization,
        queryFileJS,
        queryFileTTB,
        form,
        dropzoneSJ,
        dropzoneTTB,
        handleResetFilter,
        handleApplyFilter,
        countFilteredItems,
        handleOnSubmit,
        handleSameWithSetting,
        handleDownloadExcel,
        handleChangeTab,
        columns,
        columnRealisasi,
      }}
    >
      {children}
    </SettingPakan.Provider>
  );
};

export const useSettingPakan = () => {
  const context = useContext(SettingPakan);

  if (context === undefined) {
    throw new Error("useSettingPakan must be used within a SettingPakanStore");
  }

  return context;
};
