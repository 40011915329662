/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
} from "@material-tailwind/react";
import Input from "../../../atoms/Input";
import { FaCheckCircle } from "react-icons/fa";
import { Button } from "../../../atoms/Button";
import api from "../../../api/configApi";
import AlertNotification from "../../../atoms/AlertNotification";
import ConfirmDialog from "../../../atoms/ConfirmDialog";
import InputNumber from "../../../atoms/NumberFormat";
import TextArea from "../../../atoms/TextArea";
import Dropdown from "../../../atoms/DropdownWithReset";
import { useSelector } from "react-redux";
import { ReactComponent as ArrowIncrement } from "../../../assets/icons/arrow-increment.svg";
import { ReactComponent as ArrowDecrement } from "../../../assets/icons/arrow-decrement.svg";

const EditKandangModal = ({
  isOpen,
  onClose,
  onReloadData,
  cageID,
  onUpdate,
}) => {
  const { user } = useSelector((state) => state.auth);
  const [name, setName] = useState("");
  const [capacity, setCapacity] = useState("");
  const [address, setAddress] = useState("");
  const [selectedTypeCageId, setSelectedTypeCageId] = useState("");
  const [selectedTypeCage, setSelectedTypeCage] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedCityId, setSelectedCityId] = useState(null);
  const [selectedUnitId, setSelectedUnitId] = useState("null");
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [typeCage, setTypeCage] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [city, setCity] = useState([]);
  const [isEditSuccess, setEditSuccess] = useState(false);
  const [isEditDialogOpen, setEditDialogOpen] = useState(false);
  const [dropdownProvince, setDropdownProvince] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState(null);
  const [selectedProvinceId, setSelectedProvinceId] = useState(null);

  const [isSuccess, setIsSuccess] = useState(false);
  const [units, setUnits] = useState([]);
  const [placeholderProvince, setPlaceholderProvince] = useState("");
  const [placeholderCity, setPlaceholderCity] = useState("");
  const [fetchData, setFetchData] = useState(false);
  const [cageNumber, setCageNumber] = useState(1);
  const [codeCage, setCodeCage] = useState(null);
  const [codeCageMerge, setCodeCageMerge] = useState("");
  const [triggerUpdate, setTriggerUpdate] = useState(false);
  const [errorResponse, setErrorResponse] = useState(null);
  const [showError, setShowError] = useState(false);
  const [currentCageNumber, setCurrentCageNumber] = useState(null);
  const [temporarySequence, setTemporarySequence] = useState([]);
  const [selectedOwner, setSelectedOwner] = useState(null);
  const [Owners, setOwners] = useState([]);
  const [selectedOwnerId, setSelectedOwnerId] = useState("");
  const [selectedHouseFloorId, setSelectedHouseFloorId] = useState("");
  const [selectedHouseFloor, setSelectedHouseFloor] = useState(null);

  const isSubmitDisabled =
    !selectedOwnerId ||
    !name ||
    !cageNumber ||
    !codeCage ||
    !address ||
    !typeCage ||
    !selectedHouseFloorId ||
    !capacity ||
    (user?.role === "Super Admin" && !selectedUnitId);

  const houseFloors = [
    { id: 1, labels: "1" },
    { id: 2, labels: "2" },
    { id: 3, labels: "3" },
  ];

  useEffect(() => {
    const fetchCage = async () => {
      try {
        const response = await api.get(`/v1/houses/${cageID}`);

        setSelectedOwnerId(response.data.owner.id);

        const convertedOwner = {
          id: response.data.owner.id,
          labels: `${response.data.owner.fullName} - ${response.data.owner.phoneNumber}`,
        };

        setSelectedOwner(convertedOwner);

        setName(response.data.name);
        setCapacity(response.data.maxCapacity);
        setAddress(response.data.address);
        setSelectedTypeCage(response.data.type);
        setSelectedTypeCageId(response.data.type.id);
        setCodeCage(response.data.code);
        setPlaceholderCity(response?.data?.city?.labels);

        const convertedFloor = {
          id: response.data.totalFloor,
          labels: response.data.totalFloor,
        };
        setSelectedHouseFloor(convertedFloor);
        setSelectedHouseFloorId(response.data.totalFloor);

        setPlaceholderProvince(response?.data?.province?.labels);
        setSelectedProvinceId(response?.data?.province?.id);
        setSelectedProvince(response?.data?.province);
        setSelectedCityId(response?.data?.city?.id);
        setSelectedCity(response?.data?.city);
        setSelectedUnitId(response?.data?.unit?.id);
        setSelectedUnit(response?.data?.unit);
        setCageNumber(response?.data?.sequence);
        setCodeCage(response?.data?.code);
        setCodeCageMerge(response?.data?.code);
        setCurrentCageNumber(response?.data?.sequence);
      } catch (error) {
        console.error("Error fetching docs:", error);
      }
    };

    if (isOpen && cageID) {
      fetchCage();
    }

    if (fetchData) {
      setFetchData(false);
    }

    setShowError(false);
  }, [isOpen, cageID, fetchData]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const params = {
          showPhone: 1,
        };
        const response = await api.get("/v1/farmers/dropdown", { params });
        setOwners(response?.data?.contents);
      } catch (error) {
        return error;
      }
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    const fetchTypes = async () => {
      try {
        const response = await api.get("/v1/houses/types");
        setTypeCage(response.data.contents);
      } catch (error) {
        console.error("Error fetching strains:", error);
      }
    };

    fetchTypes();
  }, []);

  useEffect(() => {
    const fetchMasterUnit = async () => {
      try {
        const responseList = await api.get("/v1/units/dropdown");
        setUnits(responseList?.data?.contents);
      } catch (error) {
        console.log(error.message);
      }
    };

    fetchMasterUnit();
  }, []);

  useEffect(() => {
    const fetchProvince = async () => {
      try {
        const responseProvince = await api.get(
          "/v1/regions/id/province/dropdown"
        );
        setDropdownProvince(responseProvince.data.contents);
      } catch (error) {
        console.log(error);
      }
    };

    fetchProvince();
  }, []);

  useEffect(() => {
    const fetchCity = async () => {
      try {
        const response = await api.get("/v1/regions/id/cities/dropdown", {
          params: {
            province: selectedProvinceId,
          },
        });
        setCity(response.data.contents);
      } catch (error) {
        console.log(error);
      }
    };

    fetchCity();
  }, [selectedProvinceId]);

  useEffect(() => {
    if (isOpen && selectedOwnerId) {
      const checkingSequence = async () => {
        try {
          const responseChecking = await api.get(
            `/v1/houses/sequences?owner=${selectedOwnerId}`
          );
          setTemporarySequence(responseChecking?.data?.registeredSequences);
        } catch (error) {
          console.log("err", error);
          return error;
        }
      };

      checkingSequence();
    }
  }, [selectedOwnerId, isOpen]);

  const handleOpenEditDialog = () => {
    if (errorResponse === 400 && showError) {
      setEditDialogOpen(false);
    } else {
      setEditDialogOpen(true);
    }
  };

  const handleCloseEditDialog = () => {
    setEditDialogOpen(false);
  };

  const handleUpdate = async () => {
    setIsSubmitting(true);

    try {
      let requestEditData = {
        sequence: cageNumber,
        owner: { id: selectedOwnerId },
        name: name,
        // code: codeKandang,
        maxCapacity:
          typeof capacity === "string" && capacity.includes(".")
            ? parseInt(capacity.replace(".", ""))
            : capacity,
        type: selectedTypeCageId,
        totalFloor: selectedHouseFloorId,
        address: address,
        province: selectedProvince === null ? null : selectedProvince,
        city: selectedCity === null ? null : selectedCity,
      };

      if (selectedUnitId.length > 0) {
        requestEditData.unit = { id: selectedUnitId };
      }

      setIsSubmitting(true);
      const response = await api.put(`/v1/houses/${cageID}`, requestEditData);
      setFetchData(true);
      localStorage.setItem(
        "responseEditSuccess",
        JSON.stringify(!isEditSuccess)
      );

      window.location.reload();
      setIsSuccess(true);
      setEditSuccess(true);
      setIsSubmitting(false);
      setEditDialogOpen(false);

      if (onUpdate) {
        onUpdate(response.data);
      }
      if (onReloadData) {
        onReloadData();
      }

      setEditSuccess(true);
      setTimeout(() => setEditSuccess(false), 2000);
      onClose();

      console.log(requestEditData);
    } catch (error) {
      console.error("Error updating data kandang:", error);
      setIsSubmitting(false);
      setEditDialogOpen(false);
      setShowError(true);

      if (errorResponse === 400) {
        showError(true);
      }
    }

    setTriggerUpdate(true);
  };

  const handleSelectOwner = (select) => {
    setSelectedOwnerId(select.id);
    setSelectedOwner(select);

    if (selectedOwnerId !== null) {
      setTriggerUpdate(true);
    }
  };

  const handleSelectCage = (select) => {
    setSelectedTypeCageId(select.id);
    setSelectedTypeCage(select);
  };

  const handleCitySelected = (select) => {
    setSelectedCityId(select.id);
    setSelectedCity(select);
  };

  const handleSelectFloor = (select) => {
    setSelectedHouseFloorId(select.id);
    setSelectedHouseFloor(select);
  };

  const handleSelectProvince = (select) => {
    setSelectedProvinceId(select.id);
    setSelectedProvince(select);
  };

  const handleSelectMasterUnit = (select) => {
    setSelectedUnitId(select.id);
    setSelectedUnit(select);
  };

  const handleCapacityChange = (e) => {
    setCapacity(e.target.value);
  };

  const handleChangeAddress = (e) => {
    if (e.target.value.length <= 100) {
      setAddress(e.target.value);
    }
  };

  const incrementValue = () => {
    setCageNumber((prevValue) => {
      const newValue = prevValue + 1;
      checkDuplicateSequence(newValue);
      const format = codeCage.replace(/\/\d+$/, "/");
      setCodeCageMerge(`${format + String(newValue).padStart(2, "0")}`);
      return newValue;
    });
  };

  const decrementValue = () => {
    setCageNumber((prevValue) => {
      if (prevValue > 1) {
        const newValue = prevValue - 1;
        checkDuplicateSequence(newValue);
        const format = codeCage.replace(/\/\d+$/, "/");
        setCodeCageMerge(`${format + String(newValue).padStart(2, "0")}`);
        return newValue;
      }

      return prevValue;
    });
  };

  // const handleChange = (e) => {
  //   const newValue = parseInt(e.target.value, 10);
  //   if (!isNaN(newValue)) {
  //     setCageNumber(newValue);
  //     checkDuplicateSequence(newValue);
  //     const format = codeCage.replace(/\/\d+$/, "/");
  //     setCodeCageMerge(`${format + String(newValue).padStart(2, "0")}`);
  //   }
  // };

  const handleChange = (e) => {
    let inputValue = e.target.value;
    let newValue = inputValue === "" ? "" : parseInt(inputValue, 10);

    if (isNaN(newValue) || newValue < 0) {
      newValue = 0;
    } else if (newValue < 0 && inputValue !== "") {
      newValue = 0;
    }

    setCageNumber(newValue);
    setCodeCageMerge(`${codeCage + String(newValue).padStart(2, "0")}`);

    if (newValue > 0 || newValue === "") {
      checkDuplicateSequence(newValue);
    }
  };

  const checkDuplicateSequence = (sequence) => {
    if (temporarySequence.includes(sequence)) {
      setShowError(true);
    } else {
      setShowError(false);
    }

    if (sequence === currentCageNumber) {
      setShowError(false);
    }
  };

  return (
    <div>
      {/* Modal Overlay */}
      {isOpen && (
        <div className="fixed inset-0 bg-gray-800 opacity-50 z-50"></div>
      )}
      <Card
        className={`fixed top-1/2 left-1/2 w-[600px] transform -translate-x-1/2 -translate-y-1/2 z-[100] ${
          isOpen ? "block" : "hidden"
        } rounded-xl flex-col justify-start items-start`}
      >
        <Card className="h-[730px] overflow-auto">
          <CardHeader
            floated={false}
            shadow={false}
            className="border-b fixed w-full border-border-secondary rounded-b-none p-0 m-0 flex justify-between items-center z-50"
          >
            {/* Modal Title */}
            <Typography
              type="h2"
              className="text-xl text-text-primary font-bold p-5 m-0"
            >
              Ubah kandang
            </Typography>

            <button className="pr-4 text-gray-500" onClick={onClose}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </CardHeader>
          <CardBody className="flex-1 p-5 mt-[64px]">
            <div className="grid grid-cols-1 gap-2">
              <Typography className="text-text-primary text-sm font-medium leading-tight">
                Pemilik Kandang <span className="text-red-600">*</span>
              </Typography>
              <Dropdown
                onSelect={handleSelectOwner}
                placeholder="Pilih Pemilik"
                options={Owners}
                selectedOption={selectedOwner}
              ></Dropdown>
            </div>
            <div className="grid grid-cols-1 my-2xl gap-2">
              <Typography className="text-text-primary text-sm font-medium leading-tight">
                Nama Kandang <span className="text-[#F04438]">*</span>
              </Typography>
              <Input
                type="text"
                id="name"
                className="placeholder:text-text-placeholder text-text-secondary"
                value={name}
                size="medium"
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            <div className="flex flex-wrap justify-between gap-4 max-w-full">
              <div className="w-full md:w-auto">
                <Typography className="text-text-primary text-sm font-medium leading-tight mb-md">
                  No Kandang <span className="text-red-600">*</span>
                </Typography>
                <div>
                  <Input
                    type="text"
                    id="name"
                    value={cageNumber}
                    onChange={handleChange}
                    placeholder="No Kandang"
                    className={`w-[93%] max-w-full  placeholder:text-text-placeholder appearance-none ${
                      showError && "border-red-500"
                    }`}
                    size="medium"
                    disabled={selectedOwnerId.length > 0 ? false : true}
                    componentRight={
                      <div
                        className={`flex flex-col absolute right-0 justify-between items-center w-[30px] h-full bg-bg-secondary_hover rounded-r-lg border ${
                          showError && "border-red-500"
                        }`}
                      >
                        <div
                          onClick={incrementValue}
                          className="cursor-pointer hover:bg-gray-300 rounded-xs"
                        >
                          <ArrowIncrement className="h-5 w-5" />
                        </div>
                        <div
                          onClick={decrementValue}
                          className="cursor-pointer hover:bg-gray-300 rounded-xs"
                        >
                          <ArrowDecrement className="h-5 w-5" />
                        </div>
                      </div>
                    }
                  />
                  {showError && (
                    <div className="pt-2">
                      <Typography className="text-sm text-text-errorPrimary w-full md:w-[240px] h-[30px] text-start">
                        Kode kandang telah digunakan, masukkan nomor lain.
                      </Typography>
                    </div>
                  )}
                </div>
              </div>

              <div className="w-full md:w-auto">
                <Typography className="text-text-primary text-sm font-medium leading-tight mb-md">
                  Kode Kandang <span className="text-red-600">*</span>
                </Typography>

                <Input
                  type="number"
                  id="name"
                  value={codeCageMerge}
                  placeholder={codeCageMerge}
                  className="w-full md:w-[280px] max-w-full appearance-none"
                  size="medium"
                  disabled={true}
                />
              </div>
            </div>

            <div className="grid grid-cols-1 gap-4 my-2xl">
              <div>
                <Typography className="text-text-primary text-sm font-medium leading-tight">
                  Kapasitas Kandang
                </Typography>
                <InputNumber
                  type="text"
                  id="capasity"
                  className=" placeholder:text-text-placeholder text-text-secondary"
                  value={capacity}
                  size="medium"
                  onChange={handleCapacityChange}
                />
              </div>
            </div>
            <div className="grid grid-cols-1 gap-4">
              <div>
                <Typography className="text-text-primary text-sm font-medium leading-tight mb-2">
                  Jenis Kandang <span className="text-[#F04438]">*</span>
                </Typography>

                <Dropdown
                  onSelect={handleSelectCage}
                  placeholder="Pilih Tipe Kandang"
                  options={typeCage}
                  selectedOption={selectedTypeCage}
                ></Dropdown>
              </div>
            </div>
            <div className="grid grid-cols-1 gap-4 my-2xl">
              <div>
                <Typography className="text-text-primary text-sm font-medium leading-tight mb-2">
                  Tingkat Kandang <span className="text-[#F04438]">*</span>
                </Typography>
                <Dropdown
                  onSelect={handleSelectFloor}
                  placeholder="Pilih Tingkat Kandang"
                  options={houseFloors}
                  selectedOption={selectedHouseFloor}
                ></Dropdown>
              </div>
            </div>

            <div className="grid grid-cols gap-4 my-2xl">
              <Typography className="text-text-primary text-sm font-medium leading-tight">
                {/* Provinsi <span className="text-[#F04438]">*</span> */}
                Provinsi
              </Typography>

              <div className="mt-[-8px]">
                <Dropdown
                  onSelect={handleSelectProvince}
                  placeholder="Pilih Provinsi"
                  options={dropdownProvince}
                  selectedOption={selectedProvince}
                ></Dropdown>
              </div>
            </div>

            <div className="grid grid-cols-1 gap-4 my-2xl">
              <div>
                <Typography className="text-text-primary text-sm font-medium leading-tight mb-2">
                  {/* Kabupaten/Kota <span className="text-[#F04438]">*</span> */}
                  Kabupaten/Kota
                </Typography>
                <Dropdown
                  onSelect={handleCitySelected}
                  placeholder="Pilih Kab/Kota"
                  options={city}
                  selectedOption={selectedCity}
                ></Dropdown>
              </div>
            </div>

            <div className="grid grid-cols-1 gap-4">
              <div>
                <Typography className="text-text-primary text-sm font-medium leading-tight mb-2">
                  Alamat Kandang
                </Typography>
                <TextArea
                  placeholder="Alamat Kandang"
                  value={address}
                  onChange={handleChangeAddress}
                  className="placeholder:text-text-placeholder text-text-secondary"
                />

                <div>
                  <Typography className="text-sm font-semibold p-1 mt-[-24px] pt-6 text-text-placeholder">
                    {address.length} / 100
                  </Typography>
                </div>
              </div>

              {user?.role === "Super Admin" && (
                <div>
                  <Typography className="text-[14px] font-medium pt-2 text-text-primary mb-md">
                    Unit <span className="text-[#F04438]">*</span>
                  </Typography>
                    <Dropdown
                      onSelect={handleSelectMasterUnit}
                      placeholder="Pilih Unit"
                      options={units}
                      selectedOption={selectedUnit}
                    ></Dropdown>
 
                </div>
              )}
            </div>
          </CardBody>
          <CardFooter className="flex justify-end border-t border-border-secondary p-lg">
            <Button
              label="Batal"
              size="medium"
              type="btnSecondary"
              className="mr-md"
              onClick={onClose}
            />
            <Button
              label="Simpan"
              size="medium"
              type={`${
                isSubmitDisabled || isSubmitting
                  ? "btnPrimaryDisabled"
                  : "btnPrimary"
              }`}
              onClick={handleOpenEditDialog}
              disabled={isSubmitDisabled || isSubmitting}
              componentLeft={
                isSubmitting && (
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    className="animate-spin fill-neutral-400"
                  >
                    <path
                      d="M10.72,19.9a8,8,0,0,1-6.5-9.79A7.77,7.77,0,0,1,10.4,4.16a8,8,0,0,1,9.49,6.52A1.54,1.54,0,0,0,21.38,12h.13a1.37,1.37,0,0,0,1.38-1.54,11,11,0,1,0-12.7,12.39A1.54,1.54,0,0,0,12,21.34h0A1.47,1.47,0,0,0,10.72,19.9Z"
                      className="spinner_6kVp"
                    />
                  </svg>
                )
              }
            />
          </CardFooter>
        </Card>
      </Card>
      {/* Komponen dialog konfirmasi edit*/}
      <ConfirmDialog
        isOpen={isEditDialogOpen}
        onClose={handleCloseEditDialog}
        onConfirm={handleUpdate}
        title="Ubah data Kandang"
        content="Anda yakin ingin melanjutkan tindakan ini?"
        btnLabel="Lanjutkan"
        btnType="btnPrimary"
      />
      {isEditSuccess && (
        <AlertNotification
          icon={<FaCheckCircle />}
          color="green"
          message="Data Kandang berhasil diubah."
        />
      )}
    </div>
  );
};

export default EditKandangModal;
