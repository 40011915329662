import { useState } from "react";

import { cn } from "../../shared/utils";
import { Dialog, DialogContent } from "./Dialog";

const ImageWithModal = ({ src, alt, className, ...props }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <img
        src={src}
        alt={alt}
        className={cn("cursor-pointer", className)}
        onClick={() => setIsOpen(true)}
        {...props}
      />
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent className="w-fit max-w-3xl p-0">
          <div className="relative h-full max-h-[80dvh] w-full">
            <img
              src={src}
              alt={alt}
              className="h-full w-full rounded object-contain"
            />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ImageWithModal;
