import React from "react";

import { FILE_CONTENT_TYPE } from "../../shared/variables";
import { IcFileTypeJpg, IcFileTypePdf } from "../icons";

const IconType = ({ type }) => {
  if (FILE_CONTENT_TYPE.IMAGE.includes(type)) {
    return <IcFileTypeJpg />;
  }

  if (type === FILE_CONTENT_TYPE.PDF) {
    return <IcFileTypePdf />;
  }

  return null;
};

export default IconType;
