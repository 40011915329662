import { z } from "zod";

export const formSchema = z.object({
  dateSetting: z.date({
    message: "Tanggal tidak boleh kosong.",
  }),
  houses: z
    .array(
      z.object({
        rearingPeriod: z.string().min(1, {
          message: "Nama kandang tidak boleh kosong.",
        }),
        idPeriod: z.string().optional(),
        feedSetting: z.string().min(1, {
          message: "Pakan tidak boleh kosong.",
        }),
        qtySetting: z
          .number({
            message: "Sak tidak boleh kosong.",
          })
          .min(1, {
            message: "Sak tidak boleh kosong.",
          }),
      }),
    )
    .min(1, {
      message: "Minimal satu kandang harus diisi.",
    }),
});
