import React from "react";

import SapronakDOCFeature from "./feature";
import { SettingDOCStore } from "./hooks";

const SapronakDOC = () => {
  return (
    <SettingDOCStore>
      <SapronakDOCFeature />
    </SettingDOCStore>
  );
};

export default SapronakDOC;
