import * as React from "react";

function IcClipboardPlus(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M13.334 3.333c.774 0 1.162 0 1.48.086a2.5 2.5 0 011.768 1.767c.085.318.085.706.085 1.48v7.667c0 1.4 0 2.1-.273 2.635a2.5 2.5 0 01-1.092 1.093c-.535.272-1.235.272-2.635.272H7.334c-1.4 0-2.1 0-2.635-.272a2.5 2.5 0 01-1.093-1.093c-.272-.534-.272-1.235-.272-2.635V6.667c0-.775 0-1.163.085-1.48a2.5 2.5 0 011.767-1.768c.318-.086.706-.086 1.48-.086M10 14.167v-5m-2.5 2.5h5M8 5h4c.467 0 .7 0 .879-.09a.833.833 0 00.364-.365c.09-.178.09-.412.09-.878V3c0-.467 0-.7-.09-.878a.833.833 0 00-.364-.364c-.179-.091-.412-.091-.879-.091H8c-.467 0-.7 0-.878.09a.833.833 0 00-.364.365c-.091.178-.091.411-.091.878v.667c0 .466 0 .7.09.878.08.157.208.284.365.364C7.3 5 7.533 5 8 5z"
        stroke="currentColor"
        strokeWidth={1.66667}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default IcClipboardPlus;
