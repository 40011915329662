import { useQuery } from "@tanstack/react-query";

import api from "../../api/configApi";

const useGetDailyRecords = ({ periodId }) => {
  const query = useQuery({
    queryKey: ["get-daily-record", { periodId }],
    queryFn: async () => {
      const response = await api.get("/v1/dailyRecords", {
        params: {
          periodId,
          sortBy: "recordDate",
          descending: false,
        },
      });
      return response;
    },
    select: ({ data }) => data,
  });

  return query;
};

export default useGetDailyRecords;
