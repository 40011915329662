import React from "react";

import { cn } from "../../../../../../../shared/utils";
import {
  FEED_TYPE,
  FEED_TYPE_LIST,
} from "../../../../../../../shared/variables";

const BadgeStatus = ({ type }) => {
  const badgeColor = {
    [FEED_TYPE.FINISHER]: "text-[#2D61D6] border-[#A0BBF6] bg-[#F3F6FD]",
    [FEED_TYPE.GROWER]: "text-neutral-700 border-neutral-200 bg-neutral-25",
    [FEED_TYPE.PRESTARTER]: "text-neutral-700 border-neutral-200 bg-neutral-25",
    [FEED_TYPE.STARTER]:
      "text-text-warningPrimary border-warning-200 bg-warning-25",
  };

  return (
    <span
      className={cn(
        "rounded-sm border px-[6px] text-xs font-medium",
        badgeColor[type],
      )}
    >
      {FEED_TYPE_LIST.find((item) => item.value === type)?.label}
    </span>
  );
};

export default BadgeStatus;
