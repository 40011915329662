import * as React from "react";

import { Slot } from "@radix-ui/react-slot";
import { cva } from "class-variance-authority";

import { cn } from "../../shared/utils";
import { Error, Primary, Secondary } from "../../static/colors/ButtonsColor";

const buttonVariants = cva(
  "min-w-6 w-full gap-2.5 inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-md transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0",
  {
    variants: {
      variant: {
        btnPrimary: `${Primary["bg"]} ${Primary["bg_hover"]} border ${Primary["border"]} ${Primary["border_hover"]} text-button-primaryFg focus:shadow-sm focus:shadow-primary-500 `,
        btnSecondary: `${Secondary["bg"]} ${Secondary["bg_hover"]} border ${Secondary["border"]} ${Secondary["border_hover"]} text-text-secondary focus:shadow-sm focus:shadow-gray-300`,
        btnLinkBlack: `bg-none border-0 text-slate-600 hover:text-neutral-800`,
        btnLinkPrimary: `bg-none border-0 text-primary-500 hover:text-primary-600`,
        btnLinkBlue: `bg-none border-0 text-blue-600 hover:text-blue-800`,
        btnLinkRed: `bg-none border-0 text-red-600 hover:text-red-800`,
        btnError: `${Error["bg"]} ${Error["bg_hover"]} border ${Error["border"]} ${Error["border_hover"]} text-white`,
        btnPrimaryDisabled: "bg-gray-100 border border-gray-200 text-gray-400",
        btnLinkDisabled: "bg-none border-0 text-gray-400",
        btnInfoOutlined:
          "bg-none border-2 border-blue-500 text-blue-600 hover:text-blue-800 hover:border-blue-800",
        btnOutlinedGrey:
          "bg-none border border-gray-300 text-text-primary hover:bg-neutral-100",
        btnErrorOutlined: `bg-none hover:text-red-700 hover:border-red-700 border-2 ${Error["border"]} ${Error["border_hover"]} text-red-600`,
        ghost: "hover:bg-[#f5f5f5] hover:text-[#171717]",
      },
      size: {
        xsmall:
          "px-2 py-[2px] justify-center items-center gap-xs inline-flex text-sm font-bold rounded-sm",
        small:
          "px-[12px] py-[7px] h-[36px] justify-center items-center gap-sm inline-flex text-sm font-bold rounded-md",
        medium:
          "px-xl py-[11px] justify-center items-center gap-md inline-flex text-sm font-bold rounded-md",
        large:
          "px-3xl py-4 justify-center items-center gap-2.5 inline-flex text-sm font-bold rounded-md",
        icon: "h-6 w-6 p-4 justify-center items-center inline-flex rounded-md",
      },
    },
    defaultVariants: {
      variant: "btnPrimary",
      size: "small",
    },
  },
);

const Button = React.forwardRef(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  },
);
Button.displayName = "Button";

export { Button, buttonVariants };
