export const fixWording = (type) => {
  switch (type) {
    case "dashboard":
      return "Dashboard";
    case "KYC":
      return "KYC";
    case "pengaturan-pengguna":
      return "Pengaturan Pengguna";
    case "stok":
      return "Stok";
    case "livebird":
      return "Livebird";
    case "master":
      return "Master";
    case "Dokumen":
      return "Dokumen";
    case "periode-budidaya":
      return "Periode Budidaya";
    case "periode-berjalan":
      return "Periode Berjalan";
    case "monitoring-growing":
      return "Monitoring Growing";
    case "periode-selesai":
      return "Periode Selesai";
    case "doc":
      return "DOC";
    case "ovk":
      return "OVK";
    case "update-ovk":
      return "Ubah OVK";
    case "tambah-ovk":
      return "Tambah OVK";
    case "pakan":
      return "Pakan";
    case "unit":
      return "Unit";
    case "edit-unit":
      return "Ubah Unit";
    case "tambah-unit":
      return "Tambah Unit";
    case "edit-user-role":
      return "Role Akses";
    case "tambah-user-role":
      return "Buat Role Baru";
    case "role-akses":
      return "Role Akses";
    case "user":
      return "Pengguna";
    case "kandang":
      return "Kandang";
    case "tambah-kandang":
      return "Tambah Kandang";
    case "testing":
      return "Testing";
    case "detail-kandang":
      return "Pengaturan Kandang";
    case "update-pakan":
      return "Update Pakan";
    case "tambah-pakan":
      return "Tambah Pakan";
    case "data-harian":
      return "Data Harian";
    case "tambah-data-harian":
      return "Tambah data harian";
    case "ubah-data-harian":
      return "Ubah data harian";
    case "panen":
      return "Panen";
    case "detail-panen":
      return "Detail Panen";
    case "bakul":
      return "Bakul";
    case "edit-bakul":
      return "Edit Bakul";
    case "detail-data-harian":
      return "Detail Data Harian";
    case "mutasi":
      return "Mutasi Pakan";
    case "detailMutasi":
      return "Detail Mutasi";
    case "editMutasi":
      return "Ubah data mutasi";
    case "tambah-mutasi":
      return "Tambah mutasi";
    case "region":
      return "Region";
    case "tambah-region":
      return "Tambah Region";
    case "tambah-bakul":
      return "Tambah Bakul";
    case "edit-region":
      return "Edit Region";
    case "recomendation-add-ppl":
      return "Rekomendasi tambah PPL";
    case "farmer":
      return "Peternak";
    case "sapronak":
      return "Sapronak";
    case "dataharian":
      return "Data Harian";
    case "period":
      return "Ringkasan";
    case "rekapitulasi":
      return "Rekapitulasi";
    case "master-periode":
      return "Master Periode";
    case "master-periode-region":
      return "Master Periode";
    case "tambah-periode":
      return "Tambah Periode";
    case "edit-periode":
      return "Edit Periode";
    case "region-master-periode":
      return "Master Periode";
    case "manajemen-sapronak":
      return "Manajemen Sapronak";
    case "laporan-sapronak":
      return "Laporan Sapronak";
    case "setting-doc":
      return "Setting DOC";
    case "setting-pakan":
      return "Setting pakan";
    default:
      return null;
  }
};
