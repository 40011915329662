import * as React from "react";

function IcPackageCheck(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M13.003 4.755L7.336 7.903m0 0L1.669 4.755m5.667 3.148v6.333m1.333-.407l-.815.453c-.19.105-.284.157-.384.178a.668.668 0 01-.268 0c-.1-.02-.195-.073-.384-.178L1.885 11.54c-.2-.111-.3-.167-.373-.245a.666.666 0 01-.142-.243c-.034-.102-.034-.216-.034-.445V5.197c0-.228 0-.343.034-.444a.667.667 0 01.142-.243c.073-.079.173-.134.373-.245l4.933-2.741c.189-.105.284-.158.384-.178a.667.667 0 01.268 0c.1.02.195.073.384.178l4.933 2.74c.2.112.3.167.372.246.065.07.114.152.143.243.034.101.034.216.034.444v3.04m-9-5.334l6 3.333m-.333 5.667l1.333 1.333 2.667-2.667"
        stroke="currentColor"
        strokeWidth={1.33333}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default IcPackageCheck;
