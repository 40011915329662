import { useMutation, useQueryClient } from "@tanstack/react-query";

import api from "../../api/configApi";
import { toast } from "../../atoms/V2/useToast";

const useMutateEditDailyRecord = ({ id }) => {
  const queryClient = useQueryClient();
  const mutate = useMutation({
    mutationFn: async (values) => {
      const response = await api.put(`/v1/dailyRecords/${id}`, values);
      return response;
    },
    onSuccess: () => {
      toast({
        title: "Data harian berhasil diubah.",
        variant: "success",
      });
      queryClient.invalidateQueries({
        queryKey: ["get-daily-record"],
      });
      queryClient.invalidateQueries({
        queryKey: ["get-next-record-period"],
      });
      queryClient.invalidateQueries({
        queryKey: ["get-detail-daily-record"],
      });
    },
    onError: (error) => {
      console.log(error);
      toast({
        title: "Gagal mengubah data harian.",
        description: error.response.data.error,
        variant: "error",
      });
    },
  });

  return mutate;
};

export default useMutateEditDailyRecord;
