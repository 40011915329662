import { useQuery } from "@tanstack/react-query";

import api from "../../../api/configApi";

const useGetDetailSettingDOC = (id) => {
  const query = useQuery({
    queryKey: ["get-detail-setting-doc", id],
    queryFn: async () => {
      const response = await api.get(`/v1/docSettings/${id}`);
      return response;
    },
    select: (data) => data.data,
    enabled: !!id,
  });

  return query;
};

export default useGetDetailSettingDOC;
