import React from "react";

import SapronakPakanFeature from "./feature";
import { SettingPakanStore } from "./hooks";

const SapronakPakan = () => {
  return (
    <SettingPakanStore>
      <SapronakPakanFeature />
    </SettingPakanStore>
  );
};

export default SapronakPakan;
