import { useQuery } from "@tanstack/react-query";

import api from "../../api/configApi";

const useGetUnitDropdownList = ({ search = "", region = ""}) => {
  const query = useQuery({
    queryKey: ["get-unit-dropdown-list", { search, region }],
    queryFn: async () => {
      const response = await api.get("/v1/units/dropdown", {
        params: {
          search,
          region
        },
      });
      return response;
    },
    select: ({ data }) => data?.contents,
  });

  return query;
};

export default useGetUnitDropdownList;
