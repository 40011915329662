import * as React from "react";

import { cva } from "class-variance-authority";

import { cn } from "../../shared/utils";

const badgeVariants = cva(
  "inline-flex items-center rounded-md border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
  {
    variants: {
      variant: {
        default:
          "border-border-primary bg-white text-text-secondary !rounded-[6px] hover:bg-white/80",
        error:
          "border-transparent bg-fg-errorPrimary text-text-primary shadow hover:bg-fg-errorPrimary/80",
        outline: "text-text-secondary",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  },
);

function Badge({ className, variant, ...props }) {
  return (
    <div className={cn(badgeVariants({ variant }), className, "")} {...props} />
  );
}

export { Badge, badgeVariants };
