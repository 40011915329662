import React from "react";

import { FiEdit } from "react-icons/fi";

import { IcBurgerDot } from "../../../../../../../atoms/icons";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../../../../../atoms/V2/Popover";
import { useRecordingHarian } from "../hook";

const CellAction = ({ id }) => {
  const { setSelectedDetail, setIsAddDrawerVisible, setIsEdit, queryDetail } =
    useRecordingHarian();

  return (
    <>
      <Popover>
        <PopoverTrigger asChild>
          <button className="rounded p-xs hover:bg-gray-200">
            <IcBurgerDot className="size-4" />
          </button>
        </PopoverTrigger>
        <PopoverContent align="start" side="right" className="max-w-44 p-0">
          <div className="flex flex-col">
            <button
              className="flex items-center gap-md px-xl py-[10px] transition-colors hover:bg-gray-50"
              onClick={() => {
                queryDetail.refetch();
                setSelectedDetail(id);
                setIsEdit(true);
                setIsAddDrawerVisible(true);
              }}
            >
              <FiEdit className="size-4" />
              <span className="text-sm">Edit</span>
            </button>
          </div>
        </PopoverContent>
      </Popover>
    </>
  );
};

export default CellAction;
