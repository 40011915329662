export const GUARANTEE_TYPE = [
  {
    label: "Sertifikat",
    value: "SERTIFICATE",
  },
  {
    label: "BPKB",
    value: "BPKB",
  },
  {
    label: "Uang Tunai",
    value: "CASH_MONEY",
  },
];

export const FEED_TYPE = {
  PRESTARTER: "PRESTARTER",
  STARTER: "STARTER",
  GROWER: "GROWER",
  FINISHER: "FINISHER",
};

export const FEED_TYPE_LIST = [
  {
    label: "Prestarter",
    value: FEED_TYPE.PRESTARTER,
  },
  {
    label: "Starter",
    value: FEED_TYPE.STARTER,
  },
  {
    label: "Grower",
    value: FEED_TYPE.GROWER,
  },
  {
    label: "Finisher",
    value: FEED_TYPE.FINISHER,
  },
];

export const FILE_CONTENT_TYPE = {
  IMAGE: ["image/png", "image/jpeg", "image/jpg", "image/gif", "image/svg"],
  PDF: "application/pdf",
};

export const WEEK = [
  {
    label: "Minggu 1",
    value: 1,
  },
  {
    label: "Minggu 2",
    value: 2,
  },
  {
    label: "Minggu 3",
    value: 3,
  },
  {
    label: "Minggu 4",
    value: 4,
  },
  {
    label: "Minggu 5",
    value: 5,
  },
];

export const years = (() => {
  const currentYear = new Date().getFullYear();
  return Array.from({ length: 1 }, (_, i) => currentYear - i);
})();

export const months = [
  { value: "1", label: "Januari" },
  { value: "2", label: "Februari" },
  { value: "3", label: "Maret" },
  { value: "4", label: "April" },
  { value: "5", label: "Mei" },
  { value: "6", label: "Juni" },
  { value: "7", label: "Juli" },
  { value: "8", label: "Agustus" },
  { value: "9", label: "September" },
  { value: "10", label: "Oktober" },
  { value: "11", label: "November" },
  { value: "12", label: "Desember" },
];
