import { z } from "zod";

export const DOWNLOAD_TYPE = {
  allData: "all_data",
  selectedData: "selected_data",
  filterData: "filter_data",
};

export const TABS_DATA = [
  {
    label: "Setting",
    value: "setting",
  },
  {
    label: "Realisasi",
    value: "realisasi",
  },
];

export const formSchema = z.object({
  docRealization: z.string({
    message: "Nama kandang tidak boleh kosong.",
  }),
  dateRealization: z.date({
    message: "Tanggal tidak boleh kosong.",
  }),
  qtyRealization: z.number({
    message: "Populasi tidak boleh kosong.",
  }),
  bwRealization: z.number({
    message: "BW tidak boleh kosong.",
  }),
  sjNumber: z.string({
    message: "Nomor SJ tidak boleh kosong.",
  }),
  ttbNumber: z.string({
    message: "Nomor TTB tidak boleh kosong.",
  }),
});
