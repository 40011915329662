import * as React from "react";

function IcArrowDown(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={25}
      height={24}
      viewBox="0 0 25 24"
      fill="none"
      {...props}
    >
      <path
        d="M17.333 4v11m0 0l-4-4m4 4l4-4m-14-7v16m0 0l-4-4m4 4l4-4"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default IcArrowDown;
