import * as React from "react";

function IcScales(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M17.672 17.46l-1.755-9.759a1.61 1.61 0 00-1.585-1.325h-1.57V5.22h1.165a2.688 2.688 0 002.685-2.685V1.371a.537.537 0 00-.537-.538H3.272a.537.537 0 00-.537.538v1.165a2.688 2.688 0 002.684 2.685h1.165v1.155H5.015A1.61 1.61 0 003.43 7.7l-1.755 9.76a.537.537 0 00.528.632h2.374v.537a.537.537 0 101.074 0v-.537h8.044v.537a.537.537 0 001.075 0v-.537h2.374a.538.538 0 00.528-.632zM3.81 2.537v-.628h11.729v.628c0 .888-.723 1.61-1.61 1.61H5.418c-.888 0-1.61-.722-1.61-1.61zm3.85 2.685h4.03v1.155h-4.03V5.22zM2.844 17.019L4.487 7.89a.536.536 0 01.528-.441h9.317c.26 0 .482.185.528.441L16.5 17.02H2.845z"
        fill="#484B4F"
        stroke="#484B4F"
        strokeWidth={0.416667}
      />
      <path
        d="M9.672 8.478a3.763 3.763 0 00-3.758 3.758 3.763 3.763 0 003.758 3.758 3.763 3.763 0 003.759-3.758 3.763 3.763 0 00-3.759-3.758zm0 6.442a2.687 2.687 0 01-2.684-2.684 2.687 2.687 0 012.684-2.684 2.687 2.687 0 012.684 2.684 2.687 2.687 0 01-2.684 2.684z"
        fill="#484B4F"
        stroke="#484B4F"
        strokeWidth={0.416667}
      />
      <path
        d="M10.387 11.027l-1.036.779a.537.537 0 00.646.858l1.036-.779a.537.537 0 10-.646-.858z"
        fill="#484B4F"
        stroke="#484B4F"
        strokeWidth={0.416667}
      />
    </svg>
  );
}

export default IcScales;
