import { useMutation, useQueryClient } from "@tanstack/react-query";

import api from "../../../api/configApi";
import { toast } from "../../../atoms/V2/useToast";

const useMutateDocRealization = (id) => {
  const queryClient = useQueryClient();
  const mutate = useMutation({
    mutationFn: async (values) => {
      const response = await api.post(
        `/v1/docSettings/${id}/realization`,
        values,
      );
      return response;
    },
    onSuccess: () => {
      toast({
        title: "Realisasi DOC berhasil dibuat.",
        variant: "success",
      });
      queryClient.invalidateQueries({
        queryKey: ["get-setting-doc"],
      });
      queryClient.invalidateQueries({
        queryKey: ["get-detail-setting-doc", id],
      });
    },
    onError: (error) => {
      console.log(error);
      toast({
        title: "Gagal membuat realisasi DOC.",
        description: error.response.data.error,
        variant: "error",
      });
    },
  });

  return mutate;
};

export default useMutateDocRealization;
