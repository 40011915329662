import * as React from "react";

function IcEdit(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M9.5 3.768H6c-1.4 0-2.1 0-2.635.272a2.5 2.5 0 00-1.093 1.093C2 5.668 2 6.368 2 7.768v7c0 1.4 0 2.1.272 2.635a2.5 2.5 0 001.093 1.092c.535.273 1.235.273 2.635.273h7c1.4 0 2.1 0 2.635-.273a2.5 2.5 0 001.092-1.092c.273-.535.273-1.235.273-2.635v-3.5m-10 2.5h1.395c.408 0 .612 0 .804-.046.17-.041.332-.108.481-.2.169-.103.313-.247.601-.535l7.969-7.97a1.768 1.768 0 10-2.5-2.5l-7.969 7.97c-.288.288-.432.432-.535.6-.092.15-.16.312-.2.482-.046.192-.046.396-.046.803v1.396z"
        stroke="#484B4F"
        strokeWidth={1.66667}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default IcEdit;
