import { useQuery } from "@tanstack/react-query";

import api from "../../api/configApi";

const useGetMasterPeriodListOnly = () => {
  const query = useQuery({
    queryKey: ["get-master-period-list-only"],
    queryFn: async () => {
      const response = await api.get("/v1/masterPeriods/list");
      return response;
    },
    select: ({ data }) => data,
  });

  return query;
};

export default useGetMasterPeriodListOnly;
