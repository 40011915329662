/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";

import { ChevronUpDownIcon } from "@heroicons/react/24/outline";
import {
  Avatar,
  Card,
  CardBody,
  CardFooter,
  Typography,
} from "@material-tailwind/react";
import moment from "moment";
import "moment/locale/id";
import { FaCheckCircle } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";

import api from "../../api/configApi";
import { ReactComponent as BurgerDots } from "../../assets/icons/burgers-dot.svg";
import { ReactComponent as IconRightblue } from "../../assets/icons/chevron-right-blue.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/deleted.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/edit_icon.svg";
import { ReactComponent as IconSapronak } from "../../assets/icons/sapronak-icon.svg";
import AlertNotification from "../../atoms/AlertNotification";
import { Button } from "../../atoms/Button";
import ConfirmDialog from "../../atoms/ConfirmDialog";
import DrawerSapronak from "../../atoms/DrawerSapronak";
import Pagination from "../../atoms/Pagination";
import TagLabel from "../../atoms/TagLabel";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../atoms/V2/Popover";
import DefaultImagePakan from "../../img/Pakan_image.png";

const TABLE_HEAD = [
  "Produk",
  "Tanggal masuk",
  "Jumlah pakan masuk (zak)",
  "Jumlah pakan masuk (kg)",
  "Harga Satuan",
  "Total Harga",
  "",
];

const TablePakan = ({
  dataTable,
  totalPages,
  currentPage,
  setCurrentPage,
  descending,
  sortableColumns,
  handleSort,
  periodId,
}) => {
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const dataSetting = [
    { id: 1, label: "Ubah", icon: <EditIcon /> },
    { id: 2, label: "Hapus", icon: <DeleteIcon /> },
  ];
  const [showAll, setShowAll] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [getId, setGetId] = useState(null);
  const [errorNotifDelete, setErrorNotifDelete] = useState(false);
  const [errorDeleteMessage, setErrorDeleteMessage] = useState(false);
  const [responseStockFeed, setResponseStockFeed] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleChangePage = (target) => {
    setCurrentPage(target);
  };

  // const handleClickToDetail = (val) => {
  //   console.log("val", val)
  // }

  const handleShowAll = () => {
    setShowAll(!showAll);
  };

  const handleConfirmationDelete = async () => {
    try {
      await api.delete(`/v1/feeds/in/${getId}`);
      setOpenDialog(false);
      window.location.reload();
      localStorage.setItem("settingType", "sapronak");
      localStorage.setItem("settingTypeSapronak", "pakan");
      localStorage.setItem("successNotifDeleteFeed", JSON.stringify(true));
    } catch (error) {
      setOpenDialog(false);
      setErrorNotifDelete(!errorNotifDelete);
      setErrorDeleteMessage(error.response.data.error);
    }
  };

  const handleConditionalRoute = (target) => {
    if (target === 1) {
      navigate(`/pakan/update-pakan/${getId}`);
    } else if (target === 2) {
      setOpenDialog(!openDialog);
    }
  };

  useEffect(() => {
    setLoading(true);

    const fetchStock = async () => {
      try {
        const responseStock = await api.get(`/v1/feeds/stock/list`, {
          params: {
            periodId: periodId,
          },
        });
        setResponseStockFeed(responseStock?.data?.contents);
      } catch (error) {
        console.log("error", error);
      } finally {
        setLoading(false);
      }
    };

    fetchStock();
  }, [periodId]);

  useEffect(() => {
    setTimeout(() => {
      setErrorNotifDelete(false);
      setErrorDeleteMessage("");
    }, 4000);
  }, [errorNotifDelete]);

  return (
    <>
      <Card className="h-full w-full shadow-none">
        <Typography className="p-6 text-md font-semibold text-text-primary">
          Jenis pakan ({responseStockFeed?.length})
        </Typography>

        <div className="flex flex-wrap gap-4 p-[20px]">
          {loading
            ? Array(3)
                .fill(0)
                .map((_, index) => (
                  <Card
                    key={index}
                    className="h-auto w-full flex-shrink-0 rounded-[8px] border border-border-secondary shadow-none sm:w-[40%] lg:w-[26%]"
                  >
                    <CardBody className="relative overflow-hidden rounded border-border-secondary p-3">
                      <div className="flex items-start justify-between">
                        <div>
                          <div className="mb-2 h-6 w-2/3 animate-pulse rounded bg-gray-300"></div>
                          <div className="h-4 w-1/3 animate-pulse rounded bg-gray-300"></div>
                        </div>
                        <div className="h-[40px] w-[40px] animate-pulse rounded bg-gray-300 sm:h-[50px] sm:w-[50px]"></div>
                      </div>

                      <div className="mt-[10px] flex justify-between">
                        <div className="h-4 w-1/2 animate-pulse rounded bg-gray-300"></div>
                        <div className="h-4 w-1/4 animate-pulse rounded bg-gray-300"></div>
                      </div>

                      <div className="mt-[10px] flex justify-between">
                        <div className="h-4 w-1/2 animate-pulse rounded bg-gray-300"></div>
                        <div className="h-4 w-1/4 animate-pulse rounded bg-gray-300"></div>
                      </div>
                    </CardBody>
                  </Card>
                ))
            : responseStockFeed?.slice(0, 3).map((val) => (
                <Card
                  key={val.id}
                  className="h-auto w-full flex-shrink-0 rounded-[8px] border border-border-secondary shadow-none sm:w-[40%] lg:w-[26%]"
                >
                  <CardBody className="relative overflow-hidden rounded border-border-secondary p-3">
                    <div className="flex items-start justify-between">
                      <div>
                        <Typography className="text-lg font-bold leading-tight text-text-primary sm:text-xl">
                          {val.labels}
                        </Typography>
                        <div className="text-start">
                          <TagLabel
                            size="xsmall"
                            type="info"
                            children={val?.type?.labels}
                          />
                        </div>
                      </div>
                      <IconSapronak className="h-[40px] w-[40px] sm:h-[50px] sm:w-[50px]" />
                    </div>

                    <div className="mt-[10px] flex justify-between">
                      <Typography className="text-sm font-normal leading-tight text-text-quarterary sm:text-sm">
                        Stok tersedia (Sak)
                      </Typography>
                      <Typography className="text-sm font-bold leading-tight text-text-primary sm:text-sm">
                        {val.stock / 50} Sak
                      </Typography>
                    </div>

                    <div className="mt-[10px] flex justify-between">
                      <Typography className="text-sm font-normal leading-tight text-text-quarterary sm:text-sm">
                        Stok tersedia (kg)
                      </Typography>
                      <Typography className="text-sm font-bold leading-tight text-text-primary sm:text-sm">
                        {typeof val.stock === "number"
                          ? val.stock.toLocaleString("id-ID")
                          : JSON.stringify(val.stock)}{" "}
                        kg
                      </Typography>
                    </div>
                  </CardBody>
                </Card>
              ))}

          {responseStockFeed?.length > 3 && (
            <div className="mt-[50px]">
              <Button
                label="Lihat lainya"
                type="btnLinkBlue"
                onClick={handleShowAll}
                componentRight={<IconRightblue />}
              />
            </div>
          )}
        </div>

        {dataTable?.contents?.length === 0 ? (
          <div className="flex h-screen items-center justify-center">
            <div>
              <div className="flex justify-center">
                <Avatar
                  src={DefaultImagePakan}
                  alt="product image"
                  variant="rounded"
                  className="h-48 w-48"
                />
              </div>
              <Typography
                variant="h2"
                color="blue-gray"
                className="p-4 text-center text-xl font-semibold"
              >
                Data tidak ditemukan
              </Typography>

              <Typography
                variant="medium"
                color="blue-gray"
                className="bg-gray-950 flex min-h-full w-full justify-between font-normal leading-none opacity-70"
              >
                Data yang Anda cari tidak ditemukan. Coba kata kunci lain
              </Typography>
            </div>
          </div>
        ) : (
          <>
            <CardBody className="relative max-h-[960px] max-w-full overflow-scroll p-0">
              <div className="mt-[14px] flex justify-start p-[20px]">
                <Typography className="text-md font-bold leading-tight text-text-primary">
                  Riwayat penambahan
                </Typography>
              </div>

              <table className="border-spacing- mt-4 w-full min-w-max table-auto border-separate text-left">
                <thead className="border-y border-border-secondary bg-neutral-50">
                  <tr>
                    {TABLE_HEAD?.map((head, index) => (
                      <th
                        key={head}
                        className={`border-y border-border-secondary bg-neutral-25 p-lg`}
                      >
                        <div
                          className={`flex ${
                            index === 0 || index === 1
                              ? "justify-between"
                              : "justify-end"
                          } `}
                        >
                          <Typography
                            className={`flex h-[21px] items-center text-xs font-bold leading-none text-text-primary ${
                              index === 0 || index === 1
                                ? "justify-start"
                                : "justify-end"
                            }`}
                          >
                            {head}
                          </Typography>
                          {index !== 5 && TABLE_HEAD.length - 1 && (
                            <button
                              onClick={() => handleSort(sortableColumns[index])}
                              className="ml-1 focus:outline-none"
                            >
                              <ChevronUpDownIcon
                                strokeWidth={2}
                                className={`h-4 w-4 ${
                                  descending ? "rotate-180 transform" : ""
                                }`}
                              />
                            </button>
                          )}
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody>
                  {dataTable?.contents?.map((val, index) => {
                    const isLast = index === dataTable.length - 1;
                    const clases = isLast
                      ? "p-lg"
                      : `p-lg border-t border-border-secondary`;

                    return (
                      <tr key={val?.id}>
                        <td className={clases + " pl-0"}>
                          <div className="flex items-center gap-3">
                            <div className="flex justify-around gap-2">
                              <div className="pl-3xl">
                                {val?.image === undefined ? (
                                  <Avatar
                                    src={DefaultImagePakan}
                                    alt="product image"
                                    variant="rounded"
                                  />
                                ) : (
                                  val?.image
                                )}
                              </div>

                              <div className="grid grid-rows-2 items-center text-left">
                                <Typography className="text-sm font-normal leading-tight text-text-primary">
                                  {val?.feed?.labels}
                                </Typography>

                                <Typography
                                  variant="small"
                                  className="text-left text-xs text-text-quarterary"
                                >
                                  {val?.feed?.type?.labels}
                                </Typography>
                              </div>
                            </div>
                          </div>
                        </td>

                        <td className={clases}>
                          <Typography className="text-sm font-normal leading-tight text-text-primary">
                            {moment(val?.dateIn)
                              .locale("id")
                              .format("D MMMM YYYY")}
                          </Typography>
                        </td>

                        <td className={clases + " text-right"}>
                          <Typography className="text-sm font-normal leading-tight text-text-primary">
                            {/* {moment(val?.dateIn).locale('id').format("D MMMM YYYYY")} */}
                            {(val?.qtyIn / 50).toLocaleString("id-ID")} zak
                          </Typography>
                        </td>

                        <td className={clases + " text-right"}>
                          <Typography className="text-sm font-normal leading-tight text-text-primary">
                            {/* {moment(val?.dateIn).locale('id').format("D MMMM YYYYY")} */}
                            {val?.qtyIn.toLocaleString("id-ID")} kg
                          </Typography>
                        </td>

                        <td className={clases + " text-right"}>
                          <Typography className="text-sm font-normal leading-tight text-text-primary">
                            {/* {moment(val?.dateIn).locale('id').format("D MMMM YYYYY")} */}
                            Rp{val?.price.toLocaleString("id-ID")}
                          </Typography>
                        </td>

                        <td className={clases + " text-right"}>
                          <Typography className="text-sm font-normal leading-tight text-text-primary">
                            {/* {moment(val?.dateIn).locale('id').format("D MMMM YYYYY")} */}
                            Rp
                            {(
                              parseFloat(val.qtyIn) *
                              parseFloat(val.price).toFixed()
                            ).toLocaleString("id-ID")}
                          </Typography>
                        </td>

                        <td className={clases}>
                          <Popover>
                            <PopoverTrigger
                              onClick={() => {
                                setGetId(val.id);
                              }}
                            >
                              <button className="flex items-center">
                                <BurgerDots />
                              </button>
                            </PopoverTrigger>

                            <PopoverContent
                              side="left"
                              align="start"
                              className="w-[180px]"
                            >
                              {dataSetting.map((val) => (
                                <div
                                  onClick={() => handleConditionalRoute(val.id)}
                                  className="mt-2 flex cursor-pointer gap-4 hover:opacity-50"
                                >
                                  <div>{val.icon}</div>
                                  <div>{val.label}</div>
                                </div>
                              ))}
                            </PopoverContent>
                          </Popover>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </CardBody>

            <CardFooter className="items-center border-t border-border-secondary p-0">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handleChangePage}
              />
            </CardFooter>
          </>
        )}
      </Card>

      <div>
        <ConfirmDialog
          isOpen={openDialog}
          onClose={() => setOpenDialog(!openDialog)}
          title="Hapus Pakan Masuk"
          content="Anda yakin ingin melanjutkan tindakan ini?"
          btnLabel="Hapus"
          onConfirm={handleConfirmationDelete}
          btnType="btnError"
        />
      </div>

      {errorNotifDelete && (
        <AlertNotification
          icon={<FaCheckCircle />}
          color="red"
          message={errorDeleteMessage}
        />
      )}

      <div className="mt-20">
        <DrawerSapronak
          isOpen={showAll}
          onClose={() => setShowAll(!showAll)}
          response={responseStockFeed}
          className="h-[100px]"
        />
      </div>
    </>
  );
};

export default TablePakan;
