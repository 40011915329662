import * as React from "react";

import { cva } from "class-variance-authority";

import { cn } from "../../shared/utils";

const inputVariants = cva(
  "w-full border text-text-primary placeholder:text-text-placeholder focus:outline-none ",
  {
    variants: {
      size: {
        xsmall: "h-7 rounded-sm text-sm",
        small: "h-9 py-2 rounded-md text-sm",
        medium: "h-11 rounded-md",
        large: "h-14 rounded-md",
      },
      variant: {
        default:
          "border-gray-300 focus:border-primary-400 focus:ring focus:ring-primary-200",
        error: "border-red-500 focus:border-red-400",
      },
    },
    defaultVariants: {
      size: "small",
      variant: "default",
    },
  },
);

const Input = React.forwardRef(
  (
    {
      className,
      size,
      variant,
      type = "text",
      leftComponent,
      rightComponent,
      ...props
    },
    ref,
  ) => {
    return (
      <div className="relative">
        {leftComponent && (
          <div className="absolute inset-y-0 left-0 flex items-center pl-3">
            {leftComponent}
          </div>
        )}
        <input
          type={type}
          className={cn(
            inputVariants({ size, variant, className }),
            props.disabled && "!text-text-quarterary",
            leftComponent && type === "tel"
              ? "pl-[108px]"
              : leftComponent
                ? "pl-10"
                : "pl-3",
            rightComponent ? "pr-10" : "pr-3",
          )}
          ref={ref}
          {...props}
        />
        {rightComponent && (
          <div className="absolute inset-y-0 right-0 flex items-center pr-3">
            {rightComponent}
          </div>
        )}
      </div>
    );
  },
);
Input.displayName = "Input";

export { Input };
