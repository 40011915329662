import React from "react";

import { FiTrash2 } from "react-icons/fi";

import { IcFileTypePdf } from "../../../../../../../atoms/icons";
import { Button } from "../../../../../../../atoms/V2/Button";
import ImageWithModal from "../../../../../../../atoms/V2/ImageWithModal";
import { cn } from "../../../../../../../shared/utils";
import { FILE_CONTENT_TYPE } from "../../../../../../../shared/variables";

const CardFileDetail = (props) => {
  const {
    dumpsterFile,
    fileName,
    contentType,
    handleClickOuter,
    handleRemoveFile,
  } = props;

  return (
    <div
      className={cn(
        "relative flex w-1/2 flex-col justify-center overflow-hidden rounded-md border bg-secondary p-0 shadow-sm",
        FILE_CONTENT_TYPE.PDF === contentType
          ? "cursor-pointer hover:underline"
          : "",
      )}
    >
      {FILE_CONTENT_TYPE.PDF === contentType ? (
        <div className="pl-2 pt-2" onClick={handleClickOuter}>
          <IcFileTypePdf className="size-8" />
        </div>
      ) : (
        <ImageWithModal
          src={dumpsterFile}
          alt="Fetched File"
          className="aspect-video object-cover"
        />
      )}
      <div
        className="flex items-center justify-between p-md"
        onClick={handleClickOuter}
      >
        <div className="min-w-0">
          <p className="truncate text-sm">{fileName}</p>
        </div>
      </div>
      <Button
        type="button"
        size="icon"
        variant="ghost"
        className="absolute right-0 top-0 m-1 shrink-0 rounded-full hover:bg-white/50 hover:text-fg-errorPrimary aria-disabled:pointer-events-none aria-disabled:opacity-50"
        onClick={handleRemoveFile}
      >
        <FiTrash2 className="size-4" />
        <span className="sr-only">Remove file</span>
      </Button>
    </div>
  );
};

export default CardFileDetail;
