import * as React from "react";

function IcBox(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      {...props}
    >
      <path
        d="M27.334 10.667V21.6c0 2.24 0 3.36-.436 4.216a4 4 0 01-1.748 1.748c-.856.436-1.976.436-4.216.436h-9.867c-2.24 0-3.36 0-4.216-.436a4 4 0 01-1.748-1.748c-.436-.856-.436-1.976-.436-4.216V10.667M4.801 4h22.4c.746 0 1.12 0 1.405.145.25.128.455.332.583.583.145.285.145.659.145 1.405v2.4c0 .747 0 1.12-.145 1.406-.128.25-.332.454-.583.582-.285.146-.659.146-1.405.146H4.8c-.747 0-1.12 0-1.406-.146a1.333 1.333 0 01-.582-.582c-.146-.286-.146-.659-.146-1.406v-2.4c0-.746 0-1.12.146-1.405.127-.25.331-.455.582-.583C3.68 4 4.054 4 4.801 4zm8 11.333h6.4c.746 0 1.12 0 1.405.146.25.128.455.332.583.582.145.286.145.659.145 1.406v1.066c0 .747 0 1.12-.145 1.406-.128.25-.332.454-.583.582-.285.146-.659.146-1.405.146h-6.4c-.747 0-1.12 0-1.406-.146a1.333 1.333 0 01-.582-.582c-.146-.286-.146-.659-.146-1.406v-1.066c0-.747 0-1.12.146-1.406.127-.25.331-.454.582-.582.286-.146.659-.146 1.406-.146z"
        stroke="currentColor"
        strokeWidth={2.66667}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default IcBox;
