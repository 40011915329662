import { useMutation, useQueryClient } from "@tanstack/react-query";

import api from "../../api/configApi";
import { toast } from "../../atoms/V2/useToast";

const useMutateAddDailyRecord = () => {
  const queryClient = useQueryClient();
  const mutate = useMutation({
    mutationFn: async (values) => {
      const response = await api.post("/v1/dailyRecords", values);
      return response;
    },
    onSuccess: () => {
      toast({
        title: "Data harian berhasil disimpan.",
        variant: "success",
      });
      queryClient.invalidateQueries({
        queryKey: ["get-daily-record"],
      });
      queryClient.invalidateQueries({
        queryKey: ["get-next-record-period"],
      });
    },
    onError: (error) => {
      console.log(error);
      toast({
        title: "Gagal menyimpan data harian.",
        description: error.response.data.error,
        variant: "error",
      });
    },
  });

  return mutate;
};

export default useMutateAddDailyRecord;
