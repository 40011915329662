import React from "react";

import { CalendarIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { Radio, Tab, TabsHeader } from "@material-tailwind/react";
import { format } from "date-fns";
import { id } from "date-fns/locale";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { FiFilter } from "react-icons/fi";
import { Link } from "react-router-dom";

import { IcDownload, IcPlus } from "../../../../../../../atoms/icons";
import { Button } from "../../../../../../../atoms/V2/Button";
import { Calendar } from "../../../../../../../atoms/V2/DatePicker";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../../../../../atoms/V2/Dialog";
import { Input } from "../../../../../../../atoms/V2/Input";
import { Label } from "../../../../../../../atoms/V2/Label";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../../../../../atoms/V2/Popover";
import { Separator } from "../../../../../../../atoms/V2/Separator";
import { toast } from "../../../../../../../atoms/V2/useToast";
import { ROUTES_PATH } from "../../../../../../../shared/routes";
import { cn } from "../../../../../../../shared/utils";
import { DOWNLOAD_TYPE, TABS_DATA } from "../config";
import { useSettingPakan } from "../hooks";
import { CustomMultiSelect } from "./custom-multi-select";

const TabsHeaderComp = () => {
  const {
    currentTab,
    search,
    setSearch,
    value,
    setCurrentPage,
    dataTotalItem,
    docInDate,
    setDocInDate,
    initialCapacity,
    setInitialCapacity,
    finalCapacity,
    setFinalCapacity,
    isFilter,
    filteredItems,
    selectedRows,
    isDialogDownloadOpen,
    setIsDialogDownloadOpen,
    downloadType,
    setDownloadType,
    query,
    mutateDownloadExcel,
    handleResetFilter,
    handleApplyFilter,
    countFilteredItems,
    handleDownloadExcel,
    handleChangeTab,
    selectedFeedType,
    setSelectedFeedType,
  } = useSettingPakan();

  return (
    <div className="flex items-center justify-between px-xl py-xl">
      <div className="flex items-center gap-4">
        <TabsHeader
          className="h-9 rounded-sm border border-secondary bg-bg-secondary p-0"
          indicatorProps={{
            className:
              "bg-white border-[#D5D5D7] border shadow-none text-text-tertiary px-lg rounded-sm",
          }}
        >
          {TABS_DATA.map((tab) => (
            <Tab
              key={tab.value}
              value={tab.value}
              onClick={() => handleChangeTab(tab)}
              className={cn(
                "text-sm",
                currentTab === tab.value
                  ? "font-bold text-text-secondary"
                  : "font-medium text-text-tertiary",
              )}
            >
              {tab.label}
            </Tab>
          ))}
        </TabsHeader>
        <Separator orientation="vertical" className="h-9" />
        <Input
          id="search"
          type="search"
          size="small"
          value={search}
          placeholder="Pencarian"
          onChange={(e) => {
            setSearch(e.target.value);
            setCurrentPage(0);
          }}
          leftComponent={<MagnifyingGlassIcon className="h-5 w-5" />}
          className="h-9 !w-[200px] flex-shrink-0"
        />
        <Popover>
          <PopoverTrigger asChild>
            <Button
              variant="btnOutlinedGrey"
              className="w-fit flex-shrink-0 font-medium text-[#484B4F]"
            >
              <FiFilter className="size-5" />
              Filter
              {filteredItems && countFilteredItems(filteredItems) > 0 && (
                <span className="ml-[6px] flex h-[18px] w-[18px] items-center justify-center rounded-full bg-[#5A5C60] text-xs text-white">
                  {countFilteredItems(filteredItems)}
                </span>
              )}
            </Button>
          </PopoverTrigger>
          <PopoverContent align="end" className="w-[320px] px-0 py-[10px]">
            <div className="flex w-full flex-col items-center gap-3">
              <div className="flex w-full flex-col gap-2 px-4">
                <div className="flex w-full flex-col gap-2">
                  <Label>Tanggal</Label>
                  <Popover>
                    <PopoverTrigger asChild>
                      <Button
                        variant={"btnOutlinedGrey"}
                        className={cn(
                          "w-full justify-between px-[10px] text-left font-normal",
                          (!docInDate?.from || !docInDate?.to) &&
                            "text-gray-400",
                        )}
                      >
                        {docInDate?.from ? (
                          docInDate?.to ? (
                            <>
                              {format(docInDate.from, "dd/MM/yyyy", {
                                locale: id,
                              })}{" "}
                              -{" "}
                              {format(docInDate.to, "dd/MM/yyyy", {
                                locale: id,
                              })}
                            </>
                          ) : (
                            format(docInDate.from, "dd/MM/yyyy", {
                              locale: id,
                            })
                          )
                        ) : (
                          <span>Pilih range tanggal</span>
                        )}

                        <CalendarIcon className="h-4 w-4" />
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent
                      className="w-auto bg-white p-0"
                      align="start"
                    >
                      <Calendar
                        mode="range"
                        defaultMonth={docInDate?.from}
                        selected={docInDate}
                        onSelect={setDocInDate}
                        numberOfMonths={2}
                        autoFocus
                      />
                    </PopoverContent>
                  </Popover>
                </div>

                <div className="flex w-full flex-col gap-2">
                  <Label>
                    Range jumlah{" "}
                    <span className="text-xs font-normal">(Min - Maks)</span>
                  </Label>
                  <div className="flex items-center justify-between gap-2">
                    <div>
                      <Input
                        type="number"
                        placeholder="0"
                        className="w-full flex-shrink-0"
                        min={0}
                        value={initialCapacity}
                        onChange={(e) => setInitialCapacity(e.target.value)}
                        rightComponent={
                          <span className="text-sm text-text-placeholder">
                            Sak
                          </span>
                        }
                      />
                    </div>
                    <Separator className="w-6 bg-border-primary" />
                    <div>
                      <Input
                        type="number"
                        placeholder="0"
                        className="w-full flex-shrink-0"
                        min={1}
                        value={finalCapacity}
                        onChange={(e) => setFinalCapacity(e.target.value)}
                        rightComponent={
                          <span className="text-sm text-text-placeholder">
                            Sak
                          </span>
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex w-full items-center justify-between border-t-2 border-border-primary px-3 pt-[10px]">
                <Button
                  size="small"
                  variant="ghost"
                  className="w-fit"
                  onClick={handleResetFilter}
                >
                  Atur Ulang
                </Button>
                <Button
                  size="small"
                  className="w-fit"
                  onClick={handleApplyFilter}
                >
                  Terapkan
                </Button>
              </div>
            </div>
          </PopoverContent>
        </Popover>
        <CustomMultiSelect
          options={[
            { value: "PRESTARTER", label: "Prestarter" },
            { value: "STARTER", label: "Starter" },
            { value: "FINISHER", label: "Finisher" },
          ]}
          onValueChange={setSelectedFeedType}
          defaultValue={selectedFeedType}
          placeholder="Jenis pakan"
          animation={2}
          maxCount={3}
        />
      </div>
      <div className="flex items-center gap-4">
        <Dialog
          open={isDialogDownloadOpen}
          onOpenChange={(isOpen) => setIsDialogDownloadOpen(isOpen)}
        >
          <DialogTrigger asChild>
            <Button variant="btnOutlinedGrey" className="w-fit text-[#484B4F]">
              <IcDownload className="size-5" />
              Unduh
            </Button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Unduh data file ke .XLS</DialogTitle>
              <DialogDescription>
                Pilih kategori data yang ingin di unduh
              </DialogDescription>
            </DialogHeader>
            <div className="flex flex-col">
              <Radio
                name="download"
                value={downloadType === DOWNLOAD_TYPE.allData}
                onChange={() => setDownloadType(DOWNLOAD_TYPE.allData)}
                label={
                  <p>
                    Semua data di seluruh halaman{" "}
                    {dataTotalItem && `(${dataTotalItem} baris)`}
                  </p>
                }
                color="amber"
              />
              <Radio
                name="download"
                value={downloadType === DOWNLOAD_TYPE.selectedData}
                onChange={() => setDownloadType(DOWNLOAD_TYPE.selectedData)}
                disabled={selectedRows.length === 0}
                label={
                  <p>
                    Hanya data yang telah dipilih{" "}
                    {selectedRows.length > 0
                      ? `(${selectedRows.length} baris)`
                      : "(0)"}
                  </p>
                }
                color="amber"
              />
              <Radio
                name="download"
                value={downloadType === DOWNLOAD_TYPE.filterData}
                onChange={() => setDownloadType(DOWNLOAD_TYPE.filterData)}
                disabled={!isFilter && !value}
                label={
                  <p>
                    Semua data yang telah di filter{" "}
                    {isFilter || value
                      ? query.data?.totalItems &&
                        `(${query.data?.totalItems} baris)`
                      : "(0)"}
                  </p>
                }
                color="amber"
              />
            </div>
            <DialogFooter>
              <div className="flex items-center justify-end gap-lg">
                <DialogClose asChild>
                  <Button
                    variant="btnSecondary"
                    className="w-fit"
                    disabled={mutateDownloadExcel.isPending}
                  >
                    Kembali
                  </Button>
                </DialogClose>
                <Button
                  onClick={() => {
                    handleDownloadExcel();
                    toast({
                      title: "Sedang mengunduh data",
                      variant: "loading",
                    });
                  }}
                  className="w-fit"
                  disabled={mutateDownloadExcel.isPending}
                >
                  Konfirmasi
                  {mutateDownloadExcel.isPending && (
                    <AiOutlineLoading3Quarters className="size-4 animate-spin text-button-primaryFg" />
                  )}
                </Button>
              </div>
            </DialogFooter>
          </DialogContent>
        </Dialog>

        {currentTab === "setting" && (
          <Button className="w-fit" asChild>
            <Link to={ROUTES_PATH.manajemenSapronakSettingPakan}>
              <IcPlus className="size-5" />
              Buat Setting
            </Link>
          </Button>
        )}
      </div>
    </div>
  );
};

export default TabsHeaderComp;
