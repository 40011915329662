import * as React from "react";

function IcFileTypePdf(props) {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.2 3.2A2.6 2.6 0 018.8.6h12.8c.097 0 .19.039.258.107l8.634 8.634a.366.366 0 01.108.259v19.2a2.6 2.6 0 01-2.6 2.6H8.8a2.6 2.6 0 01-2.6-2.6V3.2z"
        fill="#fff"
        stroke="#D5D7DA"
        strokeWidth={1.2}
      />
      <path
        d="M21.6.4v6a3.2 3.2 0 003.2 3.2h6"
        stroke="#D5D7DA"
        strokeWidth={1.2}
      />
      <rect
        x={0.799805}
        y={14.2002}
        width={20.8}
        height={13.2}
        rx={1.6}
        fill="#D92D20"
      />
      <path
        d="M3.866 23.8v-5.818h2.295c.442 0 .818.084 1.128.253.31.166.548.398.71.695.165.296.248.637.248 1.023 0 .387-.084.727-.25 1.023a1.724 1.724 0 01-.725.69c-.314.165-.695.247-1.142.247H4.667v-.985h1.264c.237 0 .432-.041.586-.123a.81.81 0 00.346-.343c.078-.148.117-.317.117-.509a1.05 1.05 0 00-.117-.505.778.778 0 00-.346-.338 1.265 1.265 0 00-.591-.123h-.83V23.8h-1.23zm7.242 0H9.046v-5.818h2.08c.585 0 1.088.116 1.51.35.423.23.748.563.975.996.23.434.344.953.344 1.557 0 .606-.115 1.127-.344 1.563-.227.435-.554.77-.98 1.002-.424.233-.932.35-1.523.35zm-.832-1.054h.781c.364 0 .67-.065.918-.193.25-.131.437-.333.562-.605.127-.275.19-.63.19-1.063 0-.43-.063-.781-.19-1.054a1.232 1.232 0 00-.56-.602c-.248-.129-.553-.193-.917-.193h-.784v3.71zm4.59 1.054v-5.818h3.852v1.014h-2.622v1.386h2.367v1.014h-2.367V23.8h-1.23z"
        fill="#fff"
      />
    </svg>
  );
}

export default IcFileTypePdf;
