import React from "react";

import { TabPanel, Tabs, TabsBody } from "@material-tailwind/react";

import LoadingAnimation from "../../../../../../atoms/LoadingAnimation";
import Table from "../../../../../../atoms/Table/Table";
import NotFound from "../../../../../../atoms/V2/NotFound";
import Pagination from "../../../../../../atoms/V2/Pagination";
import { SheetDetail, SheetDetailSetting, SheetFormRealisasi, TabsHeaderComp } from "./components";
import { useSettingDOC } from "./hooks";

const SapronakDOCFeature = () => {
  const {
    currentTab,
    currentPage,
    setCurrentPage,
    itemPerPage,
    setItemPerPage,
    query,
    data,
    columns,
    columnRealisasi,
  } = useSettingDOC();

  return (
    <section>
      <SheetFormRealisasi />
      <SheetDetail />
      <SheetDetailSetting />

      <Tabs value={currentTab}>
        <TabsHeaderComp />
        <TabsBody>
          <TabPanel value="setting" className="px-0 pt-0">
            {currentTab === "setting" &&
              (query.isLoading ? (
                <div className="flex h-[75dvh] w-full items-center justify-center">
                  <LoadingAnimation />
                </div>
              ) : query.data?.contents?.length === 0 || query.isError ? (
                <div className="flex h-[75dvh] w-full flex-col items-center justify-center">
                  <NotFound />
                </div>
              ) : (
                <>
                  <Table
                    data={data || []}
                    columns={columns}
                    hideFooter
                    isRoundedBottom={false}
                    className="max-h-[65vh] lg:max-h-[60vh] xl:max-h-[68vh] 2xl:max-h-[70vh]"
                  />
                  <Pagination
                    currentPage={currentPage + 1}
                    totalPages={query.data?.totalPages}
                    onPageChange={(page) => setCurrentPage(page - 1)}
                    itemPerPage={itemPerPage}
                    setItemPerPage={setItemPerPage}
                  />
                </>
              ))}
          </TabPanel>
          <TabPanel value="realisasi" className="px-0 pt-0">
            {currentTab === "realisasi" &&
              (query.isLoading ? (
                <div className="flex h-[75dvh] w-full items-center justify-center">
                  <LoadingAnimation />
                </div>
              ) : query.data?.contents?.length === 0 || query.isError ? (
                <div className="flex h-[75dvh] w-full flex-col items-center justify-center">
                  <NotFound />
                </div>
              ) : (
                <>
                  <Table
                    data={data || []}
                    columns={columnRealisasi}
                    hideFooter
                    isRoundedBottom={false}
                    className="max-h-[65vh] lg:max-h-[60vh] xl:max-h-[68vh] 2xl:max-h-[70vh]"
                  />
                  <Pagination
                    currentPage={currentPage + 1}
                    totalPages={query.data?.totalPages}
                    onPageChange={(page) => setCurrentPage(page - 1)}
                    itemPerPage={itemPerPage}
                    setItemPerPage={setItemPerPage}
                  />
                </>
              ))}
          </TabPanel>
        </TabsBody>
      </Tabs>
    </section>
  );
};

export default SapronakDOCFeature;
