import React from "react";

import { cn } from "../../../../../shared/utils";
import {
  WEEK
} from "../../../../../shared/variables";

const BadgeWeek = ({ type }) => {
  const badgeColor = {
    1: "text-text-errorPrimary border-error-200 bg-error-25",
    2: "text-text-warningPrimary border-warning-200 bg-warning-25",
    3: "text-text-successSecondary border-success-200 bg-success-25",
    4: "text-text-infoSecondary border-info-200 bg-info-25",
    5: "text-neutral-700 border-neutral-200 bg-neutral-50",
  };

  return (
    <span
      className={cn(
        "rounded-sm border px-[6px] text-xs font-medium py-xs",
        badgeColor[type],
      )}
    >
      {WEEK.find((item) => item.value === type)?.label}
    </span>
  );
};

export default BadgeWeek;
