import React from "react";

import ReacordingHarianFeature from "./features";
import { RecordingHarianStore } from "./hook/index";

const RecordingHarian = () => {
  return (
    <RecordingHarianStore>
      <ReacordingHarianFeature />
    </RecordingHarianStore>
  );
};

export default RecordingHarian;
