import * as React from "react";

function IcTrash(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M12.833 5.333v-.666c0-.934 0-1.4-.181-1.757a1.667 1.667 0 00-.729-.728C11.567 2 11.1 2 10.167 2H8.833c-.933 0-1.4 0-1.756.182-.314.16-.569.414-.729.728-.181.357-.181.823-.181 1.757v.666m1.666 4.584v4.166m3.334-4.166v4.166M2 5.333h15m-1.667 0v9.334c0 1.4 0 2.1-.272 2.635a2.5 2.5 0 01-1.093 1.092c-.534.273-1.235.273-2.635.273H7.667c-1.4 0-2.1 0-2.635-.273a2.5 2.5 0 01-1.093-1.092c-.272-.535-.272-1.235-.272-2.635V5.333"
        stroke="currentColor"
        strokeWidth={1.66667}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default IcTrash;
