import React, { useEffect, useRef, useState } from "react";

import { CalendarIcon } from "@heroicons/react/24/outline";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "@material-tailwind/react";
import { format } from "date-fns";
import { id } from "date-fns/locale";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { HiArrowLeft } from "react-icons/hi";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import {
  IcBox,
  IcChevronDown,
  IcPlus,
  IcTrash,
} from "../../../../../../atoms/icons";
import { Button } from "../../../../../../atoms/V2/Button";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "../../../../../../atoms/V2/Collabsible";
import { Calendar } from "../../../../../../atoms/V2/DatePicker";
import { DropdownSingleWithSearch } from "../../../../../../atoms/V2/DropdownSingleWithSearch";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../../../../atoms/V2/Form";
import { Input } from "../../../../../../atoms/V2/Input";
import { InputNumber } from "../../../../../../atoms/V2/InputNumber";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../../../../atoms/V2/Popover";
import { ScrollArea } from "../../../../../../atoms/V2/ScrollArea";
import { ROUTES_PATH } from "../../../../../../shared/routes";
import { cn, formatNumber } from "../../../../../../shared/utils";
import { useGetFeedsList } from "../../../../../../useCases/Feed";
import { useGetMasterPeriodListOnly } from "../../../../../../useCases/MasterPeriod";
import { useGetFeedsStock } from "../../../../../../useCases/Sapronak";
import { useMutateSettingFeedBulk } from "../../../../../../useCases/Settings/Feed";
import { BadgeStatus } from "../../section/Pakan/components";
import { formSchema } from "./config";

const SapronakSettingPakan = () => {
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.auth);
  const [totalCountQTY, setTotalCountQTY] = useState(0);
  const [selectedData, setSelectedData] = useState([]);
  const [mappingData, setMappingData] = useState([]);
  const [openStates, setOpenStates] = useState(mappingData.map(() => false));

  const queryHouses = useGetMasterPeriodListOnly({
    unit: user?.unit,
  });
  const queryPakan = useGetFeedsList();
  const querySapronak = useGetFeedsStock({
    periodId: selectedData?.map((item) => item.id)?.join(","),
  });
  const mutateAdd = useMutateSettingFeedBulk();

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      dateSetting: new Date(),
      houses: [
        {
          rearingPeriod: "",
          feedSetting: "",
          qtySetting: "",
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "houses",
  });

  const scrollAreaRef = useRef(null); // Create ref for ScrollArea
  const previousFieldsLength = useRef(fields.length); // Track previous fields length
  const lastItemRef = useRef(null);
  const houses = useWatch({ control: form.control, name: "houses" });

  useEffect(() => {
    const total = houses.reduce(
      (sum, item) => sum + (Number(item.qtySetting) || 0),
      0,
    );
    setTotalCountQTY(total);
  }, [houses]); // `houses` will trigger updates automatically

  useEffect(() => {
    if (fields.length > previousFieldsLength.current) {
      setTimeout(() => {
        lastItemRef.current?.scrollIntoView({ behavior: "smooth" });
      }, 100);
    }
    previousFieldsLength.current = fields.length;
  }, [fields.length]);

  useEffect(() => {
    if (querySapronak.data) {
      const dataStock = querySapronak.data?.contents?.map((item) => ({
        id: item.id,
        labels: item.labels,
        price: item.price,
        idPeriod: item.rearingPeriod?.id,
        stock: item.stock,
        suplier: item.supplier,
        type: item.type?.id,
      }));

      const dataSelected = selectedData.map((item) => {
        const stocks = dataStock
          .filter((stock) => stock.idPeriod === item.id)
          .map((stock) => ({
            idStock: stock?.id || "-",
            stokLabel: stock?.labels || "-",
            stokPrice: stock?.price || "-",
            stokStock: stock?.stock || "-",
            stokSuplier: stock?.suplier || "-",
            stokType: stock?.type || "-",
          }));

        return {
          id: item.id,
          name: item.name,
          period: item.period,
          stock: stocks.length
            ? stocks
            : [
                {
                  idStock: "-",
                  stokLabel: "-",
                  stokPrice: "-",
                  stokStock: "-",
                  stokSuplier: "-",
                  stokType: "-",
                },
              ],
        };
      });

      setMappingData(dataSelected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [querySapronak.data]);

  const handleOnSubmit = (values) => {
    const payload = {
      dateSetting: format(values.dateSetting, "yyyy-MM-dd"),
      feedSettingList: values.houses.map((house) => ({
        rearingPeriod: { id: house.rearingPeriod },
        feedSetting: { id: house.feedSetting },
        qtySetting: house.qtySetting,
      })),
    };

    mutateAdd.mutate(payload, {
      onSuccess: () => {
        navigate(`${ROUTES_PATH.manajemenSapronak}?tab=pakan`);
      },
    });
  };

  const toggleCollapsible = (index) => {
    setOpenStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = !newStates[index];
      return newStates;
    });
  };

  return (
    <div className="grid grid-cols-12 gap-2xl p-4 pb-0">
      <Card className="col-span-8 w-full border p-0 shadow-none">
        <CardHeader
          floated={false}
          shadow={false}
          className="m-0 flex items-center justify-between gap-xs px-3xl pt-xl"
        >
          <div className="flex items-center gap-xs">
            <Link to={`${ROUTES_PATH.manajemenSapronak}?tab=pakan`}>
              <HiArrowLeft className="size-6 text-text-primary" />
            </Link>
            <h2 className="text-lg font-bold text-text-primary">
              Setting Pakan
            </h2>
          </div>
          <div className="flex items-center justify-end gap-md rounded-md bg-[#F8F8F8] px-2">
            <p className="py-[6px] text-sm text-text-secondary">
              Total pakan setting
            </p>
            <p className="font-bold text-[#2D61D6]">
              {formatNumber(totalCountQTY)} sak
            </p>
          </div>
        </CardHeader>
        <CardBody className="space-y-xl px-3xl pt-xl">
          <Form {...form}>
            <form className="space-y-lg">
              <div className="space-y-md">
                <FormField
                  control={form.control}
                  name="dateSetting"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel isRequire>Tanggal</FormLabel>
                      <FormControl>
                        <Popover>
                          <PopoverTrigger asChild>
                            <Button
                              variant={
                                form.formState.errors.dateSetting
                                  ? "btnErrorOutlined"
                                  : "btnOutlinedGrey"
                              }
                              className={cn(
                                "w-full justify-between pr-3 text-left font-normal",
                                !field.value && "text-gray-400",
                              )}
                            >
                              {field.value ? (
                                format(field.value, "PPP", { locale: id })
                              ) : (
                                <span>Pilih Tanggal</span>
                              )}
                              <CalendarIcon className="h-4 w-4" />
                            </Button>
                          </PopoverTrigger>
                          <PopoverContent
                            className="w-auto bg-white p-0"
                            align="end"
                          >
                            <Calendar
                              mode="single"
                              selected={field.value}
                              onSelect={field.onChange}
                              autoFocus
                            />
                          </PopoverContent>
                        </Popover>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <ScrollArea
                ref={scrollAreaRef}
                className="h-[59dvh] overflow-y-auto"
              >
                <div className="flex flex-col gap-xl">
                  {fields.map((field, index) => (
                    <div
                      key={field.id}
                      ref={index === fields.length - 1 ? lastItemRef : null}
                      className="rounded-md border"
                    >
                      <div className="flex items-center justify-between rounded-t-md bg-bg-secondary px-xl py-lg">
                        <p className="text-sm font-bold text-text-secondary">
                          Kandang {index + 1}
                        </p>
                        <button
                          type="button"
                          onClick={() => {
                            fields.length > 1 && remove(index);
                            setSelectedData((prev) => {
                              const newData = [...prev];
                              newData.splice(index, 1);
                              return newData;
                            });
                          }}
                          className="hover:text-red-500"
                        >
                          <IcTrash className="text-fg-disabled hover:text-fg-errorPrimary" />
                        </button>
                      </div>
                      <div className="grid grid-cols-2 gap-x-6 gap-y-3xl p-xl">
                        <FormField
                          control={form.control}
                          name={`houses.${index}.rearingPeriod`}
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel isRequire>Kandang</FormLabel>
                              <FormControl>
                                <DropdownSingleWithSearch
                                  id={field.name}
                                  options={queryHouses.data?.contents?.map(
                                    (house) => ({
                                      id: house.id,
                                      name: house.house?.labels,
                                      labels: house.house?.labels,
                                      code: house.analyticAccount,
                                    }),
                                  )}
                                  disabled={queryHouses.isLoading}
                                  isError={
                                    form.formState.errors.houses?.[index]
                                      ?.rearingPeriod
                                  }
                                  placeholder="Pilih Kandang"
                                  placeholderSearch="Cari nama kandang"
                                  selectedOption={queryHouses.data?.contents
                                    ?.map((house) => ({
                                      id: house.id,
                                      name: house.house?.labels,
                                      labels: house.house?.labels,
                                      code: house.analyticAccount,
                                    }))
                                    .find((item) => item.id === field.value)}
                                  onSelect={(value) => {
                                    field.onChange(value.id || null);
                                    form.setValue(
                                      `houses.${index}.idPeriod`,
                                      value?.code || "",
                                    );
                                    // each form has its own selected data
                                    setSelectedData((prev) => {
                                      const newData = [...prev];
                                      newData[index] = {
                                        id: value?.id || "-",
                                        name: value?.name || "-",
                                        period: value?.code || "-",
                                      };
                                      return newData;
                                    });
                                  }}
                                  content={(option) => (
                                    <div className="flex max-w-80 flex-col py-[10px]">
                                      <p className="text-base text-text-secondary">
                                        {option?.name}
                                      </p>
                                      <p className="text-xs text-text-quarterary">
                                        {option?.code || "-"}
                                      </p>
                                    </div>
                                  )}
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                        <FormField
                          control={form.control}
                          name={`houses.${index}.idPeriod`}
                          render={({ field }) => {
                            return (
                              <FormItem>
                                <FormLabel>ID Periode</FormLabel>
                                <FormControl>
                                  <Input
                                    {...field}
                                    id={field.name}
                                    disabled
                                    placeholder="ID Periode"
                                  />
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            );
                          }}
                        />

                        <FormField
                          control={form.control}
                          name={`houses.${index}.feedSetting`}
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel isRequire>Pakan</FormLabel>
                              <FormControl>
                                <DropdownSingleWithSearch
                                  id={field.name}
                                  options={queryPakan.data?.contents?.map(
                                    (item) => ({
                                      id: item.id,
                                      labels: item.name,
                                      suplier: item.supplier,
                                      type: item.type?.id,
                                    }),
                                  )}
                                  disabled={queryPakan.isLoading}
                                  isError={
                                    form.formState.errors.houses?.[index]
                                      ?.feedSetting
                                  }
                                  placeholder="Pilih pakan"
                                  placeholderSearch="Cari nama pakan"
                                  onSelect={(value) =>
                                    field.onChange(value?.id || "")
                                  }
                                  selectedOption={queryPakan.data?.contents
                                    ?.map((item) => ({
                                      id: item.id,
                                      labels: item.name,
                                      suplier: item.supplier,
                                      type: item.type?.id,
                                    }))
                                    .find((item) => item.id === field.value)}
                                  content={(option) => (
                                    <div className="flex w-full items-center justify-between gap-2">
                                      <div className="flex flex-col py-[10px]">
                                        <p className="text-base text-text-secondary">
                                          {option?.labels || "-"}
                                        </p>
                                        <p className="text-xs text-text-quarterary">
                                          {option?.suplier || "-"}
                                        </p>
                                      </div>
                                      <div className="flex w-fit flex-shrink-0 justify-end">
                                        <BadgeStatus type={option?.type} />
                                      </div>
                                    </div>
                                  )}
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                        <FormField
                          control={form.control}
                          name={`houses.${index}.qtySetting`}
                          render={({ field }) => (
                            <FormItem>
                              <FormLabel isRequire>Jumlah</FormLabel>
                              <FormControl>
                                <InputNumber
                                  id={field.name}
                                  value={field.value || ""}
                                  onValueChange={(values) => {
                                    field.onChange(
                                      values.value ? Number(values.value) : "",
                                    );
                                  }}
                                  isError={
                                    form.formState.errors.houses?.[index]
                                      ?.qtySetting
                                  }
                                  placeholder="Masukkan kuantitas"
                                  rightComponent={
                                    <span className="text-sm text-text-placeholder">
                                      Sak
                                    </span>
                                  }
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </ScrollArea>
            </form>
          </Form>
        </CardBody>
        <CardFooter className="flex w-full justify-between border-t">
          <Button
            type="button"
            variant="btnOutlinedGrey"
            className="w-fit"
            onClick={() =>
              append({
                rearingPeriod: "",
                feedSetting: "",
                qtySetting: "",
              })
            }
            disabled={mutateAdd.isPending}
          >
            <IcPlus className="size-5" />
            Tambah Kandang
          </Button>
          <Button
            onClick={form.handleSubmit(handleOnSubmit)}
            disabled={mutateAdd.isPending}
            className="w-fit"
          >
            Simpan
            {mutateAdd.isPending && (
              <AiOutlineLoading3Quarters className="size-4 animate-spin text-button-primaryFg" />
            )}
          </Button>
        </CardFooter>
      </Card>

      {/* Right side card remains the same */}
      <Card className="col-span-4 flex-shrink-0 border p-0 shadow-none">
        <CardHeader
          floated={false}
          shadow={false}
          className="m-0 space-y-1 px-3xl pt-3xl"
        >
          <h2 className="text-xl font-semibold">Stok pakan</h2>
          <p className="text-xs text-text-tertiary">
            Stok pakan tersedia di kandang
          </p>
        </CardHeader>
        <CardBody className="space-y-xl px-3xl pt-xl">
          <ScrollArea className="h-[70dvh]">
            <div className="flex flex-col gap-3">
              {mappingData?.length > 0 ? (
                mappingData?.map(
                  (item, index) => (
                    // item.stock.find((stock) => stock.stokStock !== "-") && (
                    <Collapsible
                      key={`item-${index}`}
                      open={openStates[index]}
                      onOpenChange={() => toggleCollapsible(index)}
                      className="space-y-2"
                    >
                      <CollapsibleTrigger className="flex w-full items-center justify-between gap-[10px] transition-colors hover:bg-bg-secondary/50">
                        <div className="flex flex-col items-start text-start">
                          <p className="font-normal text-text-secondary">
                            {item.name}
                          </p>
                          <p className="text-sm text-text-quarterary">
                            {item.period}
                          </p>
                        </div>
                        <IcChevronDown
                          className={cn(
                            "size-6 flex-shrink-0 text-text-primary",
                            openStates[index] ? "rotate-0" : "rotate-180",
                          )}
                        />
                      </CollapsibleTrigger>
                      <CollapsibleContent className="rounded-md border border-border-secondary">
                        <table className="w-full table-auto border-collapse overflow-hidden rounded-md border border-border-secondary text-sm text-text-primary">
                          <thead>
                            <tr>
                              <th className="border border-border-secondary bg-bg-secondary px-lg py-1 text-left">
                                Produk
                              </th>
                              <th className="border border-border-secondary bg-bg-secondary px-lg py-1 text-left font-bold">
                                Jenis pakan
                              </th>
                              <th className="border border-border-secondary bg-bg-secondary px-lg py-1 text-left font-bold">
                                Kuantitas
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {item?.stock?.map((stock, index) => (
                              <tr key={`stock-${index}`}>
                                <td className="max-w-[120px] border border-border-secondary px-lg py-[3px]">
                                  <p className="text-sm font-normal text-text-primary">
                                    {stock.stokLabel || "-"}
                                  </p>
                                  <p className="text-xs text-text-quarterary">
                                    {stock.stokSuplier || "-"}
                                  </p>
                                </td>
                                <td className="border border-border-secondary px-lg py-[3px]">
                                  {stock.stokType !== "-" ? (
                                    <BadgeStatus type={stock.stokType} />
                                  ) : (
                                    "-"
                                  )}
                                </td>
                                <td className="border border-border-secondary px-lg py-[3px]">
                                  {stock.stokStock !== "-"
                                    ? formatNumber(stock.stokStock / 50)
                                    : "-"}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </CollapsibleContent>
                    </Collapsible>
                  ),
                  // ),
                )
              ) : (
                <div className="flex flex-col items-center justify-center gap-4 py-5xl">
                  <IcBox className="text-fg-disabled" />
                  <p className="text-text-tertiary">Tidak ada stok pakan</p>
                </div>
              )}
            </div>
          </ScrollArea>
        </CardBody>
      </Card>
    </div>
  );
};

export default SapronakSettingPakan;
