import { z } from "zod";

export const productSchema = z.object({
  productId: z.string().min(1, "Produk harus diisi"),
  type: z.enum(["pakan", "ovk"]),
  qty: z.coerce.number().min(0, {
    message: "Jumlah produk tidak boleh minus",
  }),
});

export const formSchema = z.object({
  mortality: z.coerce
    .number()
    .min(0, {
      message: "Kematian tidak boleh kosong",
    })
    .optional(),
  culling: z.coerce.number().min(0, {
    message: "Pemisahan tidak boleh kosong",
  }),
  samplingWeight: z.coerce
    .number()
    .min(0, {
      message: "Bobot timbang tidak boleh kosong",
    })
    .optional(),
  samplingPopulation: z.coerce
    .number()
    .min(0, {
      message: "Jumlah sampling tidak boleh kosong",
    })
    .optional(),
  products: z
    .array(productSchema)
    .refine(
      (products) => products.some((product) => product.type === "pakan"),
      {
        message: "Setidaknya satu produk harus bertipe pakan",
      },
    ),
});

export const isSummarizedRow = [
  "Minggu 1",
  "Minggu 2",
  "Minggu 3",
  "Minggu 4",
  "Minggu 5",
  "Cumulative",
];

export const data = [
  {
    id: "2b99d7cf-2126-4a64-b140-0c72d475f600",
    createdAt: "2025-02-05T10:20:55.061531",
    createdBy: "2cebe2f3-d543-478d-8ddd-556be08f7a9b",
    updatedAt: "2025-02-06T13:39:04.380491",
    updatedBy: "2cebe2f3-d543-478d-8ddd-556be08f7a9b",
    deletedAt: null,
    deletedBy: null,
    day: 1,
    samplingWeight: 500,
    samplingPopulation: 10,
    mortality: 10,
    culling: 0,
    dailyPopulation: 5000,
    dailyFeedUsed: 250,
    recordDate: "2025-02-01",
    rearingPeriod: {
      id: "6d588544-e163-4f6b-8583-aec9cc86523b",
    },
    usedFeeds: [
      {
        supplier: "PT. Wonokoyo Jaya Corporindo",
        usedFeed: {
          qtyUsed: 250,
          id: "0b3d5f82-7a66-4812-ae3d-3114d72f234b",
        },
        id: "b2a86be6-1962-4f74-b599-33c586e43184",
        type: {
          id: "PRESTARTER",
          labels: "Prestarter",
        },
        stock: 0,
        labels: "BR1 SS",
      },
    ],
    usedOvks: [],
    standardWeight: 56,
    adg: 0,
    standardFcr: 0.25,
    actualFcr: 1,
    feedIntake: 50,
    standardFi: 14,
    standardDepletion: 0.14,
    cumulativeDepletion: 0.2,
    totalFeedUsed: 250,
    harvestCount: 0,
    harvestCountCum: 0,
    harvestTonnage: 0,
    harvestTonnageCum: 0,
    harvestAge: 0,
    estimatedIp: 499,
    notes: "hari 1",
    averageWeight: 50,
    diffFcr: 0.75,
    standardFeed: 1.4,
    totalDepletion: 10,
    documentation: null,
  },
  {
    id: "ceeb1973-cab5-4481-a067-33ac2dae7165",
    createdAt: "2025-02-05T14:13:17.361366",
    createdBy: "2cebe2f3-d543-478d-8ddd-556be08f7a9b",
    updatedAt: "2025-02-06T13:39:04.383418",
    updatedBy: "2cebe2f3-d543-478d-8ddd-556be08f7a9b",
    deletedAt: null,
    deletedBy: null,
    day: 2,
    samplingWeight: 0,
    samplingPopulation: 0,
    mortality: 0,
    culling: 0,
    dailyPopulation: 4990,
    dailyFeedUsed: 100,
    recordDate: "2025-02-02",
    rearingPeriod: {
      id: "6d588544-e163-4f6b-8583-aec9cc86523b",
    },
    usedFeeds: [
      {
        supplier: "PT. Wonokoyo Jaya Corporindo",
        usedFeed: {
          qtyUsed: 100,
          id: "7115de50-799e-4308-b8ba-5035a9b59f2e",
        },
        id: "b2a86be6-1962-4f74-b599-33c586e43184",
        type: {
          id: "PRESTARTER",
          labels: "Prestarter",
        },
        stock: 0,
        labels: "BR1 SS",
      },
    ],
    usedOvks: [],
    standardWeight: 71,
    adg: 0,
    standardFcr: 0.438,
    actualFcr: 0,
    feedIntake: 70,
    standardFi: 31,
    standardDepletion: 0.14,
    cumulativeDepletion: 0.2,
    totalFeedUsed: 350,
    harvestCount: 0,
    harvestCountCum: 0,
    harvestTonnage: 0,
    harvestTonnageCum: 0,
    harvestAge: 0,
    estimatedIp: 0,
    notes: "",
    averageWeight: 0,
    diffFcr: 0,
    standardFeed: 3.09,
    totalDepletion: 0,
    documentation: null,
  },
  {
    id: "2f2a5a4b-82b8-46a5-b910-f359bcea861a",
    createdAt: "2025-02-06T13:39:04.394204",
    createdBy: "2cebe2f3-d543-478d-8ddd-556be08f7a9b",
    updatedAt: "2025-02-06T13:39:19.030329",
    updatedBy: "2cebe2f3-d543-478d-8ddd-556be08f7a9b",
    deletedAt: null,
    deletedBy: null,
    day: 3,
    samplingWeight: 0,
    samplingPopulation: 0,
    mortality: 0,
    culling: 0,
    dailyPopulation: 4990,
    dailyFeedUsed: 150,
    recordDate: "2025-02-03",
    rearingPeriod: {
      id: "6d588544-e163-4f6b-8583-aec9cc86523b",
    },
    usedFeeds: [
      {
        supplier: "PT Farmsco Feed Indonesia",
        usedFeed: {
          qtyUsed: 150,
          id: "c62f0082-7205-473e-8508-0e9d85d2761d",
        },
        id: "e761c8e8-cdcf-40c5-bb33-557e43552b88",
        type: {
          id: "FINISHER",
          labels: "Finisher",
        },
        stock: 0,
        labels: "Bro AB",
      },
    ],
    usedOvks: [],
    standardWeight: 88,
    adg: 0,
    standardFcr: 0.582,
    actualFcr: 0,
    feedIntake: 100,
    standardFi: 51,
    standardDepletion: 0.14,
    cumulativeDepletion: 0.2,
    totalFeedUsed: 500,
    harvestCount: 0,
    harvestCountCum: 0,
    harvestTonnage: 0,
    harvestTonnageCum: 0,
    harvestAge: 0,
    estimatedIp: 0,
    notes: "",
    averageWeight: 0,
    diffFcr: 0,
    standardFeed: 5.09,
    totalDepletion: 0,
    documentation: null,
  },
  {
    id: "450989a3-1946-4f6c-8c08-56ca34b301d5",
    createdAt: "2025-02-06T13:39:19.035669",
    createdBy: "2cebe2f3-d543-478d-8ddd-556be08f7a9b",
    updatedAt: "2025-02-06T13:39:42.583119",
    updatedBy: "2cebe2f3-d543-478d-8ddd-556be08f7a9b",
    deletedAt: null,
    deletedBy: null,
    day: 4,
    samplingWeight: 0,
    samplingPopulation: 0,
    mortality: 0,
    culling: 0,
    dailyPopulation: 4990,
    dailyFeedUsed: 600,
    recordDate: "2025-02-04",
    rearingPeriod: {
      id: "6d588544-e163-4f6b-8583-aec9cc86523b",
    },
    usedFeeds: [
      {
        supplier: "PT. Wonokoyo Jaya Corporindo",
        usedFeed: {
          qtyUsed: 600,
          id: "b1cfd36b-3271-455d-b1f3-ccc0e0448805",
        },
        id: "b2a86be6-1962-4f74-b599-33c586e43184",
        type: {
          id: "PRESTARTER",
          labels: "Prestarter",
        },
        stock: 0,
        labels: "BR1 SS",
      },
    ],
    usedOvks: [],
    standardWeight: 108,
    adg: 0,
    standardFcr: 0.689,
    actualFcr: 0,
    feedIntake: 220,
    standardFi: 75,
    standardDepletion: 0.14,
    cumulativeDepletion: 0.2,
    totalFeedUsed: 1100,
    harvestCount: 0,
    harvestCountCum: 0,
    harvestTonnage: 0,
    harvestTonnageCum: 0,
    harvestAge: 0,
    estimatedIp: 0,
    notes: "",
    averageWeight: 0,
    diffFcr: 0,
    standardFeed: 7.49,
    totalDepletion: 0,
    documentation: null,
  },
  {
    id: "22e80332-2138-44f9-a548-a5c808770850",
    createdAt: "2025-02-06T13:39:42.589778",
    createdBy: "2cebe2f3-d543-478d-8ddd-556be08f7a9b",
    updatedAt: "2025-02-06T13:40:15.208919",
    updatedBy: "2cebe2f3-d543-478d-8ddd-556be08f7a9b",
    deletedAt: null,
    deletedBy: null,
    day: 5,
    samplingWeight: 0,
    samplingPopulation: 0,
    mortality: 0,
    culling: 0,
    dailyPopulation: 4990,
    dailyFeedUsed: 100,
    recordDate: "2025-02-05",
    rearingPeriod: {
      id: "6d588544-e163-4f6b-8583-aec9cc86523b",
    },
    usedFeeds: [
      {
        supplier: "PT. Wonokoyo Jaya Corporindo",
        usedFeed: {
          qtyUsed: 100,
          id: "8c33c000-1572-40bd-b8d1-9eae3cb648ab",
        },
        id: "b2a86be6-1962-4f74-b599-33c586e43184",
        type: {
          id: "PRESTARTER",
          labels: "Prestarter",
        },
        stock: 0,
        labels: "BR1 SS",
      },
    ],
    usedOvks: [],
    standardWeight: 130,
    adg: 0,
    standardFcr: 0.782,
    actualFcr: 0,
    feedIntake: 240,
    standardFi: 101,
    standardDepletion: 0.14,
    cumulativeDepletion: 0.2,
    totalFeedUsed: 1200,
    harvestCount: 0,
    harvestCountCum: 0,
    harvestTonnage: 0,
    harvestTonnageCum: 0,
    harvestAge: 0,
    estimatedIp: 0,
    notes: "",
    averageWeight: 0,
    diffFcr: 0,
    standardFeed: 10.08,
    totalDepletion: 0,
    documentation: null,
  },
  {
    id: "f6c9569a-858c-406e-a101-5f041656c233",
    createdAt: "2025-02-06T13:40:15.213851",
    createdBy: "2cebe2f3-d543-478d-8ddd-556be08f7a9b",
    updatedAt: "2025-02-06T13:40:15.213856",
    updatedBy: "2cebe2f3-d543-478d-8ddd-556be08f7a9b",
    deletedAt: null,
    deletedBy: null,
    day: 6,
    samplingWeight: 1500,
    samplingPopulation: 15,
    mortality: 0,
    culling: 0,
    dailyPopulation: 4990,
    dailyFeedUsed: 600,
    recordDate: "2025-02-06",
    rearingPeriod: {
      id: "6d588544-e163-4f6b-8583-aec9cc86523b",
    },
    usedFeeds: [
      {
        supplier: "PT Farmsco Feed Indonesia",
        usedFeed: {
          qtyUsed: 600,
          id: "228de864-8984-4387-970f-0daf81a03e86",
        },
        id: "e761c8e8-cdcf-40c5-bb33-557e43552b88",
        type: {
          id: "FINISHER",
          labels: "Finisher",
        },
        stock: 0,
        labels: "Bro AB",
      },
    ],
    usedOvks: [],
    standardWeight: 155,
    adg: 100,
    standardFcr: 0.852,
    actualFcr: 3.607,
    feedIntake: 361,
    standardFi: 131,
    standardDepletion: 0.14,
    cumulativeDepletion: 0.2,
    totalFeedUsed: 1800,
    harvestCount: 0,
    harvestCountCum: 0,
    harvestTonnage: 0,
    harvestTonnageCum: 0,
    harvestAge: 0,
    estimatedIp: 46.08,
    notes: "",
    averageWeight: 100,
    diffFcr: 2.76,
    standardFeed: 13.07,
    totalDepletion: 0,
    documentation: null,
  },
  {
    id: "f6c9569a-858c-406e-a101-5f041656c223",
    createdAt: "2025-02-07T13:40:15.213851",
    createdBy: "2cebe2f3-d543-478d-8ddd-556be08f7a9b",
    updatedAt: "2025-02-06T13:40:15.213856",
    updatedBy: "2cebe2f3-d543-478d-8ddd-556be08f7a9b",
    deletedAt: null,
    deletedBy: null,
    day: 6,
    samplingWeight: 1500,
    samplingPopulation: 15,
    mortality: 0,
    culling: 0,
    dailyPopulation: 4990,
    dailyFeedUsed: 600,
    recordDate: "2025-02-07",
    rearingPeriod: {
      id: "6d588544-e163-4f6b-8583-aec9cc86523b",
    },
    usedFeeds: [
      {
        supplier: "PT Farmsco Feed Indonesia",
        usedFeed: {
          qtyUsed: 600,
          id: "228de864-8984-4387-970f-0daf81a03e86",
        },
        id: "e761c8e8-cdcf-40c5-bb33-557e43552b88",
        type: {
          id: "FINISHER",
          labels: "Finisher",
        },
        stock: 0,
        labels: "Bro AB",
      },
    ],
    usedOvks: [],
    standardWeight: 155,
    adg: 100,
    standardFcr: 0.852,
    actualFcr: 3.607,
    feedIntake: 361,
    standardFi: 131,
    standardDepletion: 0.14,
    cumulativeDepletion: 0.2,
    totalFeedUsed: 1800,
    harvestCount: 0,
    harvestCountCum: 0,
    harvestTonnage: 0,
    harvestTonnageCum: 0,
    harvestAge: 0,
    estimatedIp: 46.08,
    notes: "",
    averageWeight: 100,
    diffFcr: 2.76,
    standardFeed: 13.07,
    totalDepletion: 0,
    documentation: null,
  },
  {
    id: "f6c9569a-858c-406e12-a101-5f041656c223",
    createdAt: "2025-02-08T13:40:15.213851",
    createdBy: "2cebe2f3-d543-478d-8ddd-556be08f7a9b",
    updatedAt: "2025-02-06T13:40:15.213856",
    updatedBy: "2cebe2f3-d543-478d-8ddd-556be08f7a9b",
    deletedAt: null,
    deletedBy: null,
    day: 6,
    samplingWeight: 1500,
    samplingPopulation: 15,
    mortality: 0,
    culling: 0,
    dailyPopulation: 4990,
    dailyFeedUsed: 600,
    recordDate: "2025-02-08",
    rearingPeriod: {
      id: "6d588544-e163-4f6b-8583-aec9cc86523b",
    },
    usedFeeds: [
      {
        supplier: "PT Farmsco Feed Indonesia",
        usedFeed: {
          qtyUsed: 600,
          id: "228de864-8984-4387-970f-0daf81a03e86",
        },
        id: "e761c8e8-cdcf-40c5-bb33-557e43552b88",
        type: {
          id: "FINISHER",
          labels: "Finisher",
        },
        stock: 0,
        labels: "Bro AB",
      },
    ],
    usedOvks: [],
    standardWeight: 155,
    adg: 100,
    standardFcr: 0.852,
    actualFcr: 3.607,
    feedIntake: 361,
    standardFi: 131,
    standardDepletion: 0.14,
    cumulativeDepletion: 0.2,
    totalFeedUsed: 1800,
    harvestCount: 0,
    harvestCountCum: 0,
    harvestTonnage: 0,
    harvestTonnageCum: 0,
    harvestAge: 0,
    estimatedIp: 46.08,
    notes: "",
    averageWeight: 100,
    diffFcr: 2.76,
    standardFeed: 13.07,
    totalDepletion: 0,
    documentation: null,
  },
  {
    id: "f6c9569a-858c-4ss06e12-a101-5f041656c223",
    createdAt: "2025-02-09T13:40:15.213851",
    createdBy: "2cebe2f3-d543-478d-8ddd-556be08f7a9b",
    updatedAt: "2025-02-06T13:40:15.213856",
    updatedBy: "2cebe2f3-d543-478d-8ddd-556be08f7a9b",
    deletedAt: null,
    deletedBy: null,
    day: 6,
    samplingWeight: 1500,
    samplingPopulation: 15,
    mortality: 0,
    culling: 0,
    dailyPopulation: 4990,
    dailyFeedUsed: 600,
    recordDate: "2025-02-09",
    rearingPeriod: {
      id: "6d588544-e163-4f6b-8583-aec9cc86523b",
    },
    usedFeeds: [
      {
        supplier: "PT Farmsco Feed Indonesia",
        usedFeed: {
          qtyUsed: 600,
          id: "228de864-8984-4387-970f-0daf81a03e86",
        },
        id: "e761c8e8-cdcf-40c5-bb33-557e43552b88",
        type: {
          id: "FINISHER",
          labels: "Finisher",
        },
        stock: 0,
        labels: "Bro AB",
      },
    ],
    usedOvks: [],
    standardWeight: 155,
    adg: 100,
    standardFcr: 0.852,
    actualFcr: 3.607,
    feedIntake: 361,
    standardFi: 131,
    standardDepletion: 0.14,
    cumulativeDepletion: 0.2,
    totalFeedUsed: 1800,
    harvestCount: 0,
    harvestCountCum: 0,
    harvestTonnage: 0,
    harvestTonnageCum: 0,
    harvestAge: 0,
    estimatedIp: 46.08,
    notes: "",
    averageWeight: 100,
    diffFcr: 2.76,
    standardFeed: 13.07,
    totalDepletion: 0,
    documentation: null,
  },
];
