import { useMutation } from "@tanstack/react-query";

import api from "../../../api/configApi";
import { toast } from "../../../atoms/V2/useToast";

const useDownloadExcel = () => {
  const mutation = useMutation({
    mutationFn: async ({
      id = "",
      search = "",
      hasRealized = false,
      initialPopulation = "",
      finalPopulation = "",
      initialDateSetting = "",
      finalDateSetting = "",
      initialDateRealization = "",
      finalDateRealization = "",
    }) => {
      const response = await api.get("/v1/feedSettings/toXLSX", {
        params: {
          id,
          search,
          hasRealized,
          initialPopulation,
          finalPopulation,
          initialDateSetting,
          finalDateSetting,
          initialDateRealization,
          finalDateRealization,
        },
        responseType: "blob",
      });

      const contentDisposition = response.headers["content-disposition"];
      const fileName = contentDisposition.split("filename=")[1];

      const url = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    },
    onSuccess: () => {
      toast({
        variant: "success",
        title: "Berhasil unduh data ",
      });
    },
    onError: (error) => {
      console.log(error);
      toast({
        title: "Gagal unduh data",
        description: error.response.data.error,
        variant: "error",
      });
    },
  });

  return mutation;
};

export default useDownloadExcel;
