import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export const formatIDR = (value) => {
  const formatter = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
  });

  return `Rp. ${formatter
    .format(value)
    .replace(/IDR|Rp|RP/gi, "")
    .trim()}`;
};

export const formatGram = (value) => {
  if (value === "" || value === null || value === undefined) {
    return "-";
  }

  const numericValue = String(value).replace(/[^0-9.]/g, "");

  if (!numericValue) {
    return "";
  }

  const sanitizedValue = Number(numericValue).toLocaleString("id-ID");

  return `${sanitizedValue}gr`;
};

export const formatNumber = (value) => {
  if (value === "" || value === null || value === undefined || isNaN(value)) {
    return "-";
  }

  const numericValue = String(value).replace(/[^0-9.]/g, "");

  if (!numericValue) {
    return "";
  }

  return Number(numericValue).toLocaleString("id-ID");
};
