import * as React from "react";

function IcFile(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M11.667 1.891v3.442c0 .467 0 .7.09.879.08.156.208.284.365.364.178.09.412.09.878.09h3.442m-3.108 4.167H6.667m6.667 3.334H6.667M8.334 7.5H6.667m5-5.833H7.334c-1.4 0-2.1 0-2.635.272a2.5 2.5 0 00-1.093 1.093c-.272.534-.272 1.234-.272 2.635v8.666c0 1.4 0 2.1.272 2.635a2.5 2.5 0 001.093 1.093c.534.272 1.234.272 2.635.272h5.333c1.4 0 2.1 0 2.635-.272a2.5 2.5 0 001.092-1.093c.273-.535.273-1.235.273-2.635V6.667l-5-5z"
        stroke="currentColor"
        strokeWidth={1.66667}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default IcFile;
