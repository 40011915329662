import { useQuery } from "@tanstack/react-query";

import api from "../../api/configApi";

const useGetNextRecordPeriod = ({ periodId }) => {
  const query = useQuery({
    queryKey: ["get-next-record-period", periodId],
    queryFn: async () => {
      const response = await api.get(`/v1/dailyRecords/nextRecord`, {
        params: {
          periodId,
        },
      });
      return response.data;
    },
    enabled: !!periodId,
  });

  return query;
};

export default useGetNextRecordPeriod;
