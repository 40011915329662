import { useQuery } from "@tanstack/react-query";

import api from "../../api/configApi";

const useGetDetailRearingPeriod = ({ id }) => {
  const query = useQuery({
    queryKey: ["get-detail-rearing-period", id],
    queryFn: async () => {
      const response = await api.get(`/v1/rearingPeriods/${id}`);
      return response.data;
    },
    enabled: !!id,
  });

  return query;
};

export default useGetDetailRearingPeriod;
