import { Tooltip } from "@material-tailwind/react";
import { Typography } from "@material-tailwind/react";
import { format } from "date-fns";
import { id as LOCALE_ID } from "date-fns/locale";

import { cn, formatNumber } from "../../../../../../../shared/utils";
import { isSummarizedRow } from "../config";
import CellAction from "./cell-action";

// TODO:
// 1. Kalau ACT >= STD = warna hijau
// 2. Kalau ACT < STD = warna merah
// 3. Kalau ACT = 0 = warna abu-abu

// TODO:
// DIFF -> FCR :
// Minus itu hijau
// Plus itu merah

// TODO:
// Kondisi:
// Type Sehat, Sakit, Bergejala, -

export const columns = [
  {
    accessorKey: "recordDate",
    header: "Tanggal",
    cell: ({ row }) => {
      const data = row.original.recordDate;

      const isFormatedDate = (date) => {
        if (date) {
          const dateArr = date.split("-");
          if (dateArr.length === 3) {
            return true;
          }
        }
        return false;
      };

      if (isFormatedDate(data)) {
        return (
          <span>
            {format(new Date(data), "dd MMMM yyyy", { locale: LOCALE_ID })}
          </span>
        );
      }

      return (
        <div className="flex w-full items-center justify-center text-sm text-text-primary">
          {data}
        </div>
      );
    },
  },
  {
    accessorKey: "day",
    header: "Umur",
  },
  {
    accessorKey: "dailyPopulation",
    header: "Populasi",
  },
  {
    accessorKey: "totalDepletions",
    header: "Deplesi",
    cell: ({ row }) => {
      const value = row.original.totalDepletion;
      const render = value === 0 ? "-" : value;

      const isCumulative = isSummarizedRow.includes(row.original?.recordDate);

      if (isNaN(value) && isCumulative) {
        return null;
      }

      if (isCumulative) {
        return (
          <span className="flex w-full justify-end text-right font-bold">
            {formatNumber(render)}
          </span>
        );
      }

      return (
        <span className="flex w-full justify-end text-right font-medium">
          {formatNumber(render)}
        </span>
      );
    },
  },
  {
    accessorKey: "usedFeeds",
    header: "Pemberian Pakan (sak)",
    columns: [
      {
        accessorKey: "usedFeedsLabel",
        header: () => {
          return <span className="flex w-full items-start">Jenis Pakan</span>;
        },
        cell: ({ row }) => {
          const values =
            row.original.usedFeeds
              ?.map((item) => item?.labels)
              ?.filter(Boolean) || [];

          const isCumulative = isSummarizedRow.includes(
            row.original?.recordDate,
          );

          if (isCumulative) {
            return null;
          }

          return (
            <div className="line-clamp-2 max-w-[132px]">
              {values.length > 0 ? (
                values.join(", ").length > 40 ? (
                  <Tooltip
                    content={
                      <div className="w-60">
                        <Typography
                          color="white"
                          className="text-sm font-normal"
                        >
                          {values.join(", ")}
                        </Typography>
                      </div>
                    }
                    placement="top"
                  >
                    {values.join(", ")}
                  </Tooltip>
                ) : (
                  values.join(", ")
                )
              ) : (
                "-"
              )}
            </div>
          );
        },
      },
      {
        accessorKey: "dailyFeedUseds",
        header: "Act",
        cell: ({ row }) => {
          const value = row.original.dailyFeedUsed / 50;
          const std = row.original.standardFeed;

          const render = value === 0 ? "-" : value;

          const isCumulative = isSummarizedRow.includes(
            row.original?.recordDate,
          );

          if (isNaN(value) && isCumulative) {
            return null;
          }

          if (isCumulative) {
            return (
              <span className="flex w-full justify-end text-right font-bold">
                {formatNumber(render)}
              </span>
            );
          }

          return (
            <div
              className={cn(
                value === 0
                  ? "text-text-secondary"
                  : value >= std
                    ? "text-green-500"
                    : "text-red-500",
                "text-right font-medium",
              )}
            >
              {formatNumber(render)}
            </div>
          );
        },
      },
      {
        accessorKey: "standardFeeds",
        header: "Std",
        cell: ({ row }) => {
          const value = row.original.standardFeed;

          const render = value === 0 ? "-" : value;

          const isCumulative = isSummarizedRow.includes(
            row.original?.recordDate,
          );

          if (isNaN(value) && isCumulative) {
            return null;
          }

          if (isCumulative) {
            return (
              <span className="flex w-full justify-end text-right font-bold">
                {formatNumber(render)}
              </span>
            );
          }

          return (
            <span className="flex justify-end text-right font-medium">
              {value}
            </span>
          );
        },
      },
    ],
  },
  {
    accessorKey: "fcr",
    header: "FCR",
    columns: [
      {
        accessorKey: "actualFcrs",
        header: "Act",
        cell: ({ row }) => {
          const value = row.original.actualFcr;
          const std = row.original.standardFcr;

          const render = value === 0 ? "-" : value;

          const isCumulative = isSummarizedRow.includes(
            row.original?.recordDate,
          );

          if (isCumulative) {
            return null;
          }

          return (
            <div
              className={cn(
                value === 0
                  ? "text-text-secondary"
                  : value >= std
                    ? "text-green-500"
                    : "text-red-500",
                "text-right font-medium",
              )}
            >
              {formatNumber(render)}
            </div>
          );
        },
      },
      {
        accessorKey: "standardFcrs",
        header: "Std",
        cell: ({ row }) => {
          const value = row.original.standardFcr;

          const render = value === 0 ? "-" : value;

          const isCumulative = isSummarizedRow.includes(
            row.original?.recordDate,
          );

          if (isCumulative) {
            return null;
          }

          return (
            <span className="flex justify-end text-right font-medium">
              {formatNumber(render)}
            </span>
          );
        },
      },
      {
        accessorKey: "diffFcrs",
        header: "Diff",
        cell: ({ row }) => {
          const value = row.original.diffFcr;

          const render = value === 0 ? "-" : value;

          const isCumulative = isSummarizedRow.includes(
            row.original?.recordDate,
          );

          if (isCumulative) {
            return null;
          }

          return (
            <div
              className={cn(
                value === 0
                  ? "text-text-secondary"
                  : value >= 0
                    ? "text-red-500"
                    : "text-green-500",
                "text-right font-medium",
              )}
            >
              {formatNumber(render)}
            </div>
          );
        },
      },
    ],
  },
  {
    accessorKey: "feedIntakeG",
    header: "Feed Intake (g)",
    columns: [
      {
        accessorKey: "feedIntakes",
        header: "Act",
        cell: ({ row }) => {
          const value = row.original.feedIntake;
          const std = row.original.standardFi;

          const render = value === 0 ? "-" : value;

          const isCumulative = isSummarizedRow.includes(
            row.original?.recordDate,
          );

          if (isNaN(value) && isCumulative) {
            return null;
          }

          if (isCumulative) {
            return (
              <span className="flex w-full justify-end text-right font-bold">
                {formatNumber(render)}
              </span>
            );
          }

          return (
            <div
              className={cn(
                value === 0
                  ? "text-text-secondary"
                  : value >= std
                    ? "text-green-500"
                    : "text-red-500",
                "text-right font-medium",
              )}
            >
              {formatNumber(render)}
            </div>
          );
        },
      },
      {
        accessorKey: "standardFis",
        header: "Std",
        cell: ({ row }) => {
          const value = row.original.standardFi;

          const render = value === 0 ? "-" : value;

          const isCumulative = isSummarizedRow.includes(
            row.original?.recordDate,
          );

          if (isNaN(value) && isCumulative) {
            return null;
          }

          if (isCumulative) {
            return (
              <span className="flex w-full justify-end text-right font-bold">
                {formatNumber(render)}
              </span>
            );
          }

          return (
            <span className="flex justify-end text-right font-medium">
              {formatNumber(render)}
            </span>
          );
        },
      },
    ],
  },
  {
    accessorKey: "bwG",
    header: "BW (g)",
    columns: [
      {
        accessorKey: "averageWeights",
        header: "Act",
        cell: ({ row }) => {
          const value = row.original.averageWeight;
          const std = row.original.standardWeight;

          const render = value === 0 ? "-" : value;

          const isCumulative = isSummarizedRow.includes(
            row.original?.recordDate,
          );

          if (isCumulative) {
            return null;
          }

          return (
            <div
              className={cn(
                value === 0
                  ? "text-text-secondary"
                  : value >= std
                    ? "text-green-500"
                    : "text-red-500",
                "text-right font-medium",
              )}
            >
              {formatNumber(render)}
            </div>
          );
        },
      },
      {
        accessorKey: "standardWeights",
        header: "Std",
        cell: ({ row }) => {
          const value = row.original.standardWeight;

          const render = value === 0 ? "-" : value;

          const isCumulative = isSummarizedRow.includes(
            row.original?.recordDate,
          );

          if (isCumulative) {
            return null;
          }

          return (
            <span className="flex justify-end text-right font-medium">
              {formatNumber(render)}
            </span>
          );
        },
      },
    ],
  },
  {
    accessorKey: "estimatedIps",
    header: "IP",
    cell: ({ row }) => {
      const value = row.original.estimatedIp;

      const render = value === 0 ? "-" : value;

      const isCumulative = isSummarizedRow.includes(row.original?.recordDate);

      if (isCumulative) {
        return null;
      }

      return (
        <span className="flex w-full justify-end text-right font-medium">
          {formatNumber(render)}
        </span>
      );
    },
  },
  {
    accessorKey: "willBeEdited_SisaPakan",
    header: "Pakan Masuk",
    columns: [
      {
        accessorKey: "feedInVendors",
        header: "Vendor",
        cell: ({ row }) => {
          const value = row.original.feedInVendor;

          const render = value === 0 ? "-" : value / 50;

          const isCumulative = isSummarizedRow.includes(
            row.original?.recordDate,
          );

          if (isNaN(value) && isCumulative) {
            return null;
          }

          if (isCumulative) {
            return (
              <span className="flex w-full justify-end text-right font-bold">
                {formatNumber(render) !== "-"
                  ? formatNumber(render) + " Sak"
                  : formatNumber(render)}
              </span>
            );
          }

          return (
            <div className={cn("text-right font-medium")}>
              {formatNumber(render) !== "-"
                ? formatNumber(render) + " Sak"
                : formatNumber(render)}
            </div>
          );
        },
      },
      {
        accessorKey: "feedInMutations",
        header: "Mutasi",
        cell: ({ row }) => {
          const value = row.original.feedInMutation;

          const render = value === 0 ? "-" : value / 50;

          const isCumulative = isSummarizedRow.includes(
            row.original?.recordDate,
          );

          if (isNaN(value) && isCumulative) {
            return null;
          }

          if (isCumulative) {
            return (
              <span className="flex w-full justify-end text-right font-bold">
                {formatNumber(render) !== "-"
                  ? formatNumber(render) + " Sak"
                  : formatNumber(render)}
              </span>
            );
          }

          return (
            <div className={cn("text-right font-medium")}>
              {formatNumber(render) !== "-"
                ? formatNumber(render) + " Sak"
                : formatNumber(render)}
            </div>
          );
        },
      },
    ],
  },
  {
    accessorKey: "dailyFeedOuts",
    header: "Pakan Keluar",
    cell: ({ row }) => {
      const value = row.original.dailyFeedOut;

      const render = value === 0 ? "-" : value / 50;
      const isCumulative = isSummarizedRow.includes(row.original?.recordDate);

      if (isNaN(value) && isCumulative) {
        return null;
      }

      if (isCumulative) {
        return (
          <span className="flex w-full justify-end text-right font-bold">
            {formatNumber(render) !== "-"
              ? formatNumber(render) + " Sak"
              : formatNumber(render)}
          </span>
        );
      }

      return (
        <div className={cn("text-right font-medium")}>
          {formatNumber(render) !== "-"
            ? formatNumber(render) + " Sak"
            : formatNumber(render)}
        </div>
      );
    },
  },
  {
    accessorKey: "willBeEdited_Pakan",
    header: "Stok Pakan",
    columns: [
      {
        accessorKey: "dailyStocks",
        header: "Recording",
        cell: ({ row }) => {
          const value = row.original.dailyStock;

          const render = value === 0 ? "-" : value / 50;

          const isCumulative = isSummarizedRow.includes(
            row.original?.recordDate,
          );

          if (isNaN(value) && isCumulative) {
            return null;
          }

          if (isCumulative) {
            return (
              <span className="flex w-full justify-end text-right font-bold">
                {formatNumber(render) !== "-"
                  ? formatNumber(render) + " Sak"
                  : formatNumber(render)}
              </span>
            );
          }

          return (
            <div className={cn("text-right font-medium")}>
              {formatNumber(render) !== "-"
                ? formatNumber(render) + " Sak"
                : formatNumber(render)}
            </div>
          );
        },
      },
      {
        accessorKey: "visitStocks",
        header: "Kunjungan",
        cell: ({ row }) => {
          const value = row.original.visitStock;

          const render = value === 0 ? "-" : value / 50;

          const isCumulative = isSummarizedRow.includes(
            row.original?.recordDate,
          );

          if (isNaN(value) && isCumulative) {
            return null;
          }

          if (isCumulative) {
            return (
              <span className="flex w-full justify-end text-right font-bold">
                {formatNumber(render) !== "-"
                  ? formatNumber(render) + " Sak"
                  : formatNumber(render)}
              </span>
            );
          }

          return (
            <div className={cn("text-right font-medium")}>
              {formatNumber(render) !== "-"
                ? formatNumber(render) + " Sak"
                : formatNumber(render)}
            </div>
          );
        },
      },
    ],
  },
  {
    accessorKey: "usedOvks",
    header: "Pemberian OVK",
    columns: [
      {
        accessorKey: "usedOvksType",
        header: "Jenis OVK",
        cell: ({ row }) => {
          const values =
            row.original.usedOvks
              ?.map((item) => item?.labels)
              ?.filter(Boolean) || [];

          const isCumulative = isSummarizedRow.includes(
            row.original?.recordDate,
          );

          if (isCumulative) {
            return null;
          }

          return (
            <div className="line-clamp-2 max-w-[132px]">
              {values.length > 0 ? (
                values.join(", ").length > 40 ? (
                  <Tooltip
                    content={
                      <div className="w-60">
                        <Typography
                          color="white"
                          className="text-sm font-normal"
                        >
                          {values.join(", ")}
                        </Typography>
                      </div>
                    }
                    placement="top"
                  >
                    {values.join(", ")}
                  </Tooltip>
                ) : (
                  values.join(", ")
                )
              ) : (
                "-"
              )}
            </div>
          );
        },
      },
      {
        accessorKey: "OvksUnits",
        header: "Satuan",
        cell: ({ row }) => {
          const values =
            row.original.usedOvks
              ?.map((item) => item?.unit?.labels)
              ?.filter(Boolean) || [];

          const isCumulative = isSummarizedRow.includes(
            row.original?.recordDate,
          );

          if (isCumulative) {
            return null;
          }

          return (
            <div className="line-clamp-2 max-w-[132px]">
              {values.length > 0 ? (
                values.join(", ").length > 40 ? (
                  <Tooltip
                    content={
                      <div className="w-60">
                        <Typography
                          color="white"
                          className="text-sm font-normal"
                        >
                          {values.join(", ")}
                        </Typography>
                      </div>
                    }
                    placement="top"
                  >
                    {values.join(", ")}
                  </Tooltip>
                ) : (
                  values.join(", ")
                )
              ) : (
                "-"
              )}
            </div>
          );
        },
      },
      {
        accessorKey: "OvksUsedOvk",
        header: "Act",
        cell: ({ row }) => {
          const value =
            row.original.usedOvks
              ?.map((item) => item?.usedOvk?.qtyUsed)
              .reduce((acc, curr) => acc + curr, 0) || 0;

          const usedOVK =
            row.original.OvksUsed !== 0 ? row.original.OvksUsed : "-";

          const render = value === 0 ? "-" : value;

          const isCumulative = isSummarizedRow.includes(
            row.original?.recordDate,
          );

          if (isCumulative) {
            return (
              <span className="flex w-full justify-end text-right font-bold">
                {formatNumber(usedOVK)}
              </span>
            );
          }

          return (
            <span className="flex justify-end text-right font-medium">
              {formatNumber(render)}
            </span>
          );
        },
      },
    ],
  },
  //   TODO: Implemented later.
  //   {
  //     accessorKey: "condition",
  //     header: "Kondisi",
  //   },
  {
    accessorKey: "notes",
    header: "Catatan",
    cell: ({ row }) => {
      const value = row.original.notes;

      const isCumulative = isSummarizedRow.includes(row.original?.recordDate);

      if (isCumulative) {
        return null;
      }

      return <div className="max-w-[132px]">{value || "-"}</div>;
    },
  },
  {
    accessorKey: "action",
    header: "",
    cell: ({ row }) => {
      const isCumulative = isSummarizedRow.includes(row.original?.recordDate);

      if (isCumulative) {
        return null;
      }

      return <CellAction id={row.original.id} />;
    },
  },
];
