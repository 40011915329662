import { useQuery } from "@tanstack/react-query";

import api from "../../../api/configApi";

const useGetSettingDOC = ({
  itemPerPage = 10,
  currentPage = 1,
  sortBy = "updatedAt",
  descending = true,
  search = "",
  hasRealized = false,
  unit ="",
  year = "",
  month = "",
  week = "",
  initialPopulation = "",
  finalPopulation = "",
  initialDateSetting = "",
  finalDateSetting = "",
  initialDateRealization = "",
  finalDateRealization = "",
}) => {
  const query = useQuery({
    queryKey: [
      "get-setting-doc",
      {
        itemPerPage,
        currentPage,
        sortBy,
        descending,
        search,
        hasRealized,
        unit,
        year,
        month,
        week,
        initialPopulation,
        finalPopulation,
        initialDateSetting,
        finalDateSetting,
        initialDateRealization,
        finalDateRealization,
      },
    ],
    queryFn: async () => {
      const response = await api.get("/v1/docSettings", {
        params: {
          itemPerPage,
          currentPage,
          sortBy,
          descending,
          search,
          hasRealized,
          unit,
          year,
          month,
          week,
          initialPopulation,
          finalPopulation,
          initialDateSetting,
          finalDateSetting,
          initialDateRealization,
          finalDateRealization,
        },
      });
      return response;
    },
    select: ({ data }) => data,
  });

  return query;
};

export default useGetSettingDOC;
