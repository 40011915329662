import { useMutation } from "@tanstack/react-query";

import api from "../../api/configApi";

const useGetDocDropdown = () => {
  const mutate = useMutation({
    mutationFn: async () => {
      const response = await api.get("/v1/docs/dropdown");
      return response.data;
    },
  });

  return mutate;
};

export default useGetDocDropdown;
