import * as React from "react";

import {
  CheckCircleIcon,
  InformationCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import * as ToastPrimitives from "@radix-ui/react-toast";
import { cva } from "class-variance-authority";
import { FaXmark } from "react-icons/fa6";
import { LuLoader } from "react-icons/lu";

import { cn } from "../../shared/utils";

const ToastProvider = ToastPrimitives.Provider;

const ToastViewport = React.forwardRef(({ className, ...props }, ref) => (
  <ToastPrimitives.Viewport
    ref={ref}
    className={cn(
      "fixed top-0 z-[100] flex max-h-screen min-w-[300px] flex-col-reverse p-4 sm:right-0 sm:top-14 sm:flex-col md:max-w-[420px]",
      className,
    )}
    {...props}
  />
));
ToastViewport.displayName = ToastPrimitives.Viewport.displayName;

const toastVariants = cva(
  "group pointer-events-auto relative flex w-full items-center justify-between space-x-3 overflow-hidden rounded-md border  shadow-lg transition-all data-[swipe=cancel]:translate-x-0 data-[swipe=end]:translate-x-[var(--radix-toast-swipe-end-x)] data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)] data-[swipe=move]:transition-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[swipe=end]:animate-out data-[state=closed]:fade-out-80 data-[state=closed]:slide-out-to-right-full data-[state=open]:slide-in-from-top-full data-[state=open]:sm:slide-in-from-bottom-full",
  {
    variants: {
      variant: {
        default: "border bg-white text-foreground",
        error:
          "destructive group border-destructive bg-destructive text-destructive-foreground",
        success: "bg-[#059669] border-[#059669] text-white",
        loading: "bg-[#0C111D] border-[#0C111D] text-white",
      },
      size: {
        small: "px-xl py-lg",
        base: "px-xl py-3xl",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "base",
    },
  },
);

const Toast = React.forwardRef(
  ({ className, variant, size, ...props }, ref) => {
    const IconComponent = (variant) => {
      switch (variant) {
        case "destructive":
          return XCircleIcon;
        case "success":
          return CheckCircleIcon;
        case "loading":
          return LuLoader;
        default:
          return InformationCircleIcon;
      }
    };

    const Icon = IconComponent(variant);

    return (
      <ToastPrimitives.Root
        ref={ref}
        className={cn(
          toastVariants({
            variant,
            size,
          }),
          className,
          "flex items-center",
        )}
        {...props}
      >
        <Icon
          className={cn(
            "flex-shrink-0",
            size === "small" ? "size-5" : "size-6",
            variant === "loading" && "animate-spin",
          )}
        />
        <div className="flex-1">{props.children}</div>
      </ToastPrimitives.Root>
    );
  },
);
Toast.displayName = ToastPrimitives.Root.displayName;

const ToastAction = React.forwardRef(({ className, ...props }, ref) => (
  <ToastPrimitives.Action
    ref={ref}
    className={cn(
      "inline-flex h-8 shrink-0 items-center justify-center rounded-md border bg-transparent px-3 text-sm font-medium ring-offset-background transition-colors hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
      className,
    )}
    {...props}
  />
));
ToastAction.displayName = ToastPrimitives.Action.displayName;

const ToastClose = React.forwardRef(({ className, ...props }, ref) => (
  <ToastPrimitives.Close
    ref={ref}
    className={cn(
      "absolute right-1 top-1/2 -translate-y-1/2 rounded-md p-1 text-white/70 opacity-0 transition-opacity hover:text-white focus:opacity-100 focus:outline-none focus:ring-1 group-hover:opacity-100",
      className,
    )}
    toast-close=""
    {...props}
  >
    <FaXmark className="size-6" />
  </ToastPrimitives.Close>
));
ToastClose.displayName = ToastPrimitives.Close.displayName;

const ToastTitle = React.forwardRef(({ className, ...props }, ref) => (
  <ToastPrimitives.Title
    ref={ref}
    className={cn("text-sm font-semibold", className)}
    {...props}
  />
));
ToastTitle.displayName = ToastPrimitives.Title.displayName;

const ToastDescription = React.forwardRef(({ className, ...props }, ref) => (
  <ToastPrimitives.Description
    ref={ref}
    className={cn("text-sm opacity-90", className)}
    {...props}
  />
));
ToastDescription.displayName = ToastPrimitives.Description.displayName;

export {
  ToastProvider,
  ToastViewport,
  Toast,
  ToastTitle,
  ToastDescription,
  ToastClose,
  ToastAction,
};
