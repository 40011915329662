import { createContext, useContext, useEffect, useState } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { useFieldArray, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import {
  useDownloadExcelDailyRecord,
  useGetDailyRecords,
  useGetDetailDailyRecords,
  useMutateAddDailyRecord,
  useMutateEditDailyRecord,
} from "../../../../../../../useCases/DailyRecords";
import { useGetNextRecordPeriod } from "../../../../../../../useCases/RearingPeriod";
import { useGetListStockSapronak } from "../../../../../../../useCases/Sapronak";
import { formSchema } from "../config";

const RecordingHarian = createContext(undefined);

export const RecordingHarianStore = ({ children }) => {
  const { id } = useParams();
  const [isAddDrawerVisible, setIsAddDrawerVisible] = useState(false);
  const [bwPerUnit, setBwPerUnit] = useState(0);
  const [isAddPeriod, setIsAddPeriod] = useState(false);
  const [isAddPeriodWithNextDay, setIsAddPeriodWithNextDay] = useState(false);
  const [excludeProducts, setExcludeProducts] = useState([]);

  const [mappedData, setMappedData] = useState([]);

  const [maxStocks, setMaxStocks] = useState({
    pakan: [],
    ovk: [],
  });
  const [previousProducts, setPreviousProducts] = useState([]);
  const [selectedDetail, setSelectedDetail] = useState(null);
  const [isEdit, setIsEdit] = useState(false);

  const queryData = useGetDailyRecords({
    periodId: id,
  });
  const queryNextDay = useGetNextRecordPeriod({
    periodId: id,
  });
  const queryProducts = useGetListStockSapronak({
    periodId: id,
    exclude: excludeProducts?.join(","),
  });
  const queryDetail = useGetDetailDailyRecords({
    id: selectedDetail !== null ? selectedDetail : null,
  });
  const mutateAdd = useMutateAddDailyRecord();
  const mutateEdit = useMutateEditDailyRecord({
    id: selectedDetail !== null ? selectedDetail : null,
  });
  const mutateDownload = useDownloadExcelDailyRecord({
    periodId: id,
  });

  const isLoadingAdd = queryNextDay.isLoading || queryProducts.isPending;

  const handleOpenAddDrawer = () => {
    setIsAddDrawerVisible(true);
    setIsEdit(false);
    setSelectedDetail(null);
    setExcludeProducts([]);
    setPreviousProducts([]);
    form.reset({
      mortality: 0,
      culling: 0,
      samplingWeight: 0,
      samplingPopulation: 0,
      products: [{ productId: "", type: "pakan", qty: 1 }],
    });
  };

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      mortality: 0,
      culling: 0,
      samplingWeight: 0,
      samplingPopulation: 0,
      products: [{ productId: "", type: "pakan", qty: 1 }],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "products",
  });

  useEffect(() => {
    if (selectedDetail !== null && queryDetail.data) {
      // Store the current products for reference
      const currentProducts = [
        ...queryDetail.data.usedFeeds.map((feed) => ({
          productId: feed.id,
          type: "pakan",
          qty: feed.usedFeed?.qtyUsed / 50,
        })),
        ...queryDetail.data.usedOvks.map((ovk) => ({
          productId: ovk.id,
          type: "ovk",
          qty: ovk.usedOvk?.qtyUsed,
        })),
      ];

      const previousProducts = {
        pakan: [
          ...queryDetail.data.usedFeeds.map((feed) => ({
            productId: feed.id,
            type: "pakan",
            qty: feed.stock,
            labels: feed.labels,
            stock: feed.stock / 50,
          })),
        ],
        ovk: [
          ...queryDetail.data.usedOvks.map((ovk) => ({
            productId: ovk.id,
            type: "ovk",
            qty: ovk.stock,
            labels: ovk.labels,
            stock: ovk.stock,
          })),
        ],
      };

      setPreviousProducts(previousProducts);

      // Set form values
      form.reset({
        mortality: queryDetail.data.mortality,
        culling: queryDetail.data.culling,
        samplingWeight: queryDetail.data?.samplingWeight,
        samplingPopulation: queryDetail.data?.samplingPopulation,
        products: currentProducts,
      });

      // Set initial exclude products but keep the currently selected one
      const initialExcludeProducts = [
        ...queryDetail.data.usedFeeds.map((feed) => feed.id),
        ...queryDetail.data.usedOvks.map((ovk) => ovk.id),
      ];

      setExcludeProducts(initialExcludeProducts);
    }
  }, [selectedDetail, queryDetail.data, form, isEdit]);

  useEffect(() => {
    if (queryData.data) {
      const newMappedData = [];
      let cumulativeDepletion = 0;
      let cumulativeDailyFeedUsed = 0;
      let cumulativeFeedIntake = 0;
      let cumulativeStandardFeed = 0;
      let cumulativeStandardFi = 0;
      let cumulativeOvksUsed = 0;
      let cumulativeValue = 0;
      let cumulativeFeedInVendor = 0;
      let cumulativeFeedInMutation = 0;
      let cumulativeDailyFeedOut = 0;
      let cumulativeDailyStock = 0;
      let cumulativeVisitStock = 0;

      for (let i = 0; i < queryData.data?.totalItems; i += 7) {
        const group = queryData.data?.contents?.slice(i, i + 7);

        group.forEach((item) => newMappedData.push(item));

        const weeklyDepletion = group.reduce(
          (sum, item) => sum + item.totalDepletion,
          0,
        );
        const weeklyDailyFeedUsed = group.reduce(
          (sum, item) => sum + item.dailyFeedUsed,
          0,
        );
        const weeklyFeedIntake = group.reduce(
          (sum, item) => sum + item.feedIntake,
          0,
        );
        const weeklyStandardFeed = group.reduce(
          (sum, item) => sum + item.standardFeed,
          0,
        );
        const weeklyStandardFi = group.reduce(
          (sum, item) => sum + item.standardFi,
          0,
        );
        const weeklyValue = group.reduce((sum, item) => sum + item.value, 0);
        const weeklyOvksUsed = group.reduce((sum, item) => {
          const usedQty = item.usedOvks?.reduce(
            (acc, unit) => acc + (unit?.usedOvk?.qtyUsed || 0),
            0,
          );
          return sum + (usedQty || 0);
        }, 0);

        const weeklyFeedInVendor = group.reduce(
          (sum, item) => sum + item.feedInVendor,
          0,
        );

        const weeklyFeedInMutation = group.reduce(
          (sum, item) => sum + item.feedInMutation,
          0,
        );

        const weeklyDailyFeedOut = group.reduce(
          (sum, item) => sum + item.dailyFeedOut,
          0,
        );

        const weeklyDailyStock = group.reduce(
          (sum, item) => sum + item.dailyStock,
          0,
        );

        const weeklyVisitStock = group.reduce(
          (sum, item) => sum + item.visitStock,
          0,
        );

        // Accumulate cumulative values
        cumulativeDepletion += weeklyDepletion;
        cumulativeDailyFeedUsed += weeklyDailyFeedUsed;
        // eslint-disable-next-line no-unused-vars
        cumulativeFeedIntake += weeklyFeedIntake;
        cumulativeStandardFeed += weeklyStandardFeed;
        // eslint-disable-next-line no-unused-vars
        cumulativeStandardFi += weeklyStandardFi;
        cumulativeOvksUsed += weeklyOvksUsed;
        cumulativeValue += weeklyValue;
        cumulativeFeedInVendor += weeklyFeedInVendor;
        cumulativeFeedInMutation += weeklyFeedInMutation;
        cumulativeDailyFeedOut += weeklyDailyFeedOut;
        // eslint-disable-next-line no-unused-vars
        cumulativeDailyStock += weeklyDailyStock;
        // eslint-disable-next-line no-unused-vars
        cumulativeVisitStock += weeklyVisitStock;

        // Add "Summary Week X"
        newMappedData.push({
          id: `summary-${i}-${i + 7}`,
          recordDate: `Minggu ${Math.floor(i / 7) + 1}`,
          value: weeklyValue,
          totalDepletion: weeklyDepletion,
          dailyFeedUsed: weeklyDailyFeedUsed,
          standardFeed: weeklyStandardFeed,
          OvksUsed: weeklyOvksUsed,
          feedInVendor: weeklyFeedInVendor,
          feedInMutation: weeklyFeedInMutation,
          dailyFeedOut: weeklyDailyFeedOut,
        });

        // Add "Cumulative" up to this week
        newMappedData.push({
          id: `cumulative-${i}-${i + 7}`,
          recordDate: "Cumulative",
          value: cumulativeValue,
          totalDepletion: cumulativeDepletion,
          dailyFeedUsed: cumulativeDailyFeedUsed,
          standardFeed: cumulativeStandardFeed,
          OvksUsed: cumulativeOvksUsed,
          feedInVendor: cumulativeFeedInVendor,
          feedInMutation: cumulativeFeedInMutation,
          dailyFeedOut: cumulativeDailyFeedOut,
        });
      }

      setMappedData(newMappedData);
    }
  }, [queryData.data]);

  const createRearingPayload = (data, rearingPeriodId) => {
    const payload = {
      rearingPeriod: { id: rearingPeriodId },
      mortality: data.mortality,
      culling: data.culling,
      samplingWeight: data.samplingWeight,
      samplingPopulation: data.samplingPopulation,
    };

    const transformProducts = (products, type, key, productKey) => {
      if (products.some((product) => product.type === type)) {
        return products
          .filter((product) => product.type === type)
          .map((product) => ({
            [productKey]: { id: product.productId },
            qtyUsed: product.qty * 50,
          }));
      }
      return undefined;
    };

    // Transform feeds
    const usedFeeds = transformProducts(
      data.products,
      "pakan",
      "usedFeeds",
      "feed",
    );
    if (usedFeeds) {
      payload.usedFeeds = usedFeeds;
    }

    // Transform OVKs
    const usedOvks = transformProducts(data.products, "ovk", "usedOvks", "ovk");
    if (usedOvks) {
      payload.usedOvks = usedOvks;
    }

    return payload;
  };

  const calculateBwPerUnit = (weight, population) => {
    if (population > 0) {
      return weight / population;
    }
    return 0;
  };

  const handleOpenDialogSubmit = async () => {
    const isValid = await form.trigger();
    if (isValid) {
      const hasEmptyPakanProduct = form
        .getValues()
        .products.some(
          (product) =>
            product.type === "pakan" && product.productId.trim() === "",
        );
      const hasEmptyOvkProduct = form
        .getValues()
        .products.some(
          (product) =>
            product.type === "ovk" && product.productId.trim() === "",
        );

      if (hasEmptyPakanProduct) {
        form.setError("products", {
          type: "manual",
          message: "Produk harus diisi untuk tipe pakan",
        });
      } else if (hasEmptyOvkProduct) {
        form.setError("products", {
          type: "manual",
          message: "Produk harus diisi untuk tipe OVK",
        });
      } else {
        setIsAddPeriod(true);
        return;
      }
    }
    form.trigger();
  };

  const handleOpenDialogSubmitNextDay = async () => {
    const isValid = await form.trigger();
    if (isValid) {
      const hasEmptyPakanProduct = form
        .getValues()
        .products.some(
          (product) =>
            product.type === "pakan" && product.productId.trim() === "",
        );
      const hasEmptyOvkProduct = form
        .getValues()
        .products.some(
          (product) =>
            product.type === "ovk" && product.productId.trim() === "",
        );

      if (hasEmptyPakanProduct) {
        form.setError("products", {
          type: "manual",
          message: "Produk harus diisi untuk tipe pakan",
        });
      } else if (hasEmptyOvkProduct) {
        form.setError("products", {
          type: "manual",
          message: "Produk harus diisi untuk tipe OVK",
        });
      } else {
        setIsAddPeriodWithNextDay(true);
      }
    }
  };

  const onSubmit = (data) => {
    const payload = createRearingPayload(data, id);
    mutateAdd.mutate(payload, {
      onSuccess: () => {
        form.reset();
        setIsAddDrawerVisible(false);
        setIsAddPeriod(false);
      },
      onError: () => {
        setIsAddPeriod(false);
      },
    });
  };

  const onSubmitEdit = (data) => {
    const payload = createRearingPayload(data, id);
    mutateEdit.mutate(payload, {
      onSuccess: () => {
        form.reset();
        setIsAddDrawerVisible(false);
        setIsAddPeriod(false);
        setIsEdit(false);
        setSelectedDetail(null);
      },
      onError: () => {
        setIsAddPeriod(false);
      },
    });
  };

  const onSubmitWithNextDay = (data) => {
    const payload = createRearingPayload(data, id);
    mutateAdd.mutate(payload, {
      onSuccess: () => {
        form.reset();
        setIsAddPeriodWithNextDay(false);
        setExcludeProducts([]);
      },
      onError: () => {
        setIsAddPeriodWithNextDay(false);
      },
    });
  };

  return (
    <RecordingHarian.Provider
      value={{
        isAddDrawerVisible,
        setIsAddDrawerVisible,
        bwPerUnit,
        setBwPerUnit,
        isAddPeriod,
        setIsAddPeriod,
        isAddPeriodWithNextDay,
        setIsAddPeriodWithNextDay,
        excludeProducts,
        setExcludeProducts,
        selectedDetail,
        setSelectedDetail,
        isEdit,
        setIsEdit,
        previousProducts,
        setPreviousProducts,
        maxStocks,
        setMaxStocks,
        queryData,
        queryNextDay,
        queryProducts,
        queryDetail,
        mutateAdd,
        mutateEdit,
        mutateDownload,
        mappedData,
        isLoadingAdd,
        handleOpenAddDrawer,
        handleOpenDialogSubmit,
        handleOpenDialogSubmitNextDay,
        form,
        fields,
        append,
        remove,
        calculateBwPerUnit,
        onSubmit,
        onSubmitEdit,
        onSubmitWithNextDay,
      }}
    >
      {children}
    </RecordingHarian.Provider>
  );
};

export const useRecordingHarian = () => {
  const context = useContext(RecordingHarian);
  if (context === undefined) {
    throw new Error(
      "useRecordingHarian must be used within a RecordingHarianStore",
    );
  }
  return context;
};
