import React from "react";

import * as CheckboxPrimitive from "@radix-ui/react-checkbox";

import { cn } from "../../shared/utils";
import { IcCheck, IcMinus } from "../icons";

const Checkbox = React.forwardRef(
  ({ className, variant = "default", ...props }, ref) => {
    return (
      <CheckboxPrimitive.Root
        className={cn(
          "peer h-4 w-4 shrink-0 rounded-xs border border-border-primary",
          "focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-bg-brandSolid",
          "disabled:cursor-not-allowed disabled:opacity-50",
          "data-[state=checked]:border-bg-brandSolid data-[state=checked]:bg-bg-brandSolid",
          "[&[data-state=indeterminate]]:border-bg-brandSolid [&[data-state=indeterminate]]:bg-bg-brandSolid",
          className,
        )}
        {...props}
      >
        <CheckboxPrimitive.Indicator
          className={cn(
            "flex items-center justify-center",
            "text-white",
            "[&_svg]:size-3",
          )}
        >
          {props.checked === "indeterminate" && <IcMinus />}
          {props.checked === true && <IcCheck />}
        </CheckboxPrimitive.Indicator>
      </CheckboxPrimitive.Root>
    );
  },
);

Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
