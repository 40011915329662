import React from "react";

import { format } from "date-fns";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

import {
  IcClipboardPlus,
  IcFile,
  IcPlus,
  IcScales,
  IcTrash,
} from "../../../../../../../atoms/icons";
import { Button } from "../../../../../../../atoms/V2/Button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "../../../../../../../atoms/V2/Dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../../../../../atoms/V2/Form";
import { Input } from "../../../../../../../atoms/V2/Input";
import { InputNumber } from "../../../../../../../atoms/V2/InputNumber";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../../../../atoms/V2/Select";
import { Separator } from "../../../../../../../atoms/V2/Separator";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from "../../../../../../../atoms/V2/Sheet";
import { Skeleton } from "../../../../../../../atoms/V2/Skeleton";
import { cn, formatNumber } from "../../../../../../../shared/utils";
import { useRecordingHarian } from "../hook";
import { BadgeStatus } from "./";
import { CustomDropdown } from "./custom-dropdown";

const SheetForm = () => {
  const {
    isAddDrawerVisible,
    setIsAddDrawerVisible,
    bwPerUnit,
    setBwPerUnit,
    isAddPeriod,
    setIsAddPeriod,
    isAddPeriodWithNextDay,
    setIsAddPeriodWithNextDay,
    setExcludeProducts,
    previousProducts,
    isEdit,
    maxStocks,
    setMaxStocks,
    queryNextDay,
    queryProducts,
    queryDetail,
    mutateAdd,
    mutateEdit,
    handleOpenDialogSubmit,
    handleOpenDialogSubmitNextDay,
    isLoadingAdd,
    form,
    fields,
    append,
    remove,
    calculateBwPerUnit,
    onSubmit,
    onSubmitEdit,
    onSubmitWithNextDay,
  } = useRecordingHarian();

  return (
    <Sheet
      open={isAddDrawerVisible}
      onOpenChange={(open) => {
        setIsAddDrawerVisible(open);
        if (!open) {
          form.reset();
          setExcludeProducts([]);
        }
      }}
    >
      <SheetContent className="px-0 pt-3xl">
        {queryDetail.isLoading ? (
          <div className="flex h-full flex-col">
            <SheetHeader className="flex justify-between border-b px-3xl pb-5">
              <Skeleton className="h-10 w-5/12" />
            </SheetHeader>
            <div className="mt-5 space-y-xl px-3xl">
              <div className="space-y-7">
                <div className="space-y-2">
                  <Skeleton className="h-5 w-4/12" />
                  <Skeleton className="h-8 w-full" />
                </div>
                <div className="space-y-2">
                  <Skeleton className="h-5 w-4/12" />
                  <Skeleton className="h-8 w-full" />
                </div>
                <div className="space-y-2">
                  <Skeleton className="h-5 w-4/12" />
                  <Skeleton className="h-8 w-full" />
                </div>
                <div className="space-y-2">
                  <Skeleton className="h-5 w-4/12" />
                  <Skeleton className="h-8 w-full" />
                </div>
                <div className="space-y-2">
                  <Skeleton className="h-5 w-4/12" />
                  <Skeleton className="h-8 w-full" />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex h-full flex-col">
            <Form {...form}>
              <form>
                <SheetHeader className="space-y-xs border-b px-3xl pb-5">
                  <SheetTitle className="text-text-primary">
                    {isEdit ? "Edit" : "Tambah"} data harian
                  </SheetTitle>
                  <SheetDescription className="flex items-center gap-2 text-text-tertiary">
                    <span>
                      {isEdit
                        ? queryDetail.data?.recordDate &&
                          format(
                            new Date(queryDetail.data.recordDate),
                            "dd MMMM yyyy",
                          )
                        : queryNextDay.data?.date &&
                          format(
                            new Date(queryNextDay.data.date),
                            "dd MMMM yyyy",
                          )}
                    </span>
                    <Separator orientation="vertical" className="h-[14px]" />
                    <p className="text-text-secondary">
                      Hari ke-{" "}
                      {isEdit ? queryDetail.data?.day : queryNextDay.data?.day}
                    </p>
                  </SheetDescription>
                </SheetHeader>
                <div className="max-h-[calc(100vh-180px)] overflow-y-auto scrollbar-hide">
                  <div className="space-y-9 px-3xl py-xl">
                    <div className="space-y-lg">
                      <div className="flex items-center gap-xs">
                        <IcFile className="size-5 text-[#484B4F]" />
                        <p className="text-text-primary">Deplesi</p>
                      </div>
                      <div className="grid grid-cols-2 gap-xl">
                        <FormField
                          control={form.control}
                          name="mortality"
                          render={({ field }) => {
                            return (
                              <FormItem>
                                <FormLabel>Kematian</FormLabel>
                                <FormControl>
                                  <InputNumber
                                    id={field.name}
                                    value={field.value ?? "0"}
                                    onValueChange={(values) => {
                                      field.onChange(
                                        values.value === "" ? 0 : values.value,
                                      );
                                    }}
                                    isError={form.formState.errors.mortality}
                                    rightComponent={
                                      <span className="text-sm text-text-placeholder">
                                        Ekor
                                      </span>
                                    }
                                  />
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            );
                          }}
                        />
                        <FormField
                          control={form.control}
                          name="culling"
                          render={({ field }) => {
                            return (
                              <FormItem>
                                <FormLabel>Pemisahan</FormLabel>
                                <FormControl>
                                  <InputNumber
                                    id={field.name}
                                    value={field.value ?? "0"}
                                    onValueChange={(values) => {
                                      field.onChange(
                                        values.value === "" ? 0 : values.value,
                                      );
                                    }}
                                    isError={form.formState.errors.culling}
                                    rightComponent={
                                      <span className="text-sm text-text-placeholder">
                                        Ekor
                                      </span>
                                    }
                                  />
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            );
                          }}
                        />
                      </div>
                    </div>
                    <div className="space-y-lg">
                      <div className="flex items-center gap-xs">
                        <IcScales className="size-5 text-[#484B4F]" />
                        <p className="text-text-primary">Sampling BW</p>
                      </div>
                      <div className="grid grid-cols-2 gap-xl">
                        <FormField
                          control={form.control}
                          name="samplingPopulation"
                          render={({ field }) => {
                            return (
                              <FormItem>
                                <FormLabel>Jumlah ekor sampling</FormLabel>
                                <FormControl>
                                  <InputNumber
                                    id={field.name}
                                    value={field.value ?? "0"}
                                    onValueChange={(values) => {
                                      field.onChange(
                                        values.value === "" ? 0 : values.value,
                                      );
                                      setBwPerUnit(
                                        calculateBwPerUnit(
                                          form.watch("samplingWeight"),
                                          values.value,
                                        ),
                                      );
                                    }}
                                    isError={
                                      form.formState.errors.samplingPopulation
                                    }
                                    rightComponent={
                                      <span className="text-sm text-text-placeholder">
                                        Ekor
                                      </span>
                                    }
                                  />
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            );
                          }}
                        />
                        <FormField
                          control={form.control}
                          name="samplingWeight"
                          render={({ field }) => {
                            return (
                              <FormItem>
                                <FormLabel>Total bobot timbang</FormLabel>
                                <FormControl>
                                  <InputNumber
                                    id={field.name}
                                    value={field.value ?? "0"}
                                    onValueChange={(values) => {
                                      field.onChange(
                                        values.value === "" ? 0 : values.value,
                                      );
                                      setBwPerUnit(
                                        calculateBwPerUnit(
                                          values.value,
                                          form.watch("samplingPopulation"),
                                        ),
                                      );
                                    }}
                                    isError={
                                      form.formState.errors.samplingWeight
                                    }
                                    rightComponent={
                                      <span className="text-sm text-text-placeholder">
                                        g
                                      </span>
                                    }
                                  />
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            );
                          }}
                        />
                      </div>
                      <div className="flex items-center gap-5xl">
                        <p className="text-sm text-text-tertiary">
                          BW per ekor
                        </p>
                        <p className="text-text-primary">
                          {bwPerUnit.toFixed(2)}g
                        </p>
                      </div>
                    </div>
                    <div className="space-y-lg">
                      <div className="flex items-center gap-xs">
                        <IcClipboardPlus className="size-5 text-[#484B4F]" />
                        <p className="text-text-primary">
                          Pemberian pakan atau OVK
                        </p>
                      </div>
                      {fields.map((field, index) => {
                        const productType = form.watch(
                          `products.${index}.type`,
                        );
                        const productId = form.watch(
                          `products.${index}.productId`,
                        );
                        const isLast = index === fields.length - 1;

                        const product =
                          productType === "ovk"
                            ? previousProducts?.ovk?.find(
                                (item) => item.productId === productId,
                              )
                            : previousProducts?.pakan?.find(
                                (item) => item.productId === productId,
                              );

                        const maxStockPakan =
                          maxStocks.pakan.find(
                            (item) =>
                              item.id ===
                              form.watch(`products.${index}.productId`),
                          )?.stock / 50;

                        const maxStockOVK = maxStocks.ovk.find(
                          (item) =>
                            item.id ===
                            form.watch(`products.${index}.productId`),
                        )?.stock;

                        const maxStock =
                          productType === "pakan" ? maxStockPakan : maxStockOVK;

                        return (
                          <div
                            key={field.id}
                            className="grid w-full grid-cols-12 gap-xl"
                          >
                            <div className="col-span-4">
                              <FormField
                                control={form.control}
                                name={`products.${index}.type`}
                                render={({ field }) => (
                                  <FormItem>
                                    <FormLabel isRequire>
                                      Tipe {index + 1}
                                    </FormLabel>
                                    <Select
                                      onValueChange={(e) => {
                                        field.onChange(e);

                                        setExcludeProducts((prev) => {
                                          const filtered = prev.filter(
                                            (id) => id !== productId,
                                          );
                                          return filtered;
                                        });

                                        form.setValue(
                                          `products.${index}.productId`,
                                          "",
                                        );

                                        form.trigger();
                                      }}
                                      defaultValue={field.value}
                                    >
                                      <FormControl>
                                        <SelectTrigger>
                                          <SelectValue
                                            placeholder="Pilih tipe"
                                            value={field.value}
                                          />
                                        </SelectTrigger>
                                      </FormControl>
                                      <SelectContent>
                                        <SelectItem value="pakan">
                                          Pakan
                                        </SelectItem>
                                        <SelectItem value="ovk">OVK</SelectItem>
                                      </SelectContent>
                                    </Select>
                                    <FormMessage />
                                  </FormItem>
                                )}
                              />
                            </div>
                            <div className="relative col-span-8">
                              {index > 0 && (
                                <button
                                  onClick={() => {
                                    fields.length > 1 && remove(index);
                                    setExcludeProducts((prev) => {
                                      const filtered = prev.filter(
                                        (id) => id !== productId,
                                      );
                                      return filtered;
                                    });

                                    setMaxStocks((prev) => {
                                      if (productType === "pakan") {
                                        const filtered = prev.pakan.filter(
                                          (item) => item.id !== productId,
                                        );
                                        return {
                                          ...prev,
                                          pakan: [...filtered],
                                        };
                                      } else {
                                        const filtered = prev.ovk.filter(
                                          (item) => item.id !== productId,
                                        );
                                        return {
                                          ...prev,
                                          ovk: [...filtered],
                                        };
                                      }
                                    });
                                  }}
                                  className="absolute right-0 top-0 mt-1 text-fg-errorPrimary"
                                >
                                  <IcTrash className="size-4" />
                                </button>
                              )}
                              <FormField
                                control={form.control}
                                name={`products.${index}.productId`}
                                render={({ field }) => (
                                  <FormItem>
                                    <FormLabel className="mb-[1px] mt-[3px] flex items-center gap-1">
                                      Produk {index + 1}
                                      {productType === "pakan" && (
                                        <span className="text-destructive">
                                          *
                                        </span>
                                      )}
                                    </FormLabel>
                                    <FormControl>
                                      {productType === "pakan" ? (
                                        // TODO: PAKAN
                                        <CustomDropdown
                                          id={field.name}
                                          options={
                                            queryProducts?.data?.feedStocks ||
                                            []
                                          }
                                          disabled={
                                            isLoadingAdd || !productType
                                          }
                                          isError={
                                            form.formState.errors.products?.[
                                              index
                                            ]?.productId
                                          }
                                          superPlaceholder={
                                            product?.labels || field.value
                                              ? ""
                                              : "Pilih produk pakan"
                                          }
                                          placeholder={
                                            product?.labels ||
                                            "Pilih produk pakan"
                                          }
                                          inputStyle={cn(
                                            product?.labels || field.value
                                              ? "text-text-primary"
                                              : "text-text-placeholder",
                                          )}
                                          placeholderSearch="Cari nama produk"
                                          selectedOption={
                                            queryProducts?.data?.feedStocks?.find(
                                              (item) => item.id === field.value,
                                            ) || null
                                          }
                                          onSelect={(value) => {
                                            const previousValue = field.value;

                                            setExcludeProducts((prev) => {
                                              const filtered = prev.filter(
                                                (id) => id !== previousValue,
                                              );
                                              return [...filtered, value.id];
                                            });

                                            // set maxStock with id and the stock
                                            setMaxStocks((prev) => {
                                              const filtered =
                                                prev.pakan.filter(
                                                  (item) =>
                                                    item.id !== previousValue,
                                                );
                                              return {
                                                ...prev,
                                                pakan: [
                                                  ...filtered,
                                                  {
                                                    id: value.id,
                                                    stock: value.stock,
                                                  },
                                                ],
                                              };
                                            });

                                            field.onChange(value.id || null);
                                            form.trigger();
                                          }}
                                          content={(option) => (
                                            <div className="grid max-w-80 grid-cols-2 py-[10px]">
                                              <div className="flex flex-col items-start gap-1">
                                                <p className="line-clamp-2 text-sm text-text-secondary">
                                                  {option?.labels || "-"}
                                                </p>
                                                <p className="line-clamp-2 text-xs text-text-quarterary">
                                                  {option?.supplier || "-"}
                                                </p>
                                              </div>
                                              <div className="flex flex-col items-end gap-1">
                                                <BadgeStatus
                                                  type={option?.type?.id}
                                                />
                                                <p className="text-xs text-text-warningPrimary">
                                                  Sisa pakan{" "}
                                                  {formatNumber(
                                                    option?.stock / 50,
                                                  )}{" "}
                                                  Sak
                                                </p>
                                              </div>
                                            </div>
                                          )}
                                        />
                                      ) : (
                                        // TODO: OVK
                                        <CustomDropdown
                                          id={field.name}
                                          options={
                                            queryProducts?.data?.ovkStocks || []
                                          }
                                          disabled={
                                            isLoadingAdd || !productType
                                          }
                                          isError={
                                            form.formState.errors.products?.[
                                              index
                                            ]?.productId
                                          }
                                          superPlaceholder={
                                            product?.labels || field.value
                                              ? ""
                                              : "Pilih produk OVK"
                                          }
                                          placeholder={
                                            product?.labels ||
                                            "Pilih produk OVK"
                                          }
                                          inputStyle={cn(
                                            product?.labels || field.value
                                              ? "text-text-primary"
                                              : "text-text-placeholder",
                                          )}
                                          placeholderSearch="Cari nama produk"
                                          selectedOption={
                                            queryProducts?.data?.ovkStocks?.find(
                                              (item) => item.id === field.value,
                                            ) || null
                                          }
                                          onSelect={(value) => {
                                            const previousValue = field.value;

                                            setExcludeProducts((prev) => {
                                              const filtered = prev.filter(
                                                (id) => id !== previousValue,
                                              );
                                              return [...filtered, value.id];
                                            });

                                            // set maxStock with id and the stock
                                            setMaxStocks((prev) => {
                                              const filtered = prev.ovk.filter(
                                                (item) =>
                                                  item.id !== previousValue,
                                              );
                                              return {
                                                ...prev,
                                                ovk: [
                                                  ...filtered,
                                                  {
                                                    id: value.id,
                                                    stock: value.stock,
                                                  },
                                                ],
                                              };
                                            });

                                            field.onChange(value.id || null);
                                            form.trigger();
                                          }}
                                          content={(option) => (
                                            <div className="grid max-w-80 grid-cols-2 py-[10px]">
                                              <div className="flex flex-col items-start gap-1">
                                                <p className="line-clamp-2 text-sm text-text-secondary">
                                                  {option?.labels || "-"}
                                                </p>
                                                <p className="line-clamp-2 text-xs text-text-quarterary">
                                                  {option?.supplier || "-"}
                                                </p>
                                              </div>
                                              <div className="flex flex-col items-end gap-1">
                                                <p className="rounded-sm border border-border-primary px-[6px] text-xs font-medium text-text-secondary">
                                                  {option?.unit?.labels}
                                                </p>
                                                <p className="text-xs text-text-warningPrimary">
                                                  Sisa item{" "}
                                                  {formatNumber(option?.stock)}
                                                </p>
                                              </div>
                                            </div>
                                          )}
                                        />
                                      )}
                                    </FormControl>
                                    <FormMessage />
                                  </FormItem>
                                )}
                              />
                            </div>
                            <div className="col-span-12">
                              <FormField
                                control={form.control}
                                name={`products.${index}.qty`}
                                render={({ field }) => {
                                  return (
                                    <FormItem>
                                      <FormLabel className="flex items-center gap-1">
                                        Jumlah pemberian{" "}
                                        {productType === "pakan"
                                          ? "pakan"
                                          : "OVK"}
                                        <span className="text-destructive">
                                          *
                                        </span>
                                      </FormLabel>
                                      <FormControl>
                                        <Input
                                          {...field}
                                          onChange={(e) => {
                                            const value = e.target.value;

                                            if (
                                              parseInt(value) >
                                              (product?.stock
                                                ? product?.stock
                                                : maxStock)
                                            ) {
                                              form.setError(
                                                `products.${index}.qty`,
                                                {
                                                  type: "manual",
                                                  message: `Maksimum stok adalah ${product?.stock ? product?.stock : maxStock}`,
                                                },
                                              );
                                            } else {
                                              form.clearErrors(
                                                `products.${index}.qty`,
                                              );
                                            }
                                            field.onChange(value);
                                          }}
                                          type="number"
                                          placeholder="0"
                                          disabled={isLoadingAdd || !productId}
                                          max={
                                            product?.stock
                                              ? product?.stock
                                              : maxStock
                                          }
                                          rightComponent={
                                            <span className="text-sm text-text-placeholder">
                                              {productType === "pakan"
                                                ? "Sak"
                                                : "Item"}
                                            </span>
                                          }
                                        />
                                      </FormControl>
                                      <FormMessage />
                                      {(product?.stock
                                        ? product?.stock
                                        : maxStock) > 0 && (
                                        <p className="text-xs text-muted-foreground">
                                          Maks:{" "}
                                          {product?.stock
                                            ? product?.stock
                                            : maxStock}
                                        </p>
                                      )}
                                    </FormItem>
                                  );
                                }}
                              />
                            </div>

                            {!isLast && (
                              <div className="col-span-12">
                                <Separator />
                              </div>
                            )}
                          </div>
                        );
                      })}
                      <div className="col-span-12">
                        {form.formState.errors.products?.root?.message && (
                          <p className="mt-2 text-sm font-medium text-destructive">
                            {form.formState.errors.products.root.message}
                          </p>
                        )}
                        <Button
                          type="button"
                          variant="btnOutlinedGrey"
                          className="w-fit text-[#484B4F]"
                          onClick={() => {
                            append({ productId: "", type: "pakan", qty: 1 });
                          }}
                          disabled={queryProducts.data?.totalItems === 0}
                        >
                          <IcPlus className="h-4 w-4" />
                          Tambah produk
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                <SheetFooter className="absolute bottom-0 left-0 right-0 border-t p-3xl">
                  <div className="flex w-full items-center justify-end gap-lg">
                    {!isEdit && (
                      <Dialog
                        open={isAddPeriodWithNextDay}
                        onOpenChange={(open) => setIsAddPeriodWithNextDay(open)}
                      >
                        <Button
                          type="button"
                          variant="btnOutlinedGrey"
                          className="w-fit"
                          onClick={handleOpenDialogSubmitNextDay}
                          disabled={
                            mutateAdd.isPending ||
                            Object.keys(form.formState.errors).length > 0
                          }
                        >
                          Hari Berikutnya
                        </Button>
                        <DialogContent className="sm:max-w-[560px]">
                          <DialogHeader>
                            <DialogTitle>Konfirmasi simpan data</DialogTitle>
                            <DialogDescription>
                              Pastikan data yang anda masukkan sudah benar,
                              sebelum melanjutkan tindakan ini.
                            </DialogDescription>
                          </DialogHeader>
                          <div className="flex items-center justify-end gap-xl">
                            <Button
                              variant="btnOutlinedGrey"
                              className="w-fit"
                              disabled={mutateAdd.isPending}
                              onClick={() => setIsAddPeriodWithNextDay(false)}
                            >
                              Batal
                            </Button>
                            <Button
                              className="w-fit"
                              disabled={mutateAdd.isPending}
                              onClick={form.handleSubmit(onSubmitWithNextDay)}
                            >
                              Ya, lanjutkan
                              {mutateAdd.isPending && (
                                <AiOutlineLoading3Quarters className="size-4 animate-spin text-button-primaryFg" />
                              )}
                            </Button>
                          </div>
                        </DialogContent>
                      </Dialog>
                    )}

                    <Dialog
                      open={isAddPeriod}
                      onOpenChange={(open) => setIsAddPeriod(open)}
                    >
                      <Button
                        type="button"
                        className="w-fit"
                        onClick={handleOpenDialogSubmit}
                        disabled={
                          mutateAdd.isPending ||
                          Object.keys(form.formState.errors).length > 0
                        }
                      >
                        Simpan
                        {mutateAdd.isPending && (
                          <AiOutlineLoading3Quarters className="size-4 animate-spin text-button-primaryFg" />
                        )}
                      </Button>
                      <DialogContent className="sm:max-w-[560px]">
                        <DialogHeader>
                          <DialogTitle>
                            Konfirmasi {isEdit ? "ubah" : "simpan"} data
                          </DialogTitle>
                          <DialogDescription>
                            Pastikan data yang anda masukkan sudah benar,
                            sebelum melanjutkan tindakan ini.
                          </DialogDescription>
                        </DialogHeader>
                        <div className="flex items-center justify-end gap-xl">
                          <Button
                            variant="btnOutlinedGrey"
                            className="w-fit"
                            disabled={
                              mutateAdd.isPending || mutateEdit.isPending
                            }
                            onClick={() => setIsAddPeriod(false)}
                          >
                            Batal
                          </Button>
                          <Button
                            className="w-fit"
                            disabled={
                              mutateAdd.isPending || mutateEdit.isPending
                            }
                            onClick={form.handleSubmit(
                              isEdit ? onSubmitEdit : onSubmit,
                            )}
                          >
                            Ya, lanjutkan
                            {(mutateAdd.isPending || mutateEdit.isPending) && (
                              <AiOutlineLoading3Quarters className="size-4 animate-spin text-button-primaryFg" />
                            )}
                          </Button>
                        </div>
                      </DialogContent>
                    </Dialog>
                  </div>
                </SheetFooter>
              </form>
            </Form>
          </div>
        )}
      </SheetContent>
    </Sheet>
  );
};

export default SheetForm;
