import { useQuery } from "@tanstack/react-query";

import api from "../../api/configApi";

const useGetFeedsList = () => {
  const query = useQuery({
    queryKey: ["get-feeds-list"],
    queryFn: async () => {
      const response = await api.get("/v1/feeds/list");
      return response.data;
    },
  });

  return query;
};

export default useGetFeedsList;
