import * as React from "react";

function IcDownload(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={17}
      height={17}
      viewBox="0 0 17 17"
      fill="none"
      {...props}
    >
      <path
        d="M16 11v1c0 1.4 0 2.1-.273 2.635a2.5 2.5 0 01-1.092 1.092C14.1 16 13.4 16 12 16H5c-1.4 0-2.1 0-2.635-.273a2.5 2.5 0 01-1.093-1.092C1 14.1 1 13.4 1 12v-1m11.667-4.167L8.5 11m0 0L4.333 6.833M8.5 11V1"
        stroke="currentColor"
        strokeWidth={1.66667}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default IcDownload;
