import { z } from "zod";

export const formSchema = z.object({
  house: z.string().min(1, {
    message: "Nama kandang tidak boleh kosong.",
  }),
  idPeriode: z.string(),
  docInDate: z.date({
    message: "Tanggal tidak boleh kosong.",
  }),
  docInPopulation: z
    .number({
      message: "Populasi tidak boleh kosong.",
    })
    .min(1, {
      message: "Populasi tidak boleh kosong.",
    }),
});
