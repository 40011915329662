import * as React from "react";

function IcChevronSelectorVertical(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M6 12.167l4.167 4.166 4.166-4.166M6 7.167L10.167 3l4.166 4.167"
        stroke="currentColor"
        strokeWidth={1.66667}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default IcChevronSelectorVertical;
