import * as React from "react";

function IcFiles(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M11.667 1.892v3.442c0 .466 0 .7.091.878.08.157.208.284.364.364.179.091.412.091.879.091h3.442m-3.109 4.167H6.667m6.667 3.333H6.667M8.334 7.5H6.667m5-5.833H7.334c-1.4 0-2.1 0-2.635.272a2.5 2.5 0 00-1.093 1.093c-.272.535-.272 1.235-.272 2.635v8.667c0 1.4 0 2.1.272 2.635A2.5 2.5 0 004.7 18.06c.535.273 1.235.273 2.635.273h5.333c1.4 0 2.1 0 2.635-.273a2.5 2.5 0 001.093-1.092c.272-.535.272-1.235.272-2.635V6.667l-5-5z"
        stroke="currentColor"
        strokeWidth={1.66667}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default IcFiles;
