import React, { useEffect, useState } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { format } from "date-fns";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

import LoadingAnimation from "../../../../../../atoms/LoadingAnimation";
import { ROUTES_PATH } from "../../../../../../shared/routes";
import { useGetMasterPeriodListOnly } from "../../../../../../useCases/MasterPeriod";
import {
  useEditSettingDOC,
  useGetDetailSettingDOC,
} from "../../../../../../useCases/Settings/DOC";
import { formSchema } from "./config";
import { DetailSection, FormSection } from "./section";

const SapronakEditSettingDOC = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.auth);

  const [selectedData, setSelectedData] = useState({
    dif1: null,
    dif2: null,
    dif3: null,
    ip1: null,
    ip2: null,
    ip3: null,
    jaminan: null,
    nilai_jaminan: null,
    idPeriode: null,
  });

  const queryDetail = useGetDetailSettingDOC(id);
  const queryHouses = useGetMasterPeriodListOnly({
    unit: user?.unit,
  });
  const selectedHouse = queryHouses.data?.contents?.find(
    (item) => item?.house?.id === queryDetail.data?.rearingPeriod?.house?.id,
  );
  const mutate = useEditSettingDOC(id);
  const isLoading = queryDetail.isLoading || queryHouses.isLoading;

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      house: "",
      idPeriode: "",
      docInDate: "",
      docInPopulation: "",
    },
  });

  useEffect(() => {
    if (queryDetail.data && queryHouses.data) {
      const detailData = queryDetail.data;

      form.setValue("house", detailData?.rearingPeriod?.house?.id);
      form.setValue("idPeriode", selectedHouse?.analyticAccount);
      form.setValue(
        "docInDate",
        queryDetail.data?.dateSetting
          ? new Date(queryDetail.data?.dateSetting)
          : new Date(),
      );
      form.setValue("docInPopulation", queryDetail.data?.qtySetting);

      setSelectedData({
        dif1: selectedHouse?.house?.diffFcrHistoryOne?.toString(),
        dif2: selectedHouse?.house?.diffFcrHistoryTwo?.toString(),
        dif3: selectedHouse?.house?.diffFcrHistoryThree?.toString(),
        ip1: selectedHouse?.house?.ipHistoryOne?.toString(),
        ip2: selectedHouse?.house?.ipHistoryTwo?.toString(),
        ip3: selectedHouse?.house?.ipHistoryThree?.toString(),
        jaminan: selectedHouse?.house?.guaranteeType?.id,
        nilai_jaminan: selectedHouse?.house?.guaranteeAmount,
        idPeriode: selectedHouse?.house?.id,
      });
    }
  }, [queryDetail.data, form, queryHouses.data, selectedHouse]);

  const handleOnSubmit = (values) => {
    const payload = {
      rearingPeriod: { id: selectedData?.idPeriode },
      dateSetting: format(values.docInDate, "yyyy-MM-dd"),
      qtySetting: values.docInPopulation,
    };

    mutate.mutate(payload, {
      onSuccess: () => {
        navigate(ROUTES_PATH.manajemenSapronak);
      },
    });
  };

  return (
    <div className="grid grid-cols-12 gap-2xl p-4">
      {isLoading ? (
        <div className="col-span-12 flex h-[85dvh] w-full items-center justify-center">
          <LoadingAnimation />
        </div>
      ) : (
        <>
          <FormSection
            form={form}
            handleOnSubmit={handleOnSubmit}
            queryHouses={queryHouses}
            setSelectedData={setSelectedData}
            mutate={mutate}
          />

          <DetailSection selectedData={selectedData} />
        </>
      )}
    </div>
  );
};

export default SapronakEditSettingDOC;
