/* eslint-disable no-sparse-arrays */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react";
import { FiFilter } from "react-icons/fi";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Popover,
  PopoverContent,
  PopoverHandler,
  Tooltip,
  Typography,
  IconButton,
} from "@material-tailwind/react";
import Input from "../../../../atoms/Input";
import {
  ChevronUpDownIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { Button } from "../../../../atoms/Button";
import { useState } from "react";
import api from "../../../../api/configApi";
import moment from "moment";
import { useNavigate, Link } from "react-router-dom";
import Pagination from "../../../../atoms/Pagination";
import AddPeriod from "../../../../molecules/periodeBudidaya/AddPeriodeBudidaya";
import AlertNotification from "../../../../atoms/AlertNotification";
import { FaCheckCircle } from "react-icons/fa";
import { ReactComponent as EmptyIcon } from "../../../../assets/icons/emptyIconn.svg";
import LoadingAnimation from "../../../../atoms/LoadingAnimation";
import DropdownWithSearchInput from "../../../../atoms/DropdownWithSearchInput";
import { DropdownSingleWithSearch } from "../../../../atoms/DropdownSingleWithSearch";
import InputNumber from "../../../../atoms/NumberFormat";
import * as Sentry from "@sentry/react";
import Cookies from "js-cookie";
import ModalEditPeriod from "../../../../molecules/periodeBudidaya/ModalEditPeriode";
import { ButtonIcon } from "../../../../atoms/ButtonIcon";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as EditIcon } from "../../../../assets/icons/edit_icon.svg";
import { ReactComponent as DetailIcon } from "../../../../assets/icons/detail-icon-black.svg";
import { ReactComponent as SettingPeriod } from "../../../../assets/icons/setting-kandang-perioder.svg";
import { ReactComponent as Download } from "../../../../assets/icons/download_icon_black.svg";
import { ReactComponent as Alert } from "../../../../assets/icons/alert-table-rearingperiod.svg";
import { ReactComponent as BurgerDotIcon } from "../../../../assets/icons/burgers-dot.svg";
import {
  setCheckedProvinces,
  setLastLabelProvince,
  setCheckedProvincesString,
  setCheckedCities,
  setLastLabelCities,
  setCheckedCitiesString,
  setCheckedRegions,
  setLastLabelRegion,
  setCheckedRegionsString,
  setSelectedUnit,
  setSelectedUnitId,
  setInitialCapacity,
  setFinalCapacity,
  setImplementFilter,
  setReloadFilter,
  setCountFilter,
  resetFilters,
  setSelectedPpl,
  setSelectPpllabels
} from "../../../../molecules/features/filterRearingPeriodSlice";
import { persistor } from "../../../../app/store";

const ListPeriodeBerjalan = () => {
  const dispatch = useDispatch();
  const {
    checkedProvinces,
    lastLabelProvince,
    checkedProvincesString,
    checkedCities,
    lastLabelCities,
    checkedCitiesString,
    checkedRegions,
    lastLabelRegion,
    checkedRegionsString,
    selectedUnit,
    selectedUnitId,
    initialCapacity,
    finalCapacity,
    implementFilter,
    reloadFilter,
    countFilter,
    selectedPpl,
  } = useSelector((state) => state.filterRearingPeriod);
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  let currentPageCookies = parseInt(
    Cookies.get("current-page-periodebudidaya")
  );
  const [currentPage, setCurrentPage] = useState(
    currentPageCookies ? currentPageCookies : 1
  );
  const [responseList, setResponseList] = useState(null);
  const [sortBy, setSortBy] = useState("docInDate");
  const [descending, setDescending] = useState(true);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const sortableColumns = [
    "house.name",
    "analyticAccount",
    "house.unit",
    "sequence",
    "docInDate",
    "docInPopulation",
    "",
  ];
  const [getId, setGetId] = useState("");

  const [totalPage, setTotalPage] = useState(1);
  const [openAddPeriod, setAddOpenPeriod] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState([0]);
  const [selectedCity, setSelectedCity] = useState([0]);
  const [search, setSearch] = useState("");
  const [province, setProvince] = useState([]);
  const [city, setCity] = useState([]);
  const [reload, setReload] = useState(false);
  const alertStop = JSON.parse(
    localStorage.getItem("responseSuccessStopPeriod")
  );
  const [notifStop, setNotifStop] = useState(alertStop);

  const alert = JSON.parse(localStorage.getItem("response success"));
  const [notif, setNotif] = useState(alert);
  const [startFetch, setStartFetch] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [searchProvince, setSearchProvince] = useState("");
  const [searchCity, setSearchCity] = useState("");
  const [validationError, setValidationError] = useState("");
  const [isFetchError, setIsFetchError] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [debouncedSearch, setDebouncedSearch] = useState(search, 400);
  const [listMasterUnits, setListMasterUnits] = useState([]);
  const [listMenuPpl, setListMenuPpl] = useState([])
  const [searchMasterUnit, setSearchMasterUnit] = useState("");
  const [regions, setRegions] = useState([]);
  const [searchRegions, setSearchRegions] = useState([]);
  const [activePopupId, setActivePopupId] = useState(null);
  const [isLoadingDownload, setIsLoadingDownload] = useState(false);
  const showChoiceRef = useRef(null);
  const [isDownloadSuccess, setIsDownloadSuccess] = useState(false);
  const [isDownloadError, setIsDownloadError] = useState(false);
  const [getHouseId, setGetHouseId] = useState(null)

  Cookies.set("current-page-periodebudidaya", currentPage);

  const TABLE_HEAD = [
    "Nama Kandang",
    "ID Analytic",
    "Unit",
    "PPL",
    "Periode",
    "Tanggal DOC-in",
    "Jml populasi(Ekor)",
    "Vendor",
    "Umur(Hari)",
    "Input data terakhir",
    <>
      <div class="p-0 m-0 grid grid-cols-12 w-[1800px] max-h-40">
        <div class="col-span-2 text-center px-lg py-sm border-t-0 border-l border-border-secondary">
          Deplesi
        </div>
        <div class="col-span-4 text-center px-lg py-sm border-t-0 border-l border-border-secondary">
          Feed intake
        </div>
        <div class="col-span-3 text-center px-lg py-sm border-t-0 border-l border-border-secondary">
          BW
        </div>
        <div class="col-span-3 text-center px-lg py-sm border-t-0 border-x border-border-secondary">
          FCR
        </div>
        <div className="col-start-1 col-end-1 p-0 m-0">
          <Typography className="text-text-quarterary border-t text-sm font-semibold leading-tight border-border-secondary text-right px-lg py-sm bg-neutral-100 border-l">
            Ekor
          </Typography>
        </div>
        <div className="col-start-2 col-end-2 p-0 m-0 min-w-[100px]">
          <Typography className="text-text-quarterary border-t text-sm font-semibold leading-tight border-border-secondary text-right px-lg py-sm bg-neutral-100 border-l">
            %
          </Typography>
        </div>
        <div className="col-start-3 col-end-3 p-0 m-0 min-w-[100px]">
          <Typography className="text-text-quarterary border-t text-sm font-semibold leading-tight border-border-secondary text-right px-lg py-sm bg-neutral-100 border-l">
            Zak
          </Typography>
        </div>
        <div className="col-start-4 col-end-4 p-0 m-0 min-w-[100px]">
          <Typography className="text-text-quarterary border-t text-sm font-semibold leading-tight border-border-secondary text-right px-lg py-sm bg-neutral-100 border-l">
            Act
          </Typography>
        </div>
        <div className="col-start-5 col-end-5 p-0 m-0 min-w-[100px]">
          <Typography className="text-text-quarterary border-t text-sm font-semibold leading-tight border-border-secondary text-right px-lg py-sm bg-neutral-100 border-l">
            Std
          </Typography>
        </div>
        <div className="col-start-6 col-end-6 p-0 m-0 min-w-[100px]">
          <Typography className="text-text-quarterary border-t text-sm font-semibold leading-tight border-border-secondary text-right px-lg py-sm bg-neutral-100 border-l">
            %
          </Typography>
        </div>
        <div className="col-start-7 col-end-7 p-0 m-0 min-w-[100px]">
          <Typography className="text-text-quarterary border-t text-sm font-semibold leading-tight border-border-secondary text-right px-lg py-sm bg-neutral-100 border-l">
            Act
          </Typography>
        </div>
        <div className="col-start-8 col-end-8 p-0 m-0 min-w-[100px]">
          <Typography className="text-text-quarterary border-t text-sm font-semibold leading-tight border-border-secondary text-right px-lg py-sm bg-neutral-100 border-l">
            Std
          </Typography>
        </div>
        <div className="col-start-9 col-end-9 p-0 m-0 min-w-[100px]">
          <Typography className="text-text-quarterary border-t text-sm font-semibold leading-tight border-border-secondary text-right px-lg py-sm bg-neutral-100 border-l">
            %
          </Typography>
        </div>
        <div className="col-start-10 col-end-10 p-0 m-0 min-w-[100px]">
          <Typography className="text-text-quarterary border-t text-sm font-semibold leading-tight border-border-secondary text-right px-lg py-sm bg-neutral-100 border-l">
            Act
          </Typography>
        </div>
        <div className="col-start-11 col-end-11 p-0 m-0">
          <Typography className="text-text-quarterary border-t text-sm font-semibold leading-tight border-border-secondary text-right px-lg py-sm bg-neutral-100 border-l">
            Std
          </Typography>
        </div>
        <div className="col-start-12 col-end-12 p-0 m-0">
          <Typography className="text-text-quarterary border-t text-sm font-semibold leading-tight border-border-secondary text-right px-lg py-sm bg-neutral-100 border-x">
            Diff
          </Typography>
        </div>
      </div>
    </>,
    "IP",
    "",
  ];

  const dataPopupTable = [
    {
      id: 1,
      label: "Ubah",
      icon: <EditIcon className="h-5 w-5" />,
    },

    {
      id: 2,
      label: "Detail",
      icon: <DetailIcon className="h-5 w-5" />,
    },

    {
      id: 3,
      label: "Atur Kandang",
      icon: <SettingPeriod />,
    },
  ];

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(search);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [search]);

  useEffect(() => {
    const fetchingList = async () => {
      setIsLoading(true);
      try {
        const params = {
          currentPage: debouncedSearch?.length > 0 ? null : currentPage - 1,
          sortBy: sortBy,
          descending: descending,
          itemPerPage: debouncedSearch?.length > 0 ? null : 10,
          search: debouncedSearch,
          end: 0,
        };

        if (implementFilter === true) {
          params.PPL = selectedPpl ? selectedPpl?.labels?.replace(/\+/g, ' ').replace(/\.+/g, ''): "";
          params.province = checkedProvincesString ? checkedProvincesString : "";
          params.city = checkedCitiesString ? checkedCitiesString : "";
          params.unit = selectedUnitId ? selectedUnitId : "";
          params.region = checkedRegionsString ? checkedRegionsString : "";
          

          if (initialCapacity !== null && initialCapacity.length > 0) {
            params.initialCapacity = parseInt(initialCapacity?.replace(".", ""));
          } else {
            delete params.initialCapacity;
          }

          if (finalCapacity !== null && finalCapacity.length > 0) {
            params.finalCapacity = parseInt(finalCapacity?.replace(".", ""));
          } else {
            delete params.finalCapacity;
          }
        }

        const response = await api.get("/v1/rearingPeriods", { params });

        setResponseList(response?.data?.contents);
        setTotalPage(response?.data?.totalPages);
        if (!response) {
          setIsOpenFilter(false);
        }
        setIsFetchError(false);
      } catch (error) {
        Sentry.captureException(error);
        setIsFetchError(true);
      } finally {
        setIsLoading(false);
      }
    };

    if (debouncedSearch || !debouncedSearch) {
      fetchingList();
    }
  }, [
    debouncedSearch,
    currentPage,
    sortBy,
    descending,
    implementFilter,
    reloadFilter,
  ]);

  useEffect(() => {
    const dataFetchDropdownProvince = async () => {
      try {
        const responseRegionDropdown = await api.get(
          `/v1/regions/id/province/dropdown`,
          {
            params: {
              search: searchProvince,
            },
          }
        );
        setProvince(responseRegionDropdown?.data?.contents);
      } catch (error) {
        Sentry.captureException(error);
      }
    };

    dataFetchDropdownProvince();
  }, [searchProvince]);

  useEffect(() => {
    const dataPPL = async () => {
      try {
        const responsePPL = await api.get(
          '/v1/users/dropdown',
          {
            params: {
              role: "PPL"
            }
          }
        )
        setListMenuPpl(responsePPL.data.contents)
      } catch (error) {
        console.log("error", error)
      }
    }

    dataPPL()
  }, [])

  useEffect(() => {
    const fetchRegion = async () => {
      try {
        const response = await api.get("/v1/regions/dropdown", {
          params: {
            search: searchRegions,
          },
        });
        setRegions(response?.data?.contents);
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };

    fetchRegion();
  }, [searchRegions]);

  useEffect(() => {
    const fetchMasterUnit = async () => {
      try {
        const params = {
          search: searchMasterUnit,
          descending: false,
        };
        const responseList = await api.get("/v1/units/dropdown", { params });
        setListMasterUnits(responseList?.data?.contents);
      } catch (error) {
        console.log(error.message);
      }
    };

    fetchMasterUnit();
  }, [searchMasterUnit]);

  useEffect(() => {
    setTimeout(() => {
      setNotifStop(false);
      localStorage.clear("responseSuccessStopPeriod");
    }, 3000);
  }, [notifStop]);

  const handleChangePage = (target) => {
    setCurrentPage(target);
  };

  const handleSort = (column) => {
    if (sortableColumns.includes(column)) {
      if (sortBy === column) {
        setDescending(!descending);
      } else {
        setSortBy(column);
        setDescending(false);
      }
    }
  };

  const handleTerapkanFilter = () => {
    setIsOpenFilter(false);
    dispatch(setCountFilter(0));
    let count = 0;

    if (implementFilter === true) {
      dispatch(setReloadFilter(true));
    }

    if (checkedProvincesString !== "") {
      count = count + 1;
    }

    if (checkedCitiesString !== "") {
      count = count + 1;
    }

    if (checkedRegionsString !== "") {
      count = count + 1;
    }

    if (selectedUnit) {
      count = count + 1;
    }

    if (selectedPpl) {
      count = count + 1
    }

    if (initialCapacity?.length > 0 || finalCapacity?.length > 0) {
      count = count + 1;
    }

    dispatch(setCountFilter(count));
    dispatch(setImplementFilter(true));
  };

  const handleResetFilter = () => {
    setIsLoading(true);
    try {
      dispatch(resetFilters());
      persistor.flush();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (startFetch) {
      const fetchData = async () => {
        const data = await api.get(`/v1/regions/id/cities/dropdown`, {
          params: {
            search: searchCity,
            province: checkedProvincesString,
          },
        });
        setCity(data?.data);
      };
      fetchData();
    }
  }, [startFetch, checkedProvincesString, searchCity]);

  useEffect(() => {
    setTimeout(() => {
      setNotif(false);
      localStorage.clear("response success");
    }, 4000);
  }, [notif]);

  useEffect(() => {
    if (reloadFilter === true) {
      setTimeout(() => {
        dispatch(setReloadFilter(false));
      }, 1000);
    }
  }, [reloadFilter, dispatch]);

  const handleFilterCity = (val) => {
    setSelectedCity(val);
  };

  const handleChangeUnit = (select) => {
    dispatch(setSelectedUnitId(select.id));
    dispatch(setSelectedUnit(select));
  };

  const handleSelectPPL = (select) => {
    const selectedPpl = select;
    dispatch(setSelectPpllabels(selectedPpl.labels))
    dispatch(setSelectedPpl(selectedPpl))
  }

  const handleFilterRegion = (val) => {
    setSelectedRegion(val);
    setStartFetch(true);
  };

  useEffect(() => {
    const handleClickOutside = (event, id) => {
      if (
        showChoiceRef.current &&
        !showChoiceRef.current.contains(event.target)
      ) {
        setActivePopupId(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showChoiceRef]);

  const handleTogglePopup = (id, hosueId) => {
    if (activePopupId === id) {
      setActivePopupId(null);
    } else {
      setActivePopupId(id);
    }

    setGetHouseId(hosueId)
    setGetId(id);
  };

  const handleClickRoute = (id) => {
    if (id === 1) {
      setOpenEdit(!openEdit);
      setActivePopupId(id);
    }

    if (id === 2) {
      navigate(`/periode-budidaya/periode-berjalan/${getId}/dataharian`);
    }

    if (id === 3) {
      navigate(`/kandang/${getHouseId}`);
    }
  };

  const handleExportLaporan = async () => {
    if (user?.level !== "UNIT" && selectedUnitId.length === 0) {
      setIsDownloadError(true);
      setTimeout(() => setIsDownloadError(false), 3000);
      return;
    }

    setIsLoadingDownload(true);
    try {
      let stringId =
        user?.level === "UNIT"
          ? null
          : selectedUnitId.length > 0
          ? selectedUnitId
          : null;
      let units = stringId === null ? "" : "?unit=" + stringId;

      const response = await api.get(
        `/v1/rearingPeriods/current/toXLSX` + units,
        {
          responseType: "blob",
        }
      );

      const contentDisposition = response.headers["content-disposition"];
      let fileName = "current_rearing_periods.xlsx";

      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="?([^"]+)"?/);
        if (fileNameMatch && fileNameMatch[1]) {
          fileName = fileNameMatch[1];
        }
      }

      const url = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);

      document.body.appendChild(link);

      link.click();

      window.URL.revokeObjectURL(url);
      setIsDownloadSuccess(true);
      setTimeout(() => setIsDownloadSuccess(false), 3000);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingDownload(false);
    }
  };

  return (
    <>
      <div className={`flex w-full h-auto overflow-hidden z-0 `}>
        <div className="flex flex-col flex-1 overflow-hidden">
          <div className="p-4">
            <Card className="w-full h-auto border border-border-secondary shadow-none p-0 m-0">
              <CardHeader
                floated={false}
                shadow={false}
                className="p-2 m-0 border-none w-full"
              >
                <Typography
                  variant="h2"
                  className="text-xl font-bold p-xl text-text-primary"
                >
                  Periode Berjalan
                </Typography>

                <div className="grid grid-cols-2">
                  <div className="flex justify-start mb-4 gap-2">
                    <div className="text-left pl-4">
                      <Input
                        type="text"
                        id="search"
                        placeholder="Cari pemilik kandang, nama kandang"
                        className="min-w-[350px] border border-border-primary"
                        size="medium"
                        componentLeft={
                          <MagnifyingGlassIcon className="h-5 w-5" />
                        }
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </div>
                    <div className="ml-2">
                      {user?.level !== "UNIT" && (
                        <Popover
                          placement="bottom"
                          open={isOpenFilter}
                          handler={() => setIsOpenFilter(!isOpenFilter)}
                        >
                          <PopoverHandler className="cursor-pointer">
                            <div>
                              <button
                                className={`${
                                  countFilter > 0
                                    ? "border-button-infoBorder"
                                    : "border-border-primary"
                                } p-5 h-11 px-xl py-2.5 justify-center items-center gap-md inline-flex text-base font-medium leading-normal rounded-md bg-white hover:bg-neutral-50 border text-text-secondary focus:shadow-sm focus:shadow-gray-300 hover:border-border-primary`}
                              >
                                <div
                                  className={`justify-center min-w-6 w-full items-center gap-2.5 flex cursor-pointer ${
                                    countFilter > 0
                                      ? "text-fg-infoPrimary"
                                      : "text-text-secondary"
                                  }`}
                                >
                                  <div className="w-auto font-medium">
                                    Filter
                                  </div>
                                </div>
                                <div className="w-6 h-6 relative flex items-center">
                                  <FiFilter
                                    className={`h-5 w-5 ${
                                      countFilter > 0 && "text-fg-infoPrimary"
                                    }`}
                                  />
                                </div>
                                {countFilter > 0 && (
                                  <div className="w-6 h-6 px-3 py-1 bg-fg-infoPrimary rounded-full justify-center items-center gap-1 inline-flex">
                                    <div className="text-white text-xs font-medium leading-[18px]">
                                      {countFilter}
                                    </div>
                                  </div>
                                )}
                              </button>
                            </div>
                          </PopoverHandler>

                          <PopoverContent className="top-[205px] left-[500px] z-50 w-[576px]">
                            {user?.level === "ALL_DATA" && (
                              <>
                                <Typography
                                  variant="small"
                                  className="flex justify-between font-normal text-xs leading-none text-neutral-500 w-full min-h-full"
                                >
                                  Lokasi
                                </Typography>
                                <Typography className="flex justify-between font-medium text-xs text-text-primary leading-none w-full min-h-full mt-6">
                                  Provinsi
                                </Typography>

                                <div className="mt-2">
                                  <DropdownWithSearchInput
                                    placeholder="Semua Provinsi"
                                    placeholderInputSearch="Cari Provinsi"
                                    options={province}
                                    onSelect={handleFilterRegion}
                                    positionClassName="top-[335px] left-[520px]"
                                    search={searchProvince}
                                    setSearch={setSearchProvince}
                                    onSelectString={(str) =>
                                      dispatch(setCheckedProvincesString(str))
                                    }
                                    lastLabel={lastLabelProvince}
                                    setLastLabel={(label) =>
                                      dispatch(setLastLabelProvince(label))
                                    }
                                    selectCheckBox={checkedProvinces}
                                    setSelectCheckbox={(provinces) =>
                                      dispatch(setCheckedProvinces(provinces))
                                    }
                                  />
                                </div>

                                <Typography className="flex justify-between text-xs font-medium text-text-primary leading-none w-full min-h-full mt-6">
                                  Kabupaten/Kota
                                </Typography>

                                <div className="mt-2">
                                  <DropdownWithSearchInput
                                    placeholder="Semua Kab/Kota"
                                    placeholderInputSearch="Cari Kab/Kota"
                                    options={city?.contents}
                                    onSelect={handleFilterCity}
                                    search={searchCity}
                                    setSearch={setSearchCity}
                                    positionClassName="top-[425px] left-[520px]"
                                    onSelectString={(str) =>
                                      dispatch(setCheckedCitiesString(str))
                                    }
                                    lastLabel={lastLabelCities}
                                    setLastLabel={(label) =>
                                      dispatch(setLastLabelCities(label))
                                    }
                                    selectCheckBox={checkedCities}
                                    setSelectCheckbox={(cities) =>
                                      dispatch(setCheckedCities(cities))
                                    }
                                  />
                                </div>
                                <Typography className="flex justify-between font-medium text-xs text-text-primary leading-[18px] w-full min-h-full mt-6">
                                  Region
                                </Typography>
                                <div className="mt-2">
                                  <DropdownWithSearchInput
                                    placeholder="Pilih region"
                                    placeholderInputSearch="Cari region"
                                    options={regions}
                                    onSelect={handleFilterRegion}
                                    search={searchRegions}
                                    setSearch={setSearchRegions}
                                    onSelectString={(str) =>
                                      dispatch(setCheckedRegionsString(str))
                                    }
                                    setLastLabel={(label) =>
                                      dispatch(setLastLabelRegion(label))
                                    }
                                    lastLabel={lastLabelRegion}
                                    positionClassName="top-[335px] left-[520px]"
                                    selectCheckBox={checkedRegions}
                                    setSelectCheckbox={(regions) =>
                                      dispatch(setCheckedRegions(regions))
                                    }
                                  />
                                </div>
                              </>
                            )}

                            {user?.level === "ALL_DATA" && (
                              <>
                                <Typography className="flex justify-between font-medium text-xs text-text-primary leading-[18px] w-full min-h-full mt-6">
                                  PPL
                                </Typography>
                                
                                <div className="mt-2">
                                  <DropdownSingleWithSearch
                                    onSelect={handleSelectPPL}
                                    placeholder="Cari Nama PPL"
                                    options={listMenuPpl}
                                    selectedOption={selectedPpl}
                                  />
                                </div>
                              </>
                            )}

                            {(user?.level === "ALL_DATA" ||
                              user?.level === "MITRA" ||
                              user?.level === "REGION") && (
                              <>
                                <Typography className="flex justify-between font-medium text-xs text-text-primary leading-[18px] w-full min-h-full mt-6">
                                  Unit
                                </Typography>

                                <div className="mt-2">
                                  {/* <DropdownWithSearchInput
                                    placeholder="Semua unit"
                                    placeholderInputSearch="Cari unit"
                                    options={listMasterUnits}
                                    onSelect={handleFilterMasterUnit}
                                    search={searchMasterUnit}
                                    setSearch={setSearchMasterUnit}
                                    onSelectString={(str) =>
                                      dispatch(setCheckedUnitsString(str))
                                    }
                                    setLastLabel={(label) =>
                                      dispatch(setLastLabelUnit(label))
                                    }
                                    lastLabel={lastLabelUnit}
                                    positionClassName="top-[515px] left-[520px]"
                                    selectCheckBox={checkedUnits}
                                    setSelectCheckbox={(units) =>
                                      dispatch(setCheckedUnits(units))
                                    }
                                  /> */}
                                  <DropdownSingleWithSearch
                                    onSelect={handleChangeUnit}
                                    placeholder="Pilih Unit"
                                    options={listMasterUnits}
                                    selectedOption={selectedUnit}
                                  />
                                </div>
                              </>
                            )}
                            {(user?.level === "ALL_DATA" ||
                              user?.level === "REGION") && (
                              <>
                                <Typography className="flex justify-between font-medium text-xs text-text-primary leading-[18px] w-full min-h-full mt-6">
                                  Kapasitas kandang
                                </Typography>

                                <div className="mt-2 flex justify-between items-center">
                                  <div className="relative w-full">
                                    <InputNumber
                                      type="text"
                                      id="capasity"
                                      placeholder="Kapasitas minimal"
                                      className="max-w-full w-[200px]"
                                      value={initialCapacity}
                                      size="medium"
                                      onChange={(e) =>
                                        dispatch(
                                          setInitialCapacity(e.target.value)
                                        )
                                      }
                                      placeholderSize={"medium"}
                                      componentRight={
                                        <Typography
                                          variant="medium"
                                          className="font-medium text-neutral-500"
                                        >
                                          ekor
                                        </Typography>
                                      }
                                    />
                                    {validationError && (
                                      <p className="text-red-500 text-sm mt-2">
                                        {validationError}
                                      </p>
                                    )}
                                  </div>

                                  <hr className="mx-4 px-2 border-2 rounded-md border-neutral-500" />
                                  <div className="relative w-full">
                                    <InputNumber
                                      type="text"
                                      id="capasity"
                                      placeholder="Kapasitas maksimal"
                                      className="max-w-full"
                                      value={finalCapacity}
                                      size="medium"
                                      onChange={(e) =>
                                        dispatch(
                                          setFinalCapacity(e.target.value)
                                        )
                                      }
                                      placeholderSize={"medium"}
                                      componentRight={
                                        <Typography
                                          variant="medium"
                                          className="font-medium text-neutral-500"
                                        >
                                          ekor
                                        </Typography>
                                      }
                                    />
                                    {validationError && (
                                      <p className="text-red-500 text-sm mt-2">
                                        {validationError}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </>
                            )}

                            <div className="border-t border-gray-300 my-4" />

                            <div className="divide-y flex w-full gap-8 justify-between mt-4">
                              <div onClick={handleResetFilter}>
                                <Typography className="flex justify-between items-center text-sm text-text-primary font-medium leading-tight w-full min-h-full cursor-pointer">
                                  Atur Ulang
                                </Typography>
                              </div>

                              <Button
                                size={"small"}
                                label="Terapkan"
                                className="cursor-pointer"
                                onClick={handleTerapkanFilter}
                              />
                            </div>
                          </PopoverContent>
                        </Popover>
                      )}
                    </div>
                  </div>
                  <div className="text-center flex justify-end mr-[30px] gap-4">
                    <Button
                      label="Download"
                      size="medium"
                      type={
                        isLoadingDownload
                          ? "btnPrimaryDisabled"
                          : user?.level === "UNIT" || user?.level === "ALL_DATA"
                          ? "btnSecondary"
                          : "btnPrimary"
                      }
                      componentRight={
                        isLoadingDownload ? (
                          <svg
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            xmlns="http://www.w3.org/2000/svg"
                            className="animate-spin fill-neutral-400"
                          >
                            <path
                              d="M10.72,19.9a8,8,0,0,1-6.5-9.79A7.77,7.77,0,0,1,10.4,4.16a8,8,0,0,1,9.49,6.52A1.54,1.54,0,0,0,21.38,12h.13a1.37,1.37,0,0,0,1.38-1.54,11,11,0,1,0-12.7,12.39A1.54,1.54,0,0,0,12,21.34h0A1.47,1.47,0,0,0,10.72,19.9Z"
                              className="spinner_6kVp"
                            />
                          </svg>
                        ) : (
                          <Download />
                        )
                      }
                      onClick={handleExportLaporan}
                      disabled={isLoadingDownload}
                    />

                    {(user?.level === "ALL_DATA" || user?.level === "UNIT") && (
                      <Button
                        label="Mulai Periode"
                        size="medium"
                        type="btnPrimary"
                        onClick={() => setAddOpenPeriod(!openAddPeriod)}
                      />
                    )}
                  </div>
                </div>
              </CardHeader>

              {responseList?.length === 0 || isFetchError ? (
                <div className=" flex justify-center items-center h-screen">
                  <div>
                    <div className="flex justify-center">
                      <EmptyIcon className="ml-[30px]" />
                    </div>
                    <Typography
                      variant="h2"
                      className="text-xl font-semibold p-4 text-center text-neutral-500"
                    >
                      Data tidak ditemukan
                    </Typography>

                    <Typography
                      variant="medium"
                      className="flex justify-between font-normal leading-none text-neutral-500 w-full min-h-full"
                    >
                      Data yang Anda cari tidak ditemukan. Coba kata kunci lain
                    </Typography>
                  </div>
                </div>
              ) : (
                <>
                  <CardBody className="p-0 overflow-scroll max-w-full max-h-[960px] z-10 scrollbar-hide">
                    {isLoading ? (
                      <div className="flex justify-center items-center h-screen">
                        <LoadingAnimation />
                      </div>
                    ) : (
                      <>
                        <table className="table-auto w-full min-w-max text-left mt-4 border-separate border-spacing-0">
                          <thead className="border-y border-border-secondary bg-neutral-50">
                            <tr>
                              {TABLE_HEAD?.map((head, index) => (
                                <th
                                  key={index}
                                  className={`border-y border-border-secondary bg-neutral-50 p-0 h-[40px] max-w-[200px] ${
                                    index === TABLE_HEAD.length - 1
                                      ? "max-w-[90px] sticky right-0 ml-4 z-10 p-0 shadow-[rgba(0,0,10,0.2)_-3px_0_12px_-5px] border-l"
                                      : index === 0
                                      ? "max-w-[90px] sticky left-0 ml-4 z-10 p-0 shadow-[rgba(0,0,10,0.2)_-3px_0_12px_-5px] border-r"
                                      : ""
                                  }`}
                                >
                                  <Typography
                                    className={`flex ${index !== 10 ? "justify-start" : "justify-end"} ${
                                      index !== 10 && "px-lg"
                                    } font-semibold leading-normal text-text-quarterary text-sm`}
                                  >
                                    {head}{" "}
                                    {index < 6 && (
                                      <button
                                        className="focus:outline-none ml-1"
                                        onClick={() =>
                                          handleSort(sortableColumns[index])
                                        }
                                      >
                                        <ChevronUpDownIcon
                                          strokeWidth={2}
                                          className={`h-4 w-4 ${
                                            descending
                                              ? "transform rotate-180"
                                              : ""
                                          }`}
                                        />
                                      </button>
                                    )}
                                  </Typography>
                                </th>
                              ))}
                            </tr>
                          </thead>

                          <tbody>
                            {responseList?.map((val, index) => {
                              const isLast = index === responseList.length - 1;
                              const classes = isLast
                                ? "p-lg px-lg"
                                : "p-lg border-b border-border-secondary";

                              return (
                                <tr
                                  key={val.id}
                                  className="hover:bg-bg-secondary"
                                >
                                  <td
                                    className={`border-r border-b border-border-secondary hover:bg-bg-secondary bg-white sticky left-0 px-lg shadow-[rgba(0,0,10,0.2)_-3px_1px_12px_-5px]`}
                                  >
                                    <div className="flex justify-between gap-4">
                                      <Link
                                        to={`/periode-budidaya/periode-berjalan/${val.id}/dataharian`}
                                      >
                                        <Typography className="text-text-infoPrimary text-sm font-medium leading-tight">
                                          {val?.house?.labels?.length > 20 ? (
                                            <Tooltip
                                              content={
                                                <div className="w-60">
                                                  <Typography
                                                    color="white"
                                                    className="font-normal text-sm"
                                                  >
                                                    {val.house.labels}
                                                  </Typography>
                                                </div>
                                              }
                                              placement="top"
                                            >
                                              {val.house.labels.substring(
                                                0,
                                                20
                                              ) + "..."}
                                            </Tooltip>
                                          ) : (
                                            val.house.labels
                                          )}
                                        </Typography>
                                      </Link>

                                      <div>
                                        {val.inputDaysLate > 7 ? (
                                          <Tooltip
                                            content="Belum menambahkan data 7 hari terakhir"
                                            placement="bottom"
                                          >
                                            <Alert />
                                          </Tooltip>
                                        ) : null}
                                      </div>
                                    </div>
                                  </td>
                                  <td className={classes + " max-w-[200px]"}>
                                    <Typography className="text-text-primary text-sm font-medium leading-tight">
                                      {val?.analyticAccount === null
                                        ? "-"
                                        : val?.analyticAccount}
                                    </Typography>
                                  </td>

                                  <td className={classes + " max-w-[200px]"}>
                                    <Typography className="text-text-primary text-sm font-medium leading-tight">
                                      {val?.house?.unit?.labels}
                                    </Typography>
                                  </td>
                                  <td className={classes + " max-w-[200px]"}>
                                    <Typography className="text-text-primary text-sm font-medium leading-tight">
                                      {val?.house.ppl?.fullName === null ? "-" : val?.house?.ppl?.fullName}
                                    </Typography>
                                  </td>
                                  <td className={classes + " max-w-[200px]"}>
                                    <Typography className="text-text-primary text-sm font-medium leading-tight flex justify-center">
                                      {val?.sequence?.toLocaleString("id-ID")}
                                    </Typography>
                                  </td>
                                  <td className={classes + " max-w-[330px]"}>
                                    <Typography className="text-text-primary text-sm font-medium leading-tight">
                                      {moment(val.docInDate).format("LL")}
                                    </Typography>
                                  </td>
                                  <td className={classes + " max-w-[200px] ml-[90px]"}>
                                    <Typography className="text-text-primary text-sm font-medium leading-tight flex justify-end">
                                      {val?.currentPopulation?.toLocaleString(
                                        "id-ID"
                                      )}
                                    </Typography>
                                  </td>
                                  <td className={classes}>
                                    <Typography className="text-text-primary text-sm font-medium leading-tight">
                                      {/* {val?.feed?.supplier} */}
                                      {val?.feed?.supplier?.length > 15 ? (
                                        <Tooltip
                                          content={
                                            <div className="w-60">
                                              <Typography
                                                color="white"
                                                className="font-normal text-sm"
                                              >
                                                {val.feed?.supplier}
                                              </Typography>
                                            </div>
                                          }
                                          placement="top"
                                        >
                                          {val.feed?.supplier?.substring(
                                            0,
                                            15
                                          ) + "..."}
                                        </Tooltip>
                                      ) : (
                                        val.feed?.supplier
                                      )}
                                    </Typography>
                                  </td>
                                  <td className={classes}>
                                    <Typography className="text-text-primary flex justify-end text-sm font-medium leading-tight text-center">
                                      {val.dayOfPeriod === 0 || null ? "-" : val?.dayOfPeriod?.toLocaleString(
                                        "id-ID"
                                      )}
                                    </Typography>
                                  </td>

                                  <td className={classes}>
                                    <Typography className="text-text-primary flex justify-end text-sm font-medium leading-tight">
                                      {val?.lastDailyRecordDate ? moment(val?.lastDailyRecordDate).format(
                                        "L"
                                      ) : "-"}
                                    </Typography>
                                  </td>

                                  <div
                                    className={`text-text-primary text-sm font-medium leading-tight text-right`}
                                  >
                                    <div className="grid grid-cols-12 w-[1200px]">
                                      <td className="col-span-1 p-lg border-l border-b flex justify-end items-center">
                                        {val.totalDepletion === 0 || null ? "-" : val.totalDepletion?.toLocaleString(
                                          "id-ID"
                                        )}
                                      </td>

                                      <td className="col-span-1 px-lg border-l border-b flex justify-end items-center">
                                        {(
                                          (val.totalDepletion /
                                            val.docInPopulation) *
                                          100
                                        )
                                          .toFixed(2)
                                          .toLocaleString("id-ID")}{" "}
                                        %
                                      </td>

                                      <td className={`col-span-1 px-lg border-l border-b flex justify-end items-center ${val.totalFeedUsed > val.standardFeedInTake ? "text-text-successPrimary" : "text-text-errorPrimary"}`}>
                                        {val.totalFeedUsed  === 0 && null ? "-" : val.totalFeedUsed / 50}
                                      </td>

                                      <td className={`col-span-1 px-lg border-l border-b flex justify-end items-center `}>
                                        {val.feedIntake === null || 0 ? "-" : val.feedIntake?.toLocaleString(
                                          "id-ID"
                                        )}
                                      </td>

                                      <td className="col-span-1 px-lg border-l border-b flex justify-end items-center">
                                        {val?.standardFeedInTake === 0 && null ? "-" : val.standardFeedInTake?.toLocaleString(
                                          "id-ID"
                                        )}
                                      </td>

                                      <td className={`col-span-1 px-lg border-l border-b flex justify-end items-center ${val.feedIntake > val.standardFeedInTake ? "text-text-infoPrimary" : "text-text-primary"}`}>
                                        {val?.standardFeedInTake &&
                                        val?.feedIntake
                                          ? `${(
                                              (val?.standardFeedInTake /
                                                val?.feedIntake) *
                                              100
                                            ).toFixed(2)} %`
                                          : "-"}
                                      </td>

                                      <td className={`col-span-1 px-lg border-l border-b flex justify-end items-center ${val.averageWeight > val.standardWeight ? "text-text-successPrimary" : "text-text-errorPrimary" }`}>
                                        {val?.averageWeight === 0 || null ?  "-" : val?.averageWeight?.toLocaleString("id-ID" )}
                                      </td>

                                      <td className="col-span-1 px-lg border-l border-b flex justify-end items-center">
                                        {val?.standardWeight === 0 || null ?  "-" : val?.standardWeight?.toLocaleString("id-ID")}
                                      </td>

                                      <td className={`col-span-1 px-lg border-l border-b flex justify-end items-center ${val.averageWeight > val.standardWeight ? "text-text-infoPrimary" : "text-text-primary" }`}>
                                        {val?.averageWeight &&
                                        val?.standardWeight
                                          ? `${Number(
                                              (
                                                (val.averageWeight /
                                                  val.standardWeight) *
                                                100
                                              ).toFixed(2)
                                            ).toLocaleString("id-ID")} %`
                                          : "-"}
                                      </td>

                                      <td className={`col-span-1 px-lg border-l border-b flex justify-end items-center ${val.actualFcr > val?.standardFcr ? "text-text-successPrimary" : "text-text-errorPrimary"}`}>
                                        {val.actualFcr === 0 || null ? "-" : val?.actualFcr?.toLocaleString(
                                          "id-ID"
                                        )}
                                      </td>

                                      <td className="col-span-1 px-lg border-l border-b flex justify-end items-center">
                                        {val.standardFcr === 0 || null ? "-" : val?.standardFcr?.toLocaleString(
                                          "id-ID"
                                        )}
                                      </td>

                                      <td className="col-span-1 px-lg border-l border-b border-r flex justify-end items-center">
                                        {/* {val?.diffFcr?.toLocaleString("id-ID")} */}
                                        {val.actualFcr && 
                                          val.standardFcr
                                          ? `${Number(
                                              (
                                                (val.actualFcr /
                                                  val.standardFcr) *
                                                100
                                              ).toFixed(2)
                                            ).toLocaleString("id-ID")} %`
                                          : "-"
                                        }
                                      </td>
                                    </div>
                                  </div>
                                  <td className={classes}>
                                    <div className="col-span-1 flex justify-end items-center">
                                      <Typography className="text-text-primary text-sm font-medium leading-tight">
                                        {val?.ip === 0 || null ? "-" : val?.ip?.toLocaleString("id-ID")}
                                      </Typography>
                                    </div>
                                  </td>

                                  <td
                                    className={`border-l border-b border-border-secondary hover:bg-bg-secondary bg-white sticky right-0 px-lg shadow-[rgba(0,0,10,0.2)_-3px_1px_12px_-5px]`}
                                  >
                                    <div className="flex justify-between gap-2 m-0 relative">
                                      {/* Button that toggles the popup */}
                                      {user?.level === "REGION" ? (
                                        <ButtonIcon
                                          icon="detail"
                                          size="small"
                                          onClick={() =>
                                            navigate(
                                              `/periode-budidaya/periode-berjalan/${val.id}/dataharian`
                                            )
                                          }
                                        />
                                      ) : (
                                        <IconButton
                                          variant="text"
                                          onClick={() =>
                                            handleTogglePopup(val?.id, val?.house?.id)
                                          }
                                        >
                                          <BurgerDotIcon />
                                        </IconButton>
                                      )}
                                    </div>

                                    {activePopupId === val?.id && (
                                      <div className="absolute top-[-8px] right-[75px] mt-2 z-50">
                                        <Card className="w-[180px] h-[121px]">
                                          <CardBody>
                                            <div className="space-y-4">
                                              {dataPopupTable?.map((item) => (
                                                <div
                                                  className="flex items-center gap-3 mt-[-2px] cursor-pointer"
                                                  key={item.label}
                                                  onClick={() =>
                                                    handleClickRoute(item?.id)
                                                  }
                                                >
                                                  <div>{item.icon}</div>

                                                  <div>
                                                    <Typography className="text-text-primary text-sm font-medium leading-tight">
                                                      {item.label}
                                                    </Typography>
                                                  </div>
                                                </div>
                                              ))}
                                            </div>
                                          </CardBody>
                                        </Card>
                                      </div>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </>
                    )}
                  </CardBody>
                  <CardFooter className="items-center border-t border-border-secondary p-sm">
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPage}
                      onPageChange={handleChangePage}
                    />
                  </CardFooter>
                </>
              )}
            </Card>
          </div>
        </div>
      </div>

      <ModalEditPeriod
        isOpen={openEdit}
        onClose={() => setOpenEdit(!openEdit)}
        getId={getId}
      />

      {isDownloadSuccess && (
        <AlertNotification
          icon={<FaCheckCircle />}
          color="green"
          message="Berhasil unduh data"
        />
      )}

      {isDownloadError && (
        <AlertNotification
          icon={<FaCheckCircle />}
          color="red"
          message="Tidak bisa download, pilih unit terlebih dahulu"
        />
      )}

      {notif && (
        <AlertNotification
          icon={<FaCheckCircle />}
          color="green"
          message="Periode Berhasil Dibuat"
        />
      )}

      {notifStop && (
        <AlertNotification
          icon={<FaCheckCircle />}
          color="green"
          message="Periode budidaya berhasil diakhiri."
        />
      )}

      <div className="p-4">
        <AddPeriod
          isOpen={openAddPeriod}
          setReload={setReload}
          onClose={() => setAddOpenPeriod(!openAddPeriod)}
        />
      </div>
    </>
  );
};

export default ListPeriodeBerjalan;
