import React from "react";

import {
  CardBody,
  CardHeader,
  Tab,
  TabPanel,
  Tabs,
  TabsBody,
  TabsHeader,
  Typography,
} from "@material-tailwind/react";
import { Card } from "@material-tailwind/react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useSearchParams } from "react-router-dom";

import { MultiSelect } from "../../../../atoms/V2/MultiSelect";
import { setSelectedUnits } from "../../../../molecules/features/filterUnit";
import { cn } from "../../../../shared/utils";
import useGetUnitDropdownList from "../../../../useCases/Unit/use-get-unit-dropdown-list";
import { SapronakDOC, SapronakPakan } from "./section";

const VALID_TABS = ["doc", "ovk", "pakan"];

const TAB_DATA = [
  { label: "DOC", value: "doc" },
  { label: "Pakan", value: "pakan" },
];

const ManajemenSapronak = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentTab = searchParams.get("tab") || "doc";
  const dispatch = useDispatch();
  const { selectedUnits } = useSelector((state) => state.filterUnit);

  const { data: queryUnitData } = useGetUnitDropdownList({ region: "" });

  if (!VALID_TABS.includes(currentTab)) {
    return <Navigate to="/manajemen-sapronak?tab=doc" replace />;
  }

  const handleTabChange = (value) => {
    setSearchParams({ tab: value });
  };

  return (
    <div className="p-4">
      <Card className="m-0 h-auto w-full border border-border-secondary p-0 shadow-none">
        <div className="flex w-full items-center justify-between px-4 py-4">
          <Typography
            variant="h2"
            className="text-xl font-bold text-text-primary"
          >
            Laporan & Realisasi Mingguan
          </Typography>
          <div className="flex items-center gap-2">
            <Typography className="text-sm font-medium leading-tight">
              Unit:{" "}
            </Typography>
            <MultiSelect
              options={queryUnitData || []}
              onValueChange={(value) => dispatch(setSelectedUnits(value))}
              defaultValue={selectedUnits}
              placeholder="Semua Unit"
              animation={2}
            />
          </div>
        </div>

        <Tabs value={currentTab}>
          <CardHeader
            floated={false}
            shadow={false}
            className="m-0 w-full rounded-b-none border-b px-xl py-sm pb-0"
          >
            <TabsHeader
              className="m-0 gap-lg rounded-none bg-transparent p-0"
              indicatorProps={{
                className:
                  "bg-transparent border-b-[3px] border-primary-300 shadow-none rounded-none gap-x-6 p-0 m-0",
              }}
              role="tablist"
            >
              {TAB_DATA.map(({ label, value }) => (
                <Tab
                  key={value}
                  value={value}
                  onClick={() => handleTabChange(value)}
                  disabled={value === "ovk"}
                  className={cn(
                    currentTab === value
                      ? "text-text-secondary"
                      : "text-text-tertiary",
                    "m-0 h-9 w-fit bg-white p-0 px-2 text-sm font-semibold",
                  )}
                  role="tab"
                >
                  {label}
                </Tab>
              ))}
            </TabsHeader>
          </CardHeader>
          <CardBody className="min-h-[80dvh] w-full px-0 py-sm">
            <TabsBody>
              <TabPanel value="doc" className="p-0">
                <SapronakDOC />
              </TabPanel>
              <TabPanel value="pakan" className="p-0">
                <SapronakPakan />
              </TabPanel>
            </TabsBody>
          </CardBody>
        </Tabs>
      </Card>
    </div>
  );
};

export default ManajemenSapronak;
