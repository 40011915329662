import { useEffect, useMemo, useState } from "react";

import {
  Card,
  CardBody,
  CardHeader,
  Tab,
  TabPanel,
  Tabs,
  TabsBody,
  TabsHeader,
  Typography,
} from "@material-tailwind/react";
import { format } from "date-fns";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
  IcBuilding,
  IcCalendar,
  IcChick,
  IcCircle,
} from "../../../../atoms/icons";
import { Skeleton } from "../../../../atoms/V2/Skeleton";
import ModalEditPeriod from "../../../../molecules/periodeBudidaya/ModalEditPeriode";
import { cn, formatNumber } from "../../../../shared/utils";
import { useGetDetailRearingPeriod } from "../../../../useCases/RearingPeriod";
import { Separator } from "./../../../../atoms/V2/Separator";
import RecordingHarian from "./Recording/RecordingHarian/index";
import Rekapitulasi from "./TabDetailPeriode/Rekapitulasi";
import RekapPeriodeBudidaya from "./TabDetailPeriode/RekapPeriodeBudidaya";
import Sapronak from "./TabDetailPeriode/Sapronak";

const DetailPeriod = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const pathSegments = location.pathname.split("/");
  const lastSegment = pathSegments[pathSegments.length - 1];

  const [settingType, setSettingType] = useState(lastSegment);
  const [openEdit, setOpenEdit] = useState(false);

  const { data: responseDetail, isLoading: loading } =
    useGetDetailRearingPeriod({ id });

  const data = useMemo(
    () => [
      {
        label: "Ringkasan",
        value: "period",
      },
      {
        label: "Sapronak",
        value: "sapronak",
      },
      {
        label: "Data Harian",
        value: "dataharian",
      },
      {
        label: "Rekapitulasi",
        value: "rekapitulasi",
      },
    ],
    [], // Empty dependency array ensures this array is only created once.
  );

  const handleTabClick = (value) => {
    setSettingType(value);

    // Update URL with query parameter when tab changes
    navigate(`/periode-budidaya/periode-berjalan/${id}/${value}`);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tabParam = params.get("tab");

    if (tabParam && data.some((tab) => tab.value === tabParam)) {
      setSettingType(tabParam);
    } else {
      const lastPathSegment = location.pathname.split("/").pop();
      if (data.some((tab) => tab.value === lastPathSegment)) {
        setSettingType(lastPathSegment);
      } else {
        setSettingType("period");
      }
    }
  }, [location.pathname, location.search, data]);

  return (
    <>
      <div className={`flex`}>
        <div className="flex flex-1 flex-col overflow-hidden">
          <div className="p-4">
            <Card
              className={`m-0 w-full border border-neutral-200 p-0 shadow-none ${
                settingType === "data"
                  ? "w-[81%] sm:w-[85%] md:w-[80%] lg:w-[100%]"
                  : "w-full"
              }`}
            >
              <Tabs value={settingType}>
                <CardHeader
                  floated={false}
                  shadow={false}
                  className="m-0 rounded-none rounded-t-xl border-x-0 border-t-0 p-0"
                >
                  {loading ? (
                    <div className="grid grid-cols-12 justify-between p-xl">
                      <div className="col-span-12 space-y-1 lg:col-span-4">
                        <div className="flex items-center gap-md">
                          <Skeleton className="h-[28px] w-4/12" />
                          <Skeleton className="h-[20px] w-2/12" />
                        </div>
                        <Skeleton className="h-[20px] w-8/12" />
                      </div>
                      <div className="col-span-12 flex flex-col items-end justify-end gap-[6px] lg:col-span-8">
                        <Skeleton className="h-[20px] w-4/12" />
                        <div className="flex items-center gap-lg">
                          <Skeleton className="h-[20px] w-[120px]" />
                          <Separator orientation="vertical" />
                          <Skeleton className="h-[20px] w-[120px]" />
                          <Separator orientation="vertical" />
                          <Skeleton className="h-[20px] w-[120px]" />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="grid grid-cols-12 justify-between p-xl">
                      <div className="col-span-12 space-y-1 lg:col-span-4">
                        <div className="flex items-center gap-md">
                          <h1 className="text-lg font-bold text-text-primary">
                            {responseDetail?.house?.labels || "-"}
                          </h1>
                          <p className="rounded-sm border border-neutral-200 bg-neutral-50 px-2 py-[2px] text-sm font-medium">
                            {responseDetail?.house?.unit?.labels || "-"}
                          </p>
                        </div>
                        <p className="text-sm text-text-quarterary">
                          {responseDetail?.analyticAccount || "-"}
                        </p>
                      </div>
                      <div className="col-span-12 flex flex-col items-end justify-end gap-[6px] lg:col-span-8">
                        <p className="inline-flex items-center gap-1 rounded-sm border border-border-primary bg-white px-2 py-[2px] text-sm font-medium">
                          <IcCircle className="size-2 fill-primary-400 text-primary-400" />
                          <span>
                            PPL: {responseDetail?.house?.ppl?.fullName || "-"}
                          </span>
                        </p>
                        <div className="flex items-center gap-lg">
                          <div className="flex items-center gap-xs">
                            <IcCalendar className="size-4 text-[#B3B4B6]" />
                            <p className="text-sm text-text-tertiary">
                              DOC In{" "}
                              {responseDetail?.docInDate
                                ? format(
                                    new Date(responseDetail?.docInDate),
                                    "dd MMM yyyy",
                                  )
                                : "-"}
                            </p>
                          </div>
                          <Separator orientation="vertical" />
                          <div className="flex items-center gap-xs">
                            <IcChick className="size-4 text-[#B3B4B6]" />
                            <p className="text-sm text-text-tertiary">
                              Populasi awal{" "}
                              {formatNumber(
                                responseDetail?.docInPopulation || 0,
                              )}{" "}
                              ekor
                            </p>
                          </div>
                          <Separator orientation="vertical" />
                          <div className="flex items-center gap-xs">
                            <IcBuilding className="size-4 text-[#B3B4B6]" />
                            <p className="text-sm text-text-tertiary">
                              Vendor DOC {responseDetail?.doc?.company || "-"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="border-y border-border-secondary px-xl pt-sm">
                    <TabsHeader
                      className="m-0 gap-lg rounded-none bg-transparent p-0"
                      indicatorProps={
                        location.pathname.endsWith(settingType)
                          ? {
                              className:
                                "bg-transparent border-b-[3px] border-primary-300  shadow-none rounded-none gap-x-6 p-0 m-0",
                            }
                          : null
                      }
                    >
                      {data.map(({ label, value }) => (
                        <Tab
                          key={`${label}-${value}`}
                          value={value}
                          onClick={() => handleTabClick(value)}
                          className={cn(
                            settingType === value
                              ? "text-text-secondary"
                              : "text-text-tertiary",
                            "m-0 h-9 w-fit bg-white p-0 px-2 text-sm font-semibold",
                          )}
                          disabled={loading}
                        >
                          <Typography variant="h6" className="w-full gap-y-5">
                            {label}
                          </Typography>
                        </Tab>
                      ))}
                    </TabsHeader>
                  </div>
                </CardHeader>

                <CardBody className="p-0">
                  <div className="relative flex w-full justify-start">
                    <TabsBody>
                      <TabPanel value="period" className="p-0">
                        {settingType === "period" && (
                          <RekapPeriodeBudidaya
                            ResponseDetail={responseDetail}
                            loading={loading}
                          />
                        )}
                      </TabPanel>
                      <TabPanel value="sapronak" className="p-0">
                        {settingType === "sapronak" && (
                          <Sapronak periodId={id} />
                        )}
                      </TabPanel>
                      <TabPanel value="dataharian" className="p-0">
                        {settingType === "dataharian" && <RecordingHarian />}
                      </TabPanel>
                      <TabPanel value="rekapitulasi" className="p-0">
                        {settingType === "rekapitulasi" && <Rekapitulasi />}
                      </TabPanel>
                    </TabsBody>
                  </div>
                </CardBody>
              </Tabs>
            </Card>
          </div>
        </div>
      </div>

      <ModalEditPeriod
        isOpen={openEdit}
        getId={id}
        onClose={() => setOpenEdit(!openEdit)}
      />
    </>
  );
};

export default DetailPeriod;
