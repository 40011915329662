import { useMutation } from "@tanstack/react-query";

import api from "../../api/configApi";

const useMutateFeedsList = () => {
  const mutation = useMutation({
    mutationFn: async () => {
      const response = await api.get("/v1/feeds/list");
      return response.data;
    },
  });

  return mutation;
};

export default useMutateFeedsList;
