// src/components/multi-select.tsx
import * as React from "react";

import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { FaCheck } from "react-icons/fa6";

import { Button } from "../../../../../../../atoms/V2/Button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from "../../../../../../../atoms/V2/Command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../../../../../atoms/V2/Popover";
import { cn } from "../../../../../../../shared/utils";
import BadgeStatus from "./badge-status";

/**
 * Props for MultiSelect component
 * https://github.com/sersavan/shadcn-multi-select-component
 */

export const CustomMultiSelect = React.forwardRef(
  (
    {
      options,
      onValueChange,
      variant,
      defaultValue = [],
      placeholder = "Select options",
      animation = 0,
      maxCount = 3,
      modalPopover = false,
      asChild = false,
      className,
      ...props
    },
    ref,
  ) => {
    const [selectedValues, setSelectedValues] = React.useState(defaultValue);
    const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);

    const handleInputKeyDown = (event) => {
      if (event.key === "Enter") {
        setIsPopoverOpen(true);
      } else if (event.key === "Backspace" && !event.currentTarget.value) {
        const newSelectedValues = [...selectedValues];
        newSelectedValues.pop();
        setSelectedValues(newSelectedValues);
        onValueChange(newSelectedValues);
      }
    };

    const toggleOption = (option) => {
      const newSelectedValues = selectedValues.includes(option)
        ? selectedValues.filter((value) => value !== option)
        : [...selectedValues, option];
      setSelectedValues(newSelectedValues);
      onValueChange(newSelectedValues);
    };

    const handleClear = () => {
      setSelectedValues([]);
      onValueChange([]);
    };

    const handleTogglePopover = () => {
      setIsPopoverOpen((prev) => !prev);
    };

    return (
      <Popover
        open={isPopoverOpen}
        onOpenChange={setIsPopoverOpen}
        modal={modalPopover}
      >
        <PopoverTrigger asChild>
          <Button
            ref={ref}
            {...props}
            onClick={handleTogglePopover}
            variant="btnOutlinedGrey"
            className={cn(
              "flex w-fit items-center justify-between [&_svg]:pointer-events-auto",
              className,
            )}
          >
            {selectedValues.length > 0 ? (
              <div className="mx-auto flex w-full items-center justify-between gap-sm">
                <span className="text-sm font-normal text-[#484B4F]">
                  {placeholder}
                </span>
                <div className="flex size-[18px] items-center justify-center rounded-full bg-[#5A5C60] text-sm text-white">
                  {selectedValues.length}
                </div>
                <ChevronDownIcon className="h-4 cursor-pointer text-muted-foreground" />
              </div>
            ) : (
              <div className="mx-auto flex w-full items-center justify-between gap-sm">
                <span className="text-sm font-normal text-[#484B4F]">
                  {placeholder}
                </span>
                <ChevronDownIcon className="h-4 cursor-pointer text-muted-foreground" />
              </div>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent
          className="max-w-[152px] p-0"
          align="center"
          onEscapeKeyDown={() => setIsPopoverOpen(false)}
        >
          <Command>
            <CommandInput
              placeholder="Cari..."
              onKeyDown={handleInputKeyDown}
            />
            <CommandList>
              <CommandEmpty>No results found.</CommandEmpty>
              <CommandGroup>
                {options.map((option) => {
                  const isSelected = selectedValues.includes(option.value);
                  return (
                    <CommandItem
                      key={option.value}
                      onSelect={() => toggleOption(option.value)}
                      className="cursor-pointer"
                    >
                      <div
                        className={cn(
                          "mr-1 flex h-5 w-5 items-center justify-center rounded-sm border border-transparent",
                          isSelected
                            ? "border-primary-500 bg-primary-500 text-white"
                            : "border-border-primary opacity-50 [&_svg]:invisible",
                        )}
                      >
                        <FaCheck className="h-4 w-4" />
                      </div>
                      {option.icon && (
                        <option.icon className="mr-2 h-4 w-4 text-muted-foreground" />
                      )}
                      <BadgeStatus type={option.label?.toUpperCase()} />
                    </CommandItem>
                  );
                })}
              </CommandGroup>
              <CommandSeparator />
              <CommandGroup>
                <div className="flex items-center justify-between">
                  {selectedValues.length > 0 && (
                    <CommandItem
                      onSelect={handleClear}
                      className="flex-1 cursor-pointer justify-center"
                    >
                      Atur ulang
                    </CommandItem>
                  )}
                </div>
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    );
  },
);

CustomMultiSelect.displayName = "CustomMultiSelect";
