import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedUnits: [],
};

const filterUnitSlice = createSlice({
  name: 'filterUnit',
  initialState,
  reducers: {
    setSelectedUnits: (state, action) => {
      state.selectedUnits = action.payload;
    },
    resetFilters: (state) => {
      state.selectedUnits = [];
    },
  },
});

export const {
  setSelectedUnits,
  resetFilters
} = filterUnitSlice.actions;

export default filterUnitSlice.reducer;
