import React from "react";

import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { Radio, Tab, TabsHeader } from "@material-tailwind/react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { FiFilter } from "react-icons/fi";

import { IcDownload } from "../../../../../../../atoms/icons";
import { Button } from "../../../../../../../atoms/V2/Button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../../../../../atoms/V2/Dialog";
import { Input } from "../../../../../../../atoms/V2/Input";
import { Label } from "../../../../../../../atoms/V2/Label";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../../../../../atoms/V2/Popover";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../../../../atoms/V2/Select";
import { Separator } from "../../../../../../../atoms/V2/Separator";
import { toast } from "../../../../../../../atoms/V2/useToast";
import { cn } from "../../../../../../../shared/utils";
import { months, years } from "../../../../../../../shared/variables";
import { CustomMultiSelect } from "../../../components/custom-multi-select";
import { DOWNLOAD_TYPE, TABS_DATA } from "../config";
import { useSettingPakan } from "../hooks";

const TabsHeaderComp = () => {
  const {
    currentTab,
    search,
    setSearch,
    value,
    setCurrentPage,
    dataTotalItem,
    selectedWeeks,
    setSelectedWeeks,
    year,
    setYear,
    month,
    setMonth,
    isFilter,
    filteredItems,
    selectedRows,
    isDialogDownloadOpen,
    setIsDialogDownloadOpen,
    downloadType,
    setDownloadType,
    query,
    mutateDownloadExcel,
    handleResetFilter,
    handleApplyFilter,
    countFilteredItems,
    handleDownloadExcel,
    handleChangeTab,
  } = useSettingPakan();

  return (
    <div className="flex items-center justify-between px-xl py-xl">
      <div className="flex items-center gap-4">
        <TabsHeader
          className="h-9 rounded-sm border border-secondary bg-bg-secondary p-0"
          indicatorProps={{
            className:
              "bg-white border-[#D5D5D7] border shadow-none text-text-tertiary px-lg rounded-sm",
          }}
        >
          {TABS_DATA.map((tab) => (
            <Tab
              key={tab.value}
              value={tab.value}
              onClick={() => handleChangeTab(tab)}
              className={cn(
                "text-sm",
                currentTab === tab.value
                  ? "font-bold text-text-secondary"
                  : "font-medium text-text-tertiary",
              )}
            >
              {tab.label}
            </Tab>
          ))}
        </TabsHeader>
        <Separator orientation="vertical" className="h-9" />
        <Input
          id="search"
          type="search"
          size="small"
          value={search}
          placeholder="Pencarian"
          onChange={(e) => {
            setSearch(e.target.value);
            setCurrentPage(0);
          }}
          leftComponent={<MagnifyingGlassIcon className="h-5 w-5" />}
          className="h-9 !w-[200px] flex-shrink-0"
        />
        <Popover>
          <PopoverTrigger asChild>
            <Button
              variant="btnOutlinedGrey"
              className="w-fit flex-shrink-0 font-medium text-[#484B4F]"
            >
              <FiFilter className="size-5" />
              Filter
              {filteredItems && countFilteredItems(filteredItems) > 0 && (
                <span className="ml-[6px] flex h-[18px] w-[18px] items-center justify-center rounded-full bg-[#5A5C60] text-xs text-white">
                  {countFilteredItems(filteredItems)}
                </span>
              )}
            </Button>
          </PopoverTrigger>
          <PopoverContent align="end" className="w-[320px] px-0 py-[10px]">
            <div className="flex w-full flex-col items-center gap-3">
              <div className="flex w-full flex-col gap-2 px-4">
                <div className="flex w-full flex-col gap-2">
                  <Label>Tahun</Label>
                  <Select value={year.toString()} onValueChange={setYear}>
                    <SelectTrigger className="w-full">
                      <SelectValue placeholder="Pilih tahun" />
                    </SelectTrigger>
                    <SelectContent>
                      {years?.map((year) => (
                        <SelectItem key={year} value={year.toString()}>
                          {year}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>

                <div className="flex w-full flex-col gap-2">
                  <Label>Bulan</Label>
                  <Select value={month} onValueChange={setMonth}>
                    <SelectTrigger className="w-full">
                      <SelectValue placeholder="Pilih bulan" />
                    </SelectTrigger>
                    <SelectContent>
                      {months.map((month) => (
                        <SelectItem key={month.value} value={month.value}>
                          {month.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              </div>
              <div className="flex w-full items-center justify-between border-t-2 border-border-primary px-3 pt-[10px]">
                <Button
                  size="small"
                  variant="ghost"
                  className="w-fit"
                  onClick={handleResetFilter}
                >
                  Atur Ulang
                </Button>
                <Button
                  size="small"
                  className="w-fit"
                  onClick={handleApplyFilter}
                >
                  Terapkan
                </Button>
              </div>
            </div>
          </PopoverContent>
        </Popover>
        <CustomMultiSelect
          options={[
            { value: 1, label: "Minggu 1" },
            { value: 2, label: "Minggu 2" },
            { value: 3, label: "Minggu 3" },
            { value: 4, label: "Minggu 4" },
            { value: 5, label: "Minggu 5" },
          ]}
          onValueChange={setSelectedWeeks}
          defaultValue={selectedWeeks}
          placeholder="Minggu"
          animation={2}
          maxCount={3}
        />
      </div>
      <div className="flex items-center gap-4">
        <Dialog
          open={isDialogDownloadOpen}
          onOpenChange={(isOpen) => setIsDialogDownloadOpen(isOpen)}
        >
          <DialogTrigger asChild>
            <Button variant="btnOutlinedGrey" className="w-fit text-[#484B4F]">
              <IcDownload className="size-5" />
              Unduh
            </Button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Unduh data file ke .XLS</DialogTitle>
              <DialogDescription>
                Pilih kategori data yang ingin di unduh
              </DialogDescription>
            </DialogHeader>
            <div className="flex flex-col">
              <Radio
                name="download"
                value={downloadType === DOWNLOAD_TYPE.allData}
                onChange={() => setDownloadType(DOWNLOAD_TYPE.allData)}
                label={
                  <p>
                    Semua data di seluruh halaman{" "}
                    {dataTotalItem && `(${dataTotalItem} baris)`}
                  </p>
                }
                color="amber"
                defaultChecked
              />
              <Radio
                name="download"
                value={downloadType === DOWNLOAD_TYPE.selectedData}
                onChange={() => setDownloadType(DOWNLOAD_TYPE.selectedData)}
                disabled={selectedRows.length === 0}
                label={
                  <p>
                    Hanya data yang telah dipilih{" "}
                    {selectedRows.length > 0
                      ? `(${selectedRows.length} baris)`
                      : "(0)"}
                  </p>
                }
                color="amber"
              />
              <Radio
                name="download"
                value={downloadType === DOWNLOAD_TYPE.filterData}
                onChange={() => setDownloadType(DOWNLOAD_TYPE.filterData)}
                disabled={!isFilter && !value}
                label={
                  <p>
                    Semua data yang telah di filter{" "}
                    {isFilter || value
                      ? query.data?.totalItems &&
                        `(${query.data?.totalItems} baris)`
                      : "(0)"}
                  </p>
                }
                color="amber"
              />
            </div>
            <DialogFooter>
              <div className="flex items-center justify-end gap-lg">
                <DialogClose asChild>
                  <Button
                    variant="btnSecondary"
                    className="w-fit"
                    disabled={mutateDownloadExcel.isPending}
                  >
                    Kembali
                  </Button>
                </DialogClose>
                <Button
                  onClick={() => {
                    handleDownloadExcel();
                    toast({
                      title: "Sedang mengunduh data",
                      variant: "loading",
                    });
                  }}
                  className="w-fit"
                  disabled={mutateDownloadExcel.isPending}
                >
                  Konfirmasi
                  {mutateDownloadExcel.isPending && (
                    <AiOutlineLoading3Quarters className="size-4 animate-spin text-button-primaryFg" />
                  )}
                </Button>
              </div>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
};

export default TabsHeaderComp;
