import React, { useEffect, useRef, useState } from "react";

import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { MdOutlineDone } from "react-icons/md";

import { ReactComponent as ChevronDown } from "../../assets/icons/chevron_down.svg";
import { cn } from "../../shared/utils";
import { Popover, PopoverContent, PopoverTrigger } from "./Popover";

export const DropdownSingleWithSearch = (props) => {
  const {
    id,
    content,
    disabled,
    options,
    onSelect,
    placeholder,
    placeholderSearch,
    selectedOption,
    setSelectedLabel,
    wrapperStyle,
    inputStyle,
    isError,
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [labelSelected, setLabelSelected] = useState("");
  const [triggerWidth, setTriggerWidth] = useState(0);

  const triggerRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    setLabelSelected(selectedOption ? selectedOption.labels : "");
  }, [selectedOption]);

  useEffect(() => {
    if (triggerRef.current) {
      setTriggerWidth(triggerRef.current.offsetWidth);
    }
  }, []);

  const filteredOptions = Array.isArray(options)
    ? options.filter((item) =>
        item?.labels?.toLowerCase().includes(searchTerm?.toLowerCase()),
      )
    : [];

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    if (e.target.value === "") {
      setLabelSelected("");
    }
  };

  const handleSelect = (selectedOption) => {
    onSelect(selectedOption);
    setIsOpen(false);
    setLabelSelected(selectedOption.labels || "");
    if (typeof setSelectedLabel === "function") {
      setSelectedLabel(selectedOption ? selectedOption.labels : "");
    }
    setSearchTerm("");
  };

  return (
    <Popover
      open={isOpen}
      onOpenChange={() => setIsOpen(!isOpen)}
      placement="bottom-start"
      modal={true}
    >
      <PopoverTrigger asChild>
        <button
          id={id}
          ref={triggerRef}
          disabled={disabled}
          className={cn(
            "inline-flex h-9 w-full min-w-[230px] items-center justify-center gap-md rounded-md border bg-white px-lg py-2.5 text-sm font-medium leading-normal text-text-placeholder hover:bg-neutral-50 focus:border-primary-400 focus:shadow-sm focus:shadow-gray-300 focus:outline-none focus:ring focus:ring-primary-200",
            disabled
              ? "cursor-not-allowed !bg-neutral-50 !text-neutral-400"
              : "",
            isError
              ? "border-fg-errorPrimary"
              : "border-border-primary hover:border-neutral-300",
          )}
        >
          <div
            className={cn(
              "flex w-full min-w-6 items-center justify-start text-sm font-normal text-text-placeholder",
              labelSelected ? "text-text-primary" : "text-text-placeholder",
              inputStyle,
            )}
          >
            <div className="w-auto">
              {labelSelected
                ? labelSelected
                : placeholder
                  ? placeholder
                  : "Pilih Data"}
            </div>
          </div>
          <div className="relative h-6 w-6">
            <ChevronDown
              className={`absolute mt-[2.5px] ${
                isOpen ? "rotate-180 transform" : ""
              } text-text-placeholder`}
            />
          </div>
        </button>
      </PopoverTrigger>

      <PopoverContent
        className="bg-white p-0"
        style={{ width: `${triggerWidth}px` }}
      >
        <div
          className={cn(
            "mb-2 flex cursor-pointer items-start gap-y-4",
            wrapperStyle,
          )}
        >
          <div className="w-full py-0">
            <div
              className={cn(
                "sticky top-0 flex w-full border-b border-border-primary",
              )}
            >
              <div className="relative w-full px-lg py-[10px]">
                <input
                  ref={inputRef}
                  type="text"
                  className={cn(
                    "text-text-placeholdersad w-full pe-5 text-sm placeholder:text-text-placeholder focus:outline-none",
                  )}
                  placeholder={
                    placeholderSearch ? placeholderSearch : "Cari..."
                  }
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
                <div className="absolute inset-y-0 right-3 flex flex-shrink-0 items-center focus:outline-none">
                  <MagnifyingGlassIcon className="size-5 text-neutral-400" />
                </div>
              </div>
            </div>

            <div className="max-h-[250px] overflow-y-auto">
              {filteredOptions?.length > 0 ? (
                filteredOptions?.map((option, index) => (
                  <button
                    key={index}
                    className="flex w-full cursor-pointer items-center justify-between px-lg text-start hover:bg-gray-100"
                    onClick={() => handleSelect(option)}
                  >
                    <div className="w-full">{content(option)}</div>
                    <div className="flex items-center justify-end p-2">
                      {labelSelected === option?.labels && (
                        <MdOutlineDone className="h-5 w-5 text-green-500" />
                      )}
                    </div>
                  </button>
                ))
              ) : (
                <div className="my-3 flex justify-start">
                  <span className="px-4 text-text-secondary">
                    Tidak ada data
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
};
