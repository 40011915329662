import React from "react";

import LoadingAnimation from "../../../../../../atoms/LoadingAnimation";
import { SheetForm } from "./components";
import { columns } from "./components/columns";
import { DataTable } from "./components/data-table";
import { useRecordingHarian } from "./hook";

const RecordingHarianFeature = () => {
  const { queryData, mappedData } = useRecordingHarian();

  return (
    <div>
      {queryData.isLoading ? (
        <div className="flex h-[75dvh] w-full items-center justify-center">
          <LoadingAnimation />
        </div>
      ) : (
        <DataTable columns={columns} data={mappedData} />
      )}
      <SheetForm />
    </div>
  );
};

export default RecordingHarianFeature;
