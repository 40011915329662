import { useMutation, useQueryClient } from "@tanstack/react-query";

import api from "../../../api/configApi";
import { toast } from "../../../atoms/V2/useToast";

const useDeleteSettingFeed = (id) => {
  const queryClient = useQueryClient();
  const mutate = useMutation({
    mutationFn: async () => {
      const response = await api.delete(`/v1/feedSettings/${id}`);
      return response;
    },
    onSuccess: () => {
      toast({
        title: "Setting pakan berhasil dihapus.",
        variant: "success",
      });
      queryClient.invalidateQueries({
        queryKey: ["get-setting-feeds"],
      });
    },
    onError: (error) => {
      console.log(error);
      toast({
        title: "Gagal menghapus setting pakan.",
        description: error.response.data.error,
        variant: "error",
      });
    },
  });

  return mutate;
};

export default useDeleteSettingFeed;
