import React from "react";

import { CalendarIcon } from "@heroicons/react/24/outline";
import { format } from "date-fns";
import { id } from "date-fns/locale";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { FiTrash2, FiUploadCloud } from "react-icons/fi";

import {
  IcFiles,
  IcFileTypePdf,
  IcPackageCheck,
} from "../../../../../../../atoms/icons";
import { Button } from "../../../../../../../atoms/V2/Button";
import { Calendar } from "../../../../../../../atoms/V2/DatePicker";
import { DropdownSingleWithSearch } from "../../../../../../../atoms/V2/DropdownSingleWithSearch";
import {
  Dropzone,
  DropZoneArea,
  DropzoneFileList,
  DropzoneFileListItem,
  DropzoneMessage,
  DropzoneRemoveFile,
  DropzoneTrigger,
} from "../../../../../../../atoms/V2/Dropzone";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../../../../../atoms/V2/Form";
import { Input } from "../../../../../../../atoms/V2/Input";
import { InputNumber } from "../../../../../../../atoms/V2/InputNumber";
import { Label } from "../../../../../../../atoms/V2/Label";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../../../../../atoms/V2/Popover";
import { Separator } from "../../../../../../../atoms/V2/Separator";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
} from "../../../../../../../atoms/V2/Sheet";
import { Skeleton } from "../../../../../../../atoms/V2/Skeleton";
import { Switch } from "../../../../../../../atoms/V2/Switch";
import { cn } from "../../../../../../../shared/utils";
import { FILE_CONTENT_TYPE } from "../../../../../../../shared/variables";
import { useSettingDOC } from "../hooks";
import CardFileDetail from "./card-file-detail";

const SheetFormRealisasi = () => {
  const {
    selectedRealisasi,
    isUploadedFileSJ,
    isUploadedFileTTB,
    isSheetRealisasiOpen,
    setIsSheetRealisasiOpen,
    isFileExist,
    setIsFileExist,
    isEditRealisasi,
    queryDetail,
    mutateDOCData,
    isPendingRealization,
    queryFileJS,
    queryFileTTB,
    form,
    dropzoneSJ,
    dropzoneTTB,
    handleOnSubmit,
    handleSameWithSetting,
  } = useSettingDOC();

  return (
    <Sheet open={isSheetRealisasiOpen} onOpenChange={setIsSheetRealisasiOpen}>
      <SheetContent className="px-0 pt-3xl">
        {queryDetail.isLoading || mutateDOCData.isPending ? (
          <div className="flex h-full flex-col">
            <SheetHeader className="flex justify-between border-b px-3xl pb-5">
              <Skeleton className="h-10 w-5/12" />
            </SheetHeader>
            <div className="mt-5 space-y-xl px-3xl">
              <div className="space-y-3">
                <Skeleton className="h-10 w-full" />
                <Skeleton className="h-5 w-full" />
              </div>
              <Separator className="self-stretch border-b border-dashed border-border-primary bg-transparent" />
              <div className="space-y-7">
                <div className="space-y-2">
                  <Skeleton className="h-5 w-4/12" />
                  <Skeleton className="h-8 w-full" />
                </div>
                <div className="space-y-2">
                  <Skeleton className="h-5 w-4/12" />
                  <Skeleton className="h-8 w-full" />
                </div>
                <div className="space-y-2">
                  <Skeleton className="h-5 w-4/12" />
                  <Skeleton className="h-8 w-full" />
                </div>
                <div className="space-y-2">
                  <Skeleton className="h-5 w-4/12" />
                  <Skeleton className="h-8 w-full" />
                </div>
                <div className="space-y-2">
                  <Skeleton className="h-5 w-4/12" />
                  <Skeleton className="h-8 w-full" />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex h-full flex-col justify-between">
            <SheetHeader className="border-b pb-5">
              <SheetTitle className="px-3xl text-text-primary">
                {isEditRealisasi ? "Realisasi" : "Realisasi DOC"}
              </SheetTitle>
              <SheetDescription className="sr-only">
                {selectedRealisasi?.houseName}
              </SheetDescription>
            </SheetHeader>
            <div className="max-h-[calc(100vh-5rem)] overflow-y-auto scrollbar-hide">
              <Form {...form}>
                <form className="py-xl">
                  <div className="space-y-xl px-3xl">
                    <div className="space-y-3">
                      <div>
                        <h4 className="text-base font-medium text-text-primary">
                          {selectedRealisasi?.houseName}
                        </h4>
                        <p className="text-sm text-text-quarterary">
                          {selectedRealisasi?.houseId}
                        </p>
                      </div>
                      {!isEditRealisasi && (
                        <div className="flex items-center justify-start gap-md">
                          <Switch
                            onCheckedChange={(checked) =>
                              checked && handleSameWithSetting()
                            }
                          />
                          <p className="text-sm font-medium text-text-secondary">
                            Sama dengan setting
                          </p>
                        </div>
                      )}
                    </div>
                    <Separator
                      decorative
                      className="self-stretch border-b border-dashed border-border-primary bg-transparent"
                    />
                    <FormField
                      control={form.control}
                      name="dateRealization"
                      render={({ field }) => {
                        return (
                          <FormItem>
                            <FormLabel isRequire>Tanggal DOC in</FormLabel>
                            <FormControl>
                              <Popover>
                                <PopoverTrigger asChild>
                                  <Button
                                    id={field.name}
                                    variant={
                                      form.formState.errors.dateRealization
                                        ? "btnErrorOutlined"
                                        : "btnOutlinedGrey"
                                    }
                                    className={cn(
                                      "w-full justify-between pr-3 text-left font-normal focus:border-primary-400 focus:ring focus:ring-primary-200 focus-visible:ring-primary-200",
                                      !field.value && "text-gray-400",
                                    )}
                                    disabled={
                                      queryDetail.data?.rearingPeriod?.isActive
                                    }
                                  >
                                    {field.value ? (
                                      format(field.value, "PPP", {
                                        locale: id,
                                      })
                                    ) : (
                                      <span>Pilih Tanggal</span>
                                    )}
                                    <CalendarIcon className="h-4 w-4" />
                                  </Button>
                                </PopoverTrigger>
                                <PopoverContent
                                  className="w-auto bg-white p-0"
                                  align="start"
                                >
                                  <Calendar
                                    mode="single"
                                    selected={field.value}
                                    onSelect={field.onChange}
                                    autoFocus
                                  />
                                </PopoverContent>
                              </Popover>
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        );
                      }}
                    />

                    <FormField
                      control={form.control}
                      name="docRealization"
                      render={({ field }) => {
                        return (
                          <FormItem>
                            <FormLabel isRequire>Jenis DOC</FormLabel>
                            <FormControl>
                              <DropdownSingleWithSearch
                                id={field.name}
                                options={mutateDOCData?.data?.contents || []}
                                disabled={
                                  mutateDOCData.isPending ||
                                  queryDetail.data?.rearingPeriod?.isActive
                                }
                                isError={form.formState.errors.docRealization}
                                placeholder="Pilih jenis DOC"
                                placeholderSearch="Cari jenis DOC"
                                selectedOption={
                                  mutateDOCData?.data?.contents?.find(
                                    (item) => item.id === field.value,
                                  ) || null
                                }
                                onSelect={(value) => {
                                  field.onChange(value.id || null);
                                }}
                                content={(option) => (
                                  <div className="flex max-w-80 flex-col py-[10px]">
                                    <p className="text-base text-text-secondary">
                                      {option?.labels || "-"}
                                    </p>
                                  </div>
                                )}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        );
                      }}
                    />

                    <FormField
                      control={form.control}
                      name="qtyRealization"
                      render={({ field }) => {
                        return (
                          <FormItem>
                            <FormLabel isRequire>Populasi</FormLabel>
                            <FormControl>
                              <InputNumber
                                id={field.name}
                                value={field.value || ""}
                                onValueChange={(values) => {
                                  field.onChange(
                                    values.value ? Number(values.value) : "",
                                  );
                                }}
                                isError={form.formState.errors.qtyRealization}
                                placeholder="Masukkan populasi"
                                rightComponent={
                                  <span className="text-sm text-text-placeholder">
                                    Ekor
                                  </span>
                                }
                                disabled={
                                  queryDetail.data?.rearingPeriod?.isActive
                                }
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        );
                      }}
                    />

                    <FormField
                      control={form.control}
                      name="bwRealization"
                      render={({ field }) => {
                        return (
                          <FormItem>
                            <FormLabel isRequire>BW DOC</FormLabel>
                            <FormControl>
                              <InputNumber
                                id={field.name}
                                value={field.value || ""}
                                onValueChange={(values) => {
                                  field.onChange(
                                    values.value ? Number(values.value) : "",
                                  );
                                }}
                                isError={form.formState.errors.bwRealization}
                                placeholder="Masukkan BW DOC"
                                rightComponent={
                                  <span className="text-sm text-text-placeholder">
                                    Gram
                                  </span>
                                }
                                disabled={
                                  queryDetail.data?.rearingPeriod?.isActive
                                }
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        );
                      }}
                    />
                    <Separator
                      decorative
                      className="self-stretch border-b border-dashed border-border-primary bg-transparent"
                    />
                    <div className="space-y-lg">
                      <div className="flex items-center gap-1">
                        <IcFiles className="size-5 text-text-secondary" />
                        <p className="font-medium text-text-primary">
                          Surat jalan (SJ)
                        </p>
                      </div>
                      <div className="space-y-xl">
                        <div className="space-y-md">
                          <Label isRequire>Lampiran SJ</Label>
                          {queryFileJS.data && isFileExist.sj ? (
                            <CardFileDetail
                              dumpsterFile={queryFileJS.data}
                              fileName={queryDetail.data?.media?.sj?.fileName}
                              contentType={
                                queryDetail.data?.media?.sj?.contentType
                              }
                              handleClickOuter={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                if (
                                  FILE_CONTENT_TYPE.PDF ===
                                  queryDetail.data?.media?.sj?.contentType
                                ) {
                                  queryFileJS.triggerDownload();
                                }
                              }}
                              handleRemoveFile={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setIsFileExist((prev) => ({
                                  ...prev,
                                  sj: false,
                                }));
                              }}
                            />
                          ) : (
                            <div className="not-prose flex flex-col">
                              <Dropzone {...dropzoneSJ}>
                                {dropzoneSJ.fileStatuses.length < 1 && (
                                  <div className="relative">
                                    {isEditRealisasi && (
                                      <p className="text-sm text-fg-errorPrimary">
                                        File baru belum di unggah!
                                      </p>
                                    )}
                                    <DropZoneArea className="border-dashed bg-bg-secondary p-0">
                                      <DropzoneTrigger className="flex items-center gap-3 bg-transparent py-md text-sm">
                                        <div className="flex items-center gap-sm rounded-md border border-[#D5D5D7] bg-white px-lg py-md text-[#484B4F]">
                                          <FiUploadCloud className="size-5" />
                                          <p className="font-semibold">
                                            Unggah file
                                          </p>
                                        </div>
                                        <p className="text-sm text-muted-foreground">
                                          Atau tarik disini
                                        </p>
                                      </DropzoneTrigger>
                                    </DropZoneArea>
                                    {isUploadedFileSJ && (
                                      <DropzoneMessage>
                                        Lampiran SJ belum diunggah
                                      </DropzoneMessage>
                                    )}
                                  </div>
                                )}

                                <DropzoneFileList className="grid grid-cols-2 gap-3 p-0">
                                  {dropzoneSJ.fileStatuses.map((file) => (
                                    <DropzoneFileListItem
                                      className="relative overflow-hidden rounded-md border bg-secondary p-0 shadow-sm"
                                      key={file.id}
                                      file={file}
                                    >
                                      {file.status === "pending" && (
                                        <div className="aspect-video animate-pulse bg-black/20" />
                                      )}
                                      {file.status === "success" &&
                                        (file.file.type ===
                                        "application/pdf" ? (
                                          <div className="pl-2 pt-2">
                                            <IcFileTypePdf className="size-8" />
                                          </div>
                                        ) : (
                                          <img
                                            src={file.result}
                                            alt={`uploaded-${file.fileName}`}
                                            className="aspect-video object-cover"
                                          />
                                        ))}
                                      <div className="flex items-center justify-between p-md">
                                        <div className="min-w-0">
                                          <p className="truncate text-sm">
                                            {file.fileName}
                                          </p>
                                          <p className="text-xs text-muted-foreground">
                                            {(
                                              file.file.size /
                                              (1024 * 1024)
                                            ).toFixed(2)}{" "}
                                            MB
                                          </p>
                                        </div>
                                      </div>
                                      <DropzoneRemoveFile
                                        variant="ghost"
                                        className="absolute right-0 top-0 m-1 shrink-0 rounded-full hover:bg-white/50 hover:text-fg-errorPrimary"
                                      >
                                        <FiTrash2 className="size-4" />
                                      </DropzoneRemoveFile>
                                    </DropzoneFileListItem>
                                  ))}
                                </DropzoneFileList>
                              </Dropzone>
                            </div>
                          )}
                        </div>
                        <FormField
                          control={form.control}
                          name="sjNumber"
                          render={({ field }) => {
                            return (
                              <FormItem>
                                <FormLabel isRequire>Nomor SJ</FormLabel>
                                <FormControl>
                                  <Input
                                    id={field.name}
                                    value={field.value || ""}
                                    onChange={field.onChange}
                                    variant={
                                      form.formState.errors.sjNumber
                                        ? "error"
                                        : "default"
                                    }
                                    placeholder="Masukkan Nomor SJ"
                                  />
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            );
                          }}
                        />
                      </div>
                    </div>
                    <Separator
                      decorative
                      className="self-stretch border-b border-dashed border-border-primary bg-transparent"
                    />
                    <div className="space-y-lg">
                      <div className="flex items-center gap-1">
                        <IcPackageCheck className="size-5 text-text-secondary" />
                        <p className="font-medium text-text-primary">
                          Tanda terima barang (TTB)
                        </p>
                      </div>
                      <div className="space-y-xl">
                        <div className="space-y-md">
                          <Label isRequire>Lampiran TTB</Label>
                          {queryFileTTB.data && isFileExist.ttb ? (
                            <CardFileDetail
                              dumpsterFile={queryFileTTB.data}
                              fileName={queryDetail.data?.media?.ttb?.fileName}
                              contentType={
                                queryDetail.data?.media?.ttb?.contentType
                              }
                              handleClickOuter={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                if (
                                  FILE_CONTENT_TYPE.PDF ===
                                  queryDetail.data?.media?.ttb?.contentType
                                ) {
                                  queryFileTTB.triggerDownload();
                                }
                              }}
                              handleRemoveFile={() => {
                                setIsFileExist((prev) => ({
                                  ...prev,
                                  ttb: false,
                                }));
                              }}
                            />
                          ) : (
                            <div className="not-prose flex flex-col">
                              <Dropzone {...dropzoneTTB}>
                                {dropzoneTTB.fileStatuses.length < 1 && (
                                  <div className="relative">
                                    {isEditRealisasi && (
                                      <p className="text-sm text-fg-errorPrimary">
                                        File baru belum di unggah!
                                      </p>
                                    )}
                                    <DropZoneArea className="border-dashed bg-bg-secondary p-0">
                                      <DropzoneTrigger className="flex items-center gap-3 bg-transparent py-md text-sm">
                                        <div className="flex items-center gap-sm rounded-md border border-[#D5D5D7] bg-white px-lg py-md text-[#484B4F]">
                                          <FiUploadCloud className="size-5" />
                                          <p className="font-semibold">
                                            Unggah file
                                          </p>
                                        </div>
                                        <p className="text-sm text-muted-foreground">
                                          Atau tarik disini
                                        </p>
                                      </DropzoneTrigger>
                                    </DropZoneArea>
                                    {isUploadedFileTTB && (
                                      <DropzoneMessage>
                                        Lampiran TTB belum diunggah
                                      </DropzoneMessage>
                                    )}
                                  </div>
                                )}

                                <DropzoneFileList className="grid grid-cols-2 gap-3 p-0">
                                  {dropzoneTTB.fileStatuses.map((file) => (
                                    <DropzoneFileListItem
                                      className="relative overflow-hidden rounded-md border bg-secondary p-0 shadow-sm"
                                      key={file.id}
                                      file={file}
                                    >
                                      {file.status === "pending" && (
                                        <div className="aspect-video animate-pulse bg-black/20" />
                                      )}
                                      {file.status === "success" &&
                                        (file.file.type ===
                                        "application/pdf" ? (
                                          <div className="pl-2 pt-2">
                                            <IcFileTypePdf className="size-8" />
                                          </div>
                                        ) : (
                                          <img
                                            src={file.result}
                                            alt={`uploaded-${file.fileName}`}
                                            className="aspect-video object-cover"
                                          />
                                        ))}
                                      <div className="flex items-center justify-between p-md">
                                        <div className="min-w-0">
                                          <p className="truncate text-sm">
                                            {file.fileName}
                                          </p>
                                          <p className="text-xs text-muted-foreground">
                                            {(
                                              file.file.size /
                                              (1024 * 1024)
                                            ).toFixed(2)}{" "}
                                            MB
                                          </p>
                                        </div>
                                      </div>
                                      <DropzoneRemoveFile
                                        variant="ghost"
                                        className="absolute right-0 top-0 m-1 shrink-0 rounded-full hover:bg-white/50 hover:text-fg-errorPrimary"
                                      >
                                        <FiTrash2 className="size-4" />
                                      </DropzoneRemoveFile>
                                    </DropzoneFileListItem>
                                  ))}
                                </DropzoneFileList>
                              </Dropzone>
                            </div>
                          )}
                        </div>
                        <FormField
                          control={form.control}
                          name="ttbNumber"
                          render={({ field }) => {
                            return (
                              <FormItem>
                                <FormLabel isRequire>Nomor TTB</FormLabel>
                                <FormControl>
                                  <Input
                                    id={field.name}
                                    value={field.value || ""}
                                    onChange={field.onChange}
                                    variant={
                                      form.formState.errors.ttbNumber
                                        ? "error"
                                        : "default"
                                    }
                                    placeholder="Masukkan Nomor TTB"
                                  />
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </Form>
            </div>
            <SheetFooter className="border-t">
              <div className="flex items-end justify-end gap-lg px-3xl pt-6">
                <Button
                  variant="btnOutlinedGrey"
                  disabled={isPendingRealization}
                  className="w-fit"
                  asChild
                >
                  <SheetClose>Batal</SheetClose>
                </Button>
                <Button
                  type="submit"
                  className="w-fit"
                  onClick={form.handleSubmit(handleOnSubmit)}
                  disabled={isPendingRealization}
                >
                  Simpan
                  {isPendingRealization && (
                    <AiOutlineLoading3Quarters className="size-4 animate-spin text-button-primaryFg" />
                  )}
                </Button>
              </div>
            </SheetFooter>
          </div>
        )}
      </SheetContent>
    </Sheet>
  );
};

export default SheetFormRealisasi;
