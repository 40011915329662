import { useQuery } from "@tanstack/react-query";

import api from "../../api/configApi";

const useGetListStock = ({ periodId, exclude }) => {
  const query = useQuery({
    queryKey: ["get-list-stok-sapronak", periodId, exclude],
    queryFn: async () => {
      const response = await api.get("/v1/sapronak/stocks", {
        params: {
          periodId,
          exclude,
        },
      });
      return response.data;
    },
    enabled: !!periodId,
  });

  return query;
};

export default useGetListStock;
