import React from "react";

import { format } from "date-fns";
import { id } from "date-fns/locale";
import { ScrollArea } from "../../../../../../../atoms/V2/ScrollArea";
import { Separator } from "../../../../../../../atoms/V2/Separator";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
} from "../../../../../../../atoms/V2/Sheet";
import { Skeleton } from "../../../../../../../atoms/V2/Skeleton";
import { formatNumber } from "../../../../../../../shared/utils";
import { useSettingPakan } from "../hooks";
import BadgeStatus from "./badge-status";
import BadgeWeek from "../../../components/badge-week";

const SheetDetailSetting = () => {
  const {
    selectedSetting,
    isSheetDetailSettingOpen,
    setIsSheetDetailSettingOpen,
    queryDetailSetting
  } = useSettingPakan();

  return (
    <Sheet
      open={isSheetDetailSettingOpen}
      onOpenChange={setIsSheetDetailSettingOpen}
    >
      <SheetContent className="px-0 pt-3xl">
        <div className="flex h-full flex-col justify-start">
          <SheetHeader className="border-b pb-5">
            <SheetTitle className="px-3xl text-text-primary">
              Detail Setting Pakan
            </SheetTitle>
            <SheetDescription className="sr-only">
              {selectedSetting?.houseName}
            </SheetDescription>
          </SheetHeader>
          {queryDetailSetting.isLoading ? (
            <div className="space-y-xl px-3xl py-xl">
              <div className="flex items-center justify-between">
                <Skeleton className="h-10 w-1/2" />
                <Skeleton className="h-8 w-1/4" />
              </div>
              <Separator
                decorative
                className="self-stretch border-b border-dashed border-border-primary bg-transparent"
              />
              <div className="grid grid-cols-2 items-center gap-y-lg">
                <Skeleton className="h-9 w-8/12" />
                <Skeleton className="h-9 w-7/12" />
                <Skeleton className="h-9 w-8/12" />
                <Skeleton className="h-9 w-7/12" />
                <Skeleton className="h-9 w-8/12" />
                <Skeleton className="h-9 w-7/12" />
                <Skeleton className="h-9 w-8/12" />
                <Skeleton className="h-9 w-7/12" />
              </div>
              <div className="space-y-2">
                <Skeleton className="h-9 w-8/12" />
                <Skeleton className="h-52 w-full" />
              </div>
            </div>
          ) : (
            <ScrollArea>
              <div className="space-y-xl px-3xl py-xl">
                <div className="flex items-center justify-between">
                  <div>
                    <h4 className="text-base font-medium text-text-primary">
                      {queryDetailSetting.data?.rearingPeriod?.house?.labels}
                    </h4>
                    <p className="text-sm text-text-quarterary">
                      {queryDetailSetting.data?.rearingPeriod?.analyticAccount}
                    </p>
                  </div>
                </div>
                <Separator
                  decorative
                  className="self-stretch border-b border-dashed border-border-primary bg-transparent"
                />
                <div className="grid grid-cols-2 items-center gap-y-lg">
                  <p className="text-sm text-text-tertiary">Alamat kandang</p>
                  <p className="text-sm text-text-primary">
                    {queryDetailSetting.data?.rearingPeriod?.house?.address}
                  </p>
                  <p className="text-sm text-text-tertiary">Kontak peternak</p>
                  <p className="text-sm text-text-primary">
                    {queryDetailSetting.data?.rearingPeriod?.house?.owner?.phoneNumber}
                  </p>
                  <p className="text-sm text-text-tertiary">Jenis kandang</p>
                  <p className="w-fit rounded-sm border border-neutral-200 bg-neutral-25 px-sm py-[2px] text-xs text-text-secondary">
                    {queryDetailSetting.data?.rearingPeriod?.house?.type?.labels}
                  </p>
                  <p className="text-sm text-text-tertiary">PPL</p>
                  <p className="text-sm text-text-primary">
                    {queryDetailSetting.data?.rearingPeriod?.house?.ppl?.labels}
                  </p>
                  <p className="text-sm text-text-tertiary">Kontak PPL</p>
                  <p className="text-sm text-text-primary">
                    {queryDetailSetting.data?.rearingPeriod?.house?.ppl?.phoneNumber}
                  </p>
                </div>
                <Separator
                  decorative
                  className="self-stretch border-b border-dashed border-border-primary bg-transparent"
                />
                <div className="space-y-2">
                  <h3 className="font-medium text-text-secondary">Detail</h3>
                  <div className="rounded-md border border-border-secondary">
                    <table className="w-full table-auto border-collapse overflow-hidden rounded-md border border-border-secondary text-sm text-text-primary">
                      <thead>
                        <tr>
                          <th className="border border-border-secondary bg-bg-secondary px-lg py-1 text-left"></th>
                          <th className="border border-border-secondary bg-bg-secondary px-lg py-1 text-left font-bold">
                            Setting
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="border border-border-secondary p-lg font-medium">
                            Tanggal
                          </td>
                          <td className="border border-border-secondary p-lg">
                            {queryDetailSetting.data?.dateSetting
                              ? format(
                                  queryDetailSetting.data?.dateSetting,
                                  "dd MMM yyyy",
                                  {
                                    locale: id,
                                  },
                                )
                              : "-"}
                          </td>
                        </tr>
                        <tr>
                          <td className="border border-border-secondary p-lg font-medium">
                            Minggu
                          </td>
                          <td className="border border-border-secondary p-lg">
                            <BadgeWeek type={queryDetailSetting.data?.weekSetting} />
                          </td>
                        </tr>
                        <tr>
                          <td className="border border-border-secondary p-lg font-medium">
                            Kuantitas
                          </td>
                          <td className="border border-border-secondary p-lg">
                            {formatNumber(
                              (queryDetailSetting.data?.qtySetting ?? 0) / 10,
                            )}{" "}
                            Sak
                          </td>
                        </tr>
                        <tr>
                          <td className="border border-border-secondary p-lg font-medium">
                            Nama pakan
                          </td>
                          <td className="border border-border-secondary p-lg">
                            {queryDetailSetting.data?.feedSetting?.labels || "-"}
                          </td>
                        </tr>
                        <tr>
                          <td className="border border-border-secondary p-lg font-medium">
                            Jenis pakan
                          </td>
                          <td className="max-w-[129px] border border-border-secondary p-lg">
                            <BadgeStatus
                              type={
                                queryDetailSetting.data?.feedSetting?.type?.id || "-"
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="border border-border-secondary p-lg font-medium">
                            Vendor
                          </td>
                          <td className="max-w-[129px] border border-border-secondary p-lg">
                            {queryDetailSetting.data?.feedSetting?.supplier || "-"}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </ScrollArea>
          )}
        </div>
      </SheetContent>
    </Sheet>
  );
};

export default SheetDetailSetting;
