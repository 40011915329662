import React from "react";

import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import {
  Card,
  CardBody,
  CardHeader,
  Typography,
} from "@material-tailwind/react";
import { Radio } from "@material-tailwind/react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { FiDownload } from "react-icons/fi";
import { HiOutlinePlus } from "react-icons/hi";

import LoadingAnimation from "../../../../atoms/LoadingAnimation";
import Table from "../../../../atoms/Table/Table";
import { Button } from "../../../../atoms/V2/Button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../../atoms/V2/Dialog";
import { Input } from "../../../../atoms/V2/Input";
import NotFound from "../../../../atoms/V2/NotFound";
import Pagination from "../../../../atoms/V2/Pagination";
import { toast } from "../../../../atoms/V2/useToast";
import { ROUTES_PATH } from "../../../../shared/routes";
import MasterPeriodeFilter from "./components/master-periode-filter";
import { useMasterPeriode } from "./hook";

const MasterPeriodeFeature = () => {
  const {
    query,
    data,
    columns,
    ConfirmDelete,
    pinningConfigs,
    currentPage,
    setCurrentPage,
    search,
    handleSearch,
    navigate,
    itemPerPage,
    setItemPerPage,
    handleDownloadExcel,
    mutationDownloadExcel,
    dataTotalItem,
    isDownloadFilter,
    setIsDownloadFilter,
    isDisableFeature,
    isDialogDownloadOpen,
    setIsDialogDownloadOpen,
    isFilter,
  } = useMasterPeriode();

  return (
    <div className="z-0 flex h-auto w-full overflow-hidden">
      <ConfirmDelete />
      <div className="flex-1 overflow-hidden">
        <div className="p-4">
          <Card className="m-0 h-auto w-full border border-border-secondary p-0 shadow-none">
            <CardHeader
              floated={false}
              shadow={false}
              className="m-0 w-full border-none px-3xl py-xl"
            >
              <div className="space-y-xl">
                <Typography
                  variant="h2"
                  className="text-xl font-bold text-text-primary"
                >
                  Master Periode
                </Typography>
                <div className="flex items-center justify-between">
                  <div className="flex items-center justify-between gap-4">
                    <Input
                      id="search"
                      type="search"
                      value={search}
                      placeholder="Cari pemilik kandang, nama kandang"
                      onChange={handleSearch}
                      leftComponent={
                        <MagnifyingGlassIcon className="h-5 w-5" />
                      }
                      disabled={query.isLoading}
                      className="!w-[364px] flex-shrink-0 border border-border-primary"
                    />
                    <MasterPeriodeFilter />
                  </div>
                  <div className="flex items-center justify-between gap-4">
                    <Dialog
                      open={isDialogDownloadOpen}
                      onOpenChange={(isOpen) => setIsDialogDownloadOpen(isOpen)}
                    >
                      <DialogTrigger asChild>
                        <Button
                          variant="btnOutlinedGrey"
                          disabled={isDisableFeature}
                        >
                          Download
                          <FiDownload className="ml-1 size-5" />
                        </Button>
                      </DialogTrigger>
                      <DialogContent>
                        <DialogHeader>
                          <DialogTitle>Unduh data file ke .XLS</DialogTitle>
                          <DialogDescription>
                            Pilih kategori data yang ingin di unduh
                          </DialogDescription>
                        </DialogHeader>
                        <div className="flex flex-col">
                          <Radio
                            name="download"
                            defaultChecked
                            value={isDownloadFilter}
                            onChange={() => setIsDownloadFilter(false)}
                            label={
                              <p>
                                Semua data di seluruh halaman{" "}
                                {dataTotalItem && `(${dataTotalItem} baris)`}
                              </p>
                            }
                            color="amber"
                          />
                          <Radio
                            name="download"
                            value={isDownloadFilter}
                            onChange={() => setIsDownloadFilter(true)}
                            disabled={!isFilter}
                            label={
                              <p>
                                Semua data yang telah di filter{" "}
                                {isFilter
                                  ? query.data?.totalItems &&
                                    `(${query.data?.totalItems} baris)`
                                  : "(0)"}
                              </p>
                            }
                            color="amber"
                          />
                        </div>
                        <DialogFooter>
                          <div className="flex items-center justify-end gap-lg">
                            <DialogClose asChild>
                              <Button
                                variant="btnSecondary"
                                className="w-fit"
                                disabled={mutationDownloadExcel.isPending}
                              >
                                Kembali
                              </Button>
                            </DialogClose>
                            <Button
                              onClick={() => {
                                handleDownloadExcel();
                                toast({
                                  title: "Sedang mengunduh data",
                                  variant: "loading",
                                });
                              }}
                              className="w-fit"
                              disabled={mutationDownloadExcel.isPending}
                            >
                              Konfirmasi
                              {mutationDownloadExcel.isPending && (
                                <AiOutlineLoading3Quarters className="size-4 animate-spin text-button-primaryFg" />
                              )}
                            </Button>
                          </div>
                        </DialogFooter>
                      </DialogContent>
                    </Dialog>
                    <Button
                      variant="btnPrimary"
                      onClick={() => {
                        navigate(ROUTES_PATH.masterPeriodeTambahPeriode);
                      }}
                    >
                      Buat Periode <HiOutlinePlus className="ml-1 size-5" />
                    </Button>
                  </div>
                </div>
              </div>
            </CardHeader>
            <CardBody className="w-full p-0">
              {query.isLoading ? (
                <div className="flex h-[75dvh] w-full items-center justify-center">
                  <LoadingAnimation />
                </div>
              ) : query.data?.contents?.length === 0 || query.isError ? (
                <div className="flex h-[75dvh] w-full flex-col items-center justify-center">
                  <NotFound />
                </div>
              ) : (
                <>
                  <Table
                    data={data || []}
                    columns={columns}
                    pinningConfigs={pinningConfigs}
                    hideFooter
                    isRoundedBottom={false}
                    className="max-h-[65vh] lg:max-h-[60vh] xl:max-h-[68vh] 2xl:max-h-[70vh]"
                  />
                  <Pagination
                    currentPage={currentPage + 1}
                    totalPages={query.data?.totalPages}
                    onPageChange={(page) => setCurrentPage(page - 1)}
                    itemPerPage={itemPerPage}
                    setItemPerPage={setItemPerPage}
                  />
                </>
              )}
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default MasterPeriodeFeature;
