import { useMutation, useQueryClient } from "@tanstack/react-query";

import api from "../../../api/configApi";
import { toast } from "../../../atoms/V2/useToast";

const useMutateSettingDOC = () => {
  const queryClient = useQueryClient();
  const mutate = useMutation({
    mutationFn: async (values) => {
      const response = await api.post("/v1/docSettings", values);
      return response;
    },
    onSuccess: () => {
      toast({
        title: "Setting DOC berhasil.",
        variant: "success",
      });
      queryClient.invalidateQueries({
        queryKey: ["get-setting-doc"],
      });
    },
    onError: (error) => {
      console.log(error);
      toast({
        title: "Gagal setting DOC.",
        description: error.response.data.error,
        variant: "error",
      });
    },
  });

  return mutate;
};

export default useMutateSettingDOC;
