import * as React from "react";

function IcFileTypeJpg(props) {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.2 3.2A2.6 2.6 0 018.8.6h12.8c.097 0 .19.039.258.107l8.634 8.634a.366.366 0 01.108.259v19.2a2.6 2.6 0 01-2.6 2.6H8.8a2.6 2.6 0 01-2.6-2.6V3.2z"
        fill="#fff"
        stroke="#D5D7DA"
        strokeWidth={1.2}
      />
      <path
        d="M21.6.4v6a3.2 3.2 0 003.2 3.2h6"
        stroke="#D5D7DA"
        strokeWidth={1.2}
      />
      <rect
        x={0.799805}
        y={14.2002}
        width={20.8}
        height={13.2}
        rx={1.6}
        fill="#7F56D9"
      />
      <path
        d="M6.121 17.982h1.216v4.056c0 .375-.084.701-.253.978a1.67 1.67 0 01-.696.639c-.297.15-.643.224-1.037.224a2.39 2.39 0 01-.954-.184 1.526 1.526 0 01-.676-.569c-.167-.255-.25-.576-.247-.963h1.224a.874.874 0 00.094.395.64.64 0 00.247.25.783.783 0 00.375.086c.151 0 .28-.033.384-.097a.622.622 0 00.241-.29 1.18 1.18 0 00.082-.469v-4.056zM8.354 23.8v-5.818h2.296c.441 0 .817.084 1.128.253.31.166.547.398.71.695.165.296.247.637.247 1.023 0 .387-.083.727-.25 1.023a1.724 1.724 0 01-.724.69c-.315.165-.695.247-1.143.247H9.155v-.985h1.265c.236 0 .431-.041.585-.123a.81.81 0 00.346-.343c.078-.148.117-.317.117-.509a1.05 1.05 0 00-.117-.505.778.778 0 00-.346-.338 1.265 1.265 0 00-.591-.123h-.83V23.8h-1.23zm9.038-3.938a1.276 1.276 0 00-.168-.366 1.132 1.132 0 00-.616-.446c-.13-.04-.276-.06-.435-.06-.297 0-.558.074-.784.222a1.454 1.454 0 00-.523.645c-.125.28-.187.623-.187 1.028s.061.75.184 1.034c.124.284.298.501.523.65.226.148.492.222.799.222.278 0 .516-.049.713-.147.198-.1.35-.242.454-.424.106-.182.16-.396.16-.645l.25.037h-1.5v-.926h2.434v.733c0 .511-.108.95-.324 1.318a2.208 2.208 0 01-.892.847 2.779 2.779 0 01-1.301.295c-.546 0-1.025-.12-1.438-.36a2.504 2.504 0 01-.966-1.032c-.229-.447-.343-.977-.343-1.59 0-.472.068-.893.204-1.262.139-.371.332-.686.58-.943.248-.258.537-.454.866-.588.33-.135.687-.202 1.071-.202.33 0 .637.048.92.145.285.095.537.23.756.403.222.175.403.382.543.623.14.238.23.501.27.79h-1.25z"
        fill="#fff"
      />
    </svg>
  );
}

export default IcFileTypeJpg;
