import React, { useState } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { format } from "date-fns";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { ROUTES_PATH } from "../../../../../../shared/routes";
import { useGetMasterPeriodListOnly } from "../../../../../../useCases/MasterPeriod";
import { useMutateSettingDOC } from "../../../../../../useCases/Settings/DOC";
import { formSchema } from "./config";
import { DetailSection, FormSection } from "./section";

const SapronakSettingDOC = () => {
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.auth);

  const [selectedData, setSelectedData] = useState({
    dif1: null,
    dif2: null,
    dif3: null,
    ip1: null,
    ip2: null,
    ip3: null,
    jaminan: null,
    nilai_jaminan: null,
    idPeriode: null,
  });

  const queryHouses = useGetMasterPeriodListOnly({
    unit: user?.unit,
  });
  const mutate = useMutateSettingDOC();

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      house: "",
      idPeriode: "",
      docInDate: "",
      docInPopulation: "",
    },
  });

  const handleOnSubmit = (values) => {
    const payload = {
      rearingPeriod: { id: selectedData?.idPeriode },
      dateSetting: format(values.docInDate, "yyyy-MM-dd"),
      qtySetting: values.docInPopulation,
    };

    mutate.mutate(payload, {
      onSuccess: () => {
        navigate(ROUTES_PATH.manajemenSapronak);
      },
    });
  };

  return (
    <div className="grid grid-cols-12 gap-2xl p-4">
      <FormSection
        form={form}
        handleOnSubmit={handleOnSubmit}
        queryHouses={queryHouses}
        setSelectedData={setSelectedData}
        mutate={mutate}
      />

      <DetailSection selectedData={selectedData} />
    </div>
  );
};

export default SapronakSettingDOC;
