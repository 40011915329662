import React, { useEffect, useState } from "react";

import { CalendarIcon } from "@heroicons/react/24/outline";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "@material-tailwind/react";
import { format } from "date-fns";
import { id as LOCALE_ID } from "date-fns/locale";
import { useForm, useWatch } from "react-hook-form";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { HiArrowLeft } from "react-icons/hi";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { z } from "zod";

import { IcBox, IcChevronDown } from "../../../../../../atoms/icons";
import LoadingAnimation from "../../../../../../atoms/LoadingAnimation";
import { Button } from "../../../../../../atoms/V2/Button";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "../../../../../../atoms/V2/Collabsible";
import { Calendar } from "../../../../../../atoms/V2/DatePicker";
import { DropdownSingleWithSearch } from "../../../../../../atoms/V2/DropdownSingleWithSearch";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../../../../atoms/V2/Form";
import { Input } from "../../../../../../atoms/V2/Input";
import { InputNumber } from "../../../../../../atoms/V2/InputNumber";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../../../../atoms/V2/Popover";
import { ROUTES_PATH } from "../../../../../../shared/routes";
import { cn, formatNumber } from "../../../../../../shared/utils";
import { useGetFeedsList } from "../../../../../../useCases/Feed";
import { useGetMasterPeriodListOnly } from "../../../../../../useCases/MasterPeriod";
import { useGetFeedsStock } from "../../../../../../useCases/Sapronak";
import {
  useEditSettingFeed,
  useGetDetailSettingFeed,
} from "../../../../../../useCases/Settings/Feed";
import { BadgeStatus } from "../../section/Pakan/components";

export const formSchema = z.object({
  dateSetting: z.date({
    message: "Tanggal tidak boleh kosong.",
  }),
  rearingPeriod: z.string().min(1, {
    message: "Nama kandang tidak boleh kosong.",
  }),
  idPeriod: z.string(),
  feedSetting: z.string().min(1, {
    message: "Pakan tidak boleh kosong.",
  }),
  qtySetting: z
    .number({
      message: "Sak tidak boleh kosong.",
    })
    .min(1, {
      message: "Sak tidak boleh kosong.",
    }),
});

const SapronakEditSettingPakan = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.auth);

  const [selectedData, setSelectedData] = useState([]);
  const [mappingData, setMappingData] = useState([]);
  const [openStates, setOpenStates] = useState(mappingData.map(() => false));

  const queryDetail = useGetDetailSettingFeed(id);
  const queryHouses = useGetMasterPeriodListOnly({
    unit: user?.unit,
  });
  const queryPakan = useGetFeedsList();
  const querySapronak = useGetFeedsStock({
    periodId: selectedData[0]?.id,
  });
  const mutateEdit = useEditSettingFeed(id);

  const isLoading =
    queryDetail.isLoading || queryHouses.isLoading || queryPakan.isLoading;

  const form = useForm({
    resolver: zodResolver(formSchema),
  });

  const realtimeQty = useWatch({ control: form.control, name: "qtySetting" });

  useEffect(() => {
    if (queryDetail.data) {
      setSelectedData([
        {
          id: queryDetail.data?.rearingPeriod?.id,
          name: queryDetail.data?.rearingPeriod?.house?.labels,
          period: queryDetail.data?.rearingPeriod?.analyticAccount,
        },
      ]);
      form.setValue(
        "dateSetting",
        queryDetail.data?.dateSetting
          ? new Date(queryDetail.data?.dateSetting)
          : new Date(),
      );
      form.setValue("rearingPeriod", queryDetail.data?.rearingPeriod?.id);
      form.setValue(
        "idPeriode",
        queryDetail.data?.rearingPeriod?.analyticAccount,
      );
      form.setValue("feedSetting", queryDetail.data?.feedSetting?.id);
      form.setValue("qtySetting", queryDetail.data?.qtySetting);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryDetail.isLoading]);

  useEffect(() => {
    if (querySapronak.data && selectedData.length > 0) {
      const dataStock = querySapronak.data?.contents?.map((item) => ({
        id: item.id,
        labels: item.labels,
        price: item.price,
        idPeriod: item.rearingPeriod?.id,
        stock: item.stock,
        suplier: item.supplier,
        type: item.type?.id,
      }));

      const dataSelected = selectedData.map((item) => {
        const stocks = dataStock
          .filter((stock) => stock.idPeriod === item.id)
          .map((stock) => ({
            idStock: stock?.id || "-",
            stokLabel: stock?.labels || "-",
            stokPrice: stock?.price || "-",
            stokStock: stock?.stock || "-",
            stokSuplier: stock?.suplier || "-",
            stokType: stock?.type || "-",
          }));

        return {
          id: item.id,
          name: item.name,
          period: item.period,
          stock: stocks.length
            ? stocks
            : [
                {
                  idStock: "-",
                  stokLabel: "-",
                  stokPrice: "-",
                  stokStock: "-",
                  stokSuplier: "-",
                  stokType: "-",
                },
              ],
        };
      });

      setMappingData(dataSelected);
    }
  }, [querySapronak.data, selectedData]);

  const handleOnSubmit = () => {
    const values = form.getValues();

    const payload = {
      dateSetting: format(values.dateSetting, "yyyy-MM-dd"),
      rearingPeriod: { id: values.rearingPeriod },
      feedSetting: { id: values.feedSetting },
      qtySetting: values.qtySetting,
    };
    mutateEdit.mutate(payload, {
      onSuccess: () => {
        navigate(`${ROUTES_PATH.manajemenSapronak}?tab=pakan`);
      },
    });
  };

  const toggleCollapsible = (index) => {
    setOpenStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = !newStates[index];
      return newStates;
    });
  };

  return (
    <div className="grid grid-cols-12 gap-2xl p-4">
      {isLoading ? (
        <div className="col-span-12 flex h-[85dvh] w-full items-center justify-center">
          <LoadingAnimation />
        </div>
      ) : (
        <>
          <Card className="col-span-8 w-full border p-0 shadow-none">
            <CardHeader
              floated={false}
              shadow={false}
              className="m-0 flex items-center justify-between gap-xs px-3xl pt-xl"
            >
              <div className="flex items-center gap-xs">
                <Link to={`${ROUTES_PATH.manajemenSapronak}?tab=pakan`}>
                  <HiArrowLeft className="size-6 text-text-primary" />
                </Link>
                <h2 className="text-lg font-bold text-text-primary">
                  Ubah Setting Pakan
                </h2>
              </div>
              <div className="flex items-center justify-end gap-md rounded-md bg-[#F8F8F8] px-2">
                <p className="py-[6px] text-sm text-text-secondary">
                  Total pakan setting
                </p>
                <p className="font-bold text-[#2D61D6]">
                  {formatNumber(realtimeQty)} sak
                </p>
              </div>
            </CardHeader>
            <CardBody className="space-y-xl px-3xl pt-xl">
              <Form {...form}>
                <form className="space-y-lg">
                  <div className="space-y-md">
                    <FormField
                      control={form.control}
                      name="dateSetting"
                      render={({ field }) => {
                        return (
                          <FormItem>
                            <FormLabel isRequire>Tanggal</FormLabel>
                            <FormControl>
                              <Popover>
                                <PopoverTrigger asChild>
                                  <Button
                                    id={field.name}
                                    variant={
                                      form.formState.errors.dateSetting
                                        ? "btnErrorOutlined"
                                        : "btnOutlinedGrey"
                                    }
                                    disabled={mutateEdit.isPending}
                                    className={cn(
                                      "w-full justify-between pr-3 text-left font-normal focus:border-primary-400 focus:ring focus:ring-primary-200 focus-visible:ring-primary-200",
                                      !field.value && "text-gray-400",
                                    )}
                                  >
                                    {field.value ? (
                                      format(field.value, "PPP", {
                                        locale: LOCALE_ID,
                                      })
                                    ) : (
                                      <span>Pilih Tanggal</span>
                                    )}
                                    <CalendarIcon className="h-4 w-4" />
                                  </Button>
                                </PopoverTrigger>
                                <PopoverContent
                                  className="w-auto bg-white p-0"
                                  align="end"
                                >
                                  <Calendar
                                    mode="single"
                                    selected={field.value}
                                    onSelect={field.onChange}
                                    autoFocus
                                  />
                                </PopoverContent>
                              </Popover>
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        );
                      }}
                    />
                  </div>
                  <div className="rounded-md border">
                    <div className="flex items-center justify-between rounded-t-md bg-bg-secondary px-xl py-lg">
                      <p className="text-sm font-bold text-text-secondary">
                        Kandang 1
                      </p>
                    </div>
                    <div className="grid grid-cols-2 gap-x-6 gap-y-3xl p-xl">
                      <FormField
                        control={form.control}
                        name={`rearingPeriod`}
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel isRequire>Kandang</FormLabel>
                            <FormControl>
                              <DropdownSingleWithSearch
                                id={field.name}
                                options={queryHouses.data?.contents?.map(
                                  (house) => ({
                                    id: house.id,
                                    name: house.house?.labels,
                                    labels: house.house?.labels,
                                    code: house.analyticAccount,
                                  }),
                                )}
                                disabled={
                                  queryHouses.isLoading || mutateEdit.isPending
                                }
                                isError={
                                  form.formState.errors.houses?.rearingPeriod
                                }
                                placeholder="Pilih Kandang"
                                placeholderSearch="Cari nama kandang"
                                selectedOption={queryHouses.data?.contents
                                  ?.map((house) => ({
                                    id: house.id,
                                    name: house.house?.labels,
                                    labels: house.house?.labels,
                                    code: house.analyticAccount,
                                  }))
                                  .find((item) => item.id === field.value)}
                                onSelect={(value) => {
                                  field.onChange(value.id || null);
                                  form.setValue(`idPeriode`, value?.code || "");
                                  // each form has its own selected data
                                  setSelectedData([
                                    {
                                      id: value?.id || "-",
                                      name: value?.name || "-",
                                      period: value?.code || "-",
                                    },
                                  ]);
                                }}
                                content={(option) => (
                                  <div className="flex max-w-80 flex-col py-[10px]">
                                    <p className="text-base text-text-secondary">
                                      {option?.name}
                                    </p>
                                    <p className="text-xs text-text-quarterary">
                                      {option?.code || "-"}
                                    </p>
                                  </div>
                                )}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={form.control}
                        name="idPeriode"
                        render={({ field }) => {
                          return (
                            <FormItem>
                              <FormLabel>ID Periode</FormLabel>
                              <FormControl>
                                <Input
                                  {...field}
                                  id={field.name}
                                  disabled
                                  placeholder="ID Periode"
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          );
                        }}
                      />
                      <FormField
                        control={form.control}
                        name="feedSetting"
                        render={({ field }) => {
                          return (
                            <FormItem>
                              <FormLabel isRequire>Pakan</FormLabel>
                              <FormControl>
                                <DropdownSingleWithSearch
                                  id={field.name}
                                  options={queryPakan.data?.contents?.map(
                                    (item) => ({
                                      id: item.id,
                                      labels: item.name,
                                      suplier: item.supplier,
                                      type: item.type?.id,
                                    }),
                                  )}
                                  disabled={
                                    queryPakan.isLoading || mutateEdit.isPending
                                  }
                                  isError={form.formState.errors.feedSetting}
                                  placeholder="Pilih pakan"
                                  placeholderSearch="Cari nama pakan"
                                  onSelect={(value) => {
                                    field.onChange(value?.id || "");
                                  }}
                                  selectedOption={
                                    queryPakan.data?.contents
                                      ?.map((item) => ({
                                        id: item.id,
                                        labels: item.name,
                                        suplier: item.supplier,
                                        type: item.type?.id,
                                      }))
                                      ?.find(
                                        (item) => item.id === field.value,
                                      ) || {
                                      id: "",
                                      labels: "",
                                      suplier: "",
                                      type: "",
                                    }
                                  }
                                  content={(option) => {
                                    return (
                                      <div className="flex w-full items-center justify-between gap-2">
                                        <div className="flex flex-col py-[10px]">
                                          <p className="text-base text-text-secondary">
                                            {option?.labels || "-"}
                                          </p>
                                          <p className="text-xs text-text-quarterary">
                                            {option?.suplier || "-"}
                                          </p>
                                        </div>
                                        <div className="flex w-fit flex-shrink-0 justify-end">
                                          <BadgeStatus type={option?.type} />
                                        </div>
                                      </div>
                                    );
                                  }}
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          );
                        }}
                      />
                      <FormField
                        control={form.control}
                        name="qtySetting"
                        render={({ field }) => {
                          return (
                            <FormItem>
                              <FormLabel isRequire>Jumlah</FormLabel>
                              <FormControl>
                                <InputNumber
                                  id={field.name}
                                  value={field.value || ""}
                                  onValueChange={(values) => {
                                    field.onChange(
                                      values.value ? Number(values.value) : "",
                                    );
                                  }}
                                  disabled={mutateEdit.isPending}
                                  isError={form.formState.errors.qtySetting}
                                  placeholder="Masukkan kuantitas"
                                  rightComponent={
                                    <span className="text-sm text-text-placeholder">
                                      Sak
                                    </span>
                                  }
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          );
                        }}
                      />
                    </div>
                  </div>
                </form>
              </Form>
            </CardBody>
            <CardFooter className="flex w-full justify-end border-t">
              <Button
                onClick={handleOnSubmit}
                className="w-fit"
                disabled={mutateEdit.isPending}
              >
                Simpan
                {mutateEdit.isPending && (
                  <AiOutlineLoading3Quarters className="size-4 animate-spin text-button-primaryFg" />
                )}
              </Button>
            </CardFooter>
          </Card>
          <Card className="col-span-4 flex-shrink-0 border p-0 shadow-none">
            <CardHeader
              floated={false}
              shadow={false}
              className="m-0 space-y-1 px-3xl pt-3xl"
            >
              <h2 className="text-xl font-semibold">Stok pakan</h2>
              <p className="text-xs text-text-tertiary">
                Stok pakan tersedia di kandang
              </p>
            </CardHeader>
            <CardBody className="space-y-xl px-3xl pt-xl">
              <div className="flex flex-col gap-3">
                {mappingData?.length > 0 ? (
                  mappingData?.map(
                    (item, index) => (
                      // item.stock.find((stock) => stock.stokStock !== "-") && (
                      <Collapsible
                        key={`item-${index}`}
                        open={openStates[index]}
                        onOpenChange={() => toggleCollapsible(index)}
                        className="space-y-2"
                      >
                        <CollapsibleTrigger className="flex w-full items-center justify-between gap-[10px] transition-colors hover:bg-bg-secondary/50">
                          <div className="flex flex-col items-start text-start">
                            <p className="font-normal text-text-secondary">
                              {item.name}
                            </p>
                            <p className="text-sm text-text-quarterary">
                              {item.period}
                            </p>
                          </div>
                          <IcChevronDown
                            className={cn(
                              "size-6 flex-shrink-0 text-text-primary",
                              openStates[index] ? "rotate-0" : "rotate-180",
                            )}
                          />
                        </CollapsibleTrigger>
                        <CollapsibleContent className="rounded-md border border-border-secondary">
                          <table className="w-full table-auto border-collapse overflow-hidden rounded-md border border-border-secondary text-sm text-text-primary">
                            <thead>
                              <tr>
                                <th className="border border-border-secondary bg-bg-secondary px-lg py-1 text-left">
                                  Produk
                                </th>
                                <th className="border border-border-secondary bg-bg-secondary px-lg py-1 text-left font-bold">
                                  Jenis pakan
                                </th>
                                <th className="border border-border-secondary bg-bg-secondary px-lg py-1 text-left font-bold">
                                  Kuantitas
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {item?.stock?.map((stock, index) => (
                                <tr key={`stock-${index}`}>
                                  <td className="max-w-[120px] border border-border-secondary px-lg py-[3px]">
                                    <p className="text-sm font-normal text-text-primary">
                                      {stock.stokLabel || "-"}
                                    </p>
                                    <p className="text-xs text-text-quarterary">
                                      {stock.stokSuplier || "-"}
                                    </p>
                                  </td>
                                  <td className="border border-border-secondary px-lg py-[3px]">
                                    {stock.stokType !== "-" ? (
                                      <BadgeStatus type={stock.stokType} />
                                    ) : (
                                      "-"
                                    )}
                                  </td>
                                  <td className="border border-border-secondary px-lg py-[3px]">
                                    {stock.stokStock !== "-"
                                      ? formatNumber(stock.stokStock / 50)
                                      : "-"}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </CollapsibleContent>
                      </Collapsible>
                    ),
                    // ),
                  )
                ) : (
                  <div className="flex flex-col items-center justify-center gap-4 py-5xl">
                    <IcBox className="text-fg-disabled" />
                    <p className="text-text-tertiary">Tidak ada stok pakan</p>
                  </div>
                )}
              </div>
            </CardBody>
          </Card>
        </>
      )}
    </div>
  );
};

export default SapronakEditSettingPakan;
